import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Switch from "@mui/material/Switch";
import axios from "axios";
import "../../../styles/dashboards/clgdashboard/campusmanagersettings.css";
import { toast } from "react-toastify";

const label = { inputProps: { "aria-label": "Switch demo" } };

// Validation schema using Yup
const validationSchema = Yup.object({
  old_password: Yup.string().required("Current password is required"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters long")
    .required("New password is required"),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

function CampusManagerSettings() {
  const token = sessionStorage.getItem("accessToken");

  // Formik setup
  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password2: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          "http://backend.startnaukri.com/api/changepassword/",
          {
            old_password: values.old_password,
            old_password: values.old_password,
            password: values.password,
            password2: values.password,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Handle success response
        if (response.status === 200) {
          toast.success(response.data.msg || "Password changed successfully!");
          formik.resetForm();
        }

        if (response.status === 400 && response.data.non_field_errors) {
          toast.error(response.data.non_field_errors.join(", "));
          console.log("----response", response);
        }
      } catch (error) {
        console.error("Error changing password:", error);

        if (error.response && error.response.data) {
          if (error.response.data.non_field_errors) {
            toast.error(error.response.data.non_field_errors.join(", "));
          } else if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          } else {
            toast.error(
              "An error occurred while changing the password. Please try again."
            );
          }
        } else {
          toast.error("Network error. Please try again later.");
        }
      }
    },
  });

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="clg-setting-page">
          <div className="clg-setting-page-header">
            <h5>Login Details</h5>
          </div>
          <div className="setting-section-text">
            <h5>Basic Information</h5>
            <p>This is your personal information you can update anytime</p>
          </div>

          {/* Password Change Section */}
          <div className="setting-password-section">
            <h5>Password</h5>
            <p>
              If you wish to change your password, you can change it from here
            </p>
            <form
              className="password-form-section"
              onSubmit={formik.handleSubmit}
            >
              {/* Current Password Field */}
              <div className="current-password-field campus-current-password-field">
                <input
                  type="password"
                  name="old_password"
                  placeholder="Enter your current password"
                  onChange={formik.handleChange}
                  value={formik.values.old_password}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.old_password && formik.errors.old_password && (
                  <div className="error-message-setting">
                    {formik.errors.old_password}
                  </div>
                )}
              </div>

              {/* New Password Field */}
              <div className="password-field">
                <input
                  type="password"
                  name="password"
                  placeholder="Enter new password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="error-message-setting">
                    {formik.errors.password}
                  </div>
                )}

                {/* Confirm Password Field */}
                <input
                  type="password"
                  name="password2"
                  placeholder="Confirm password"
                  onChange={formik.handleChange}
                  value={formik.values.password2}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.password2 && formik.errors.password2 && (
                  <div className="error-message-setting">
                    {formik.errors.password2}
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <button type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>

          {/* Notification Section */}
          <div className="clg-setting-notification">
            <div>
              <h5>Notifications</h5>
              <p>
                This is your personal information that you can update anytime
              </p>
            </div>
            <div className="clg-setting-notification-switch">
              <Switch {...label} defaultChecked />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusManagerSettings;
