import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, IconButton, Menu, MenuItem, Pagination } from "@mui/material";
import threedot from "../../../../utils/landingpage/images/three-dots-svgrepo-com.svg";
import "../../../../styles/dashboards/hrdashboard/hrjoblisting.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import filterIcon from "../../../../utils/dashboards/filterIcon.svg";
import filterIcondrop from "../../../../utils/dashboards/filterarrowdropdown.svg";

export default function LateralJobList() {
  const ITEM_HEIGHT = 48;
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(true);
  const [jobsData, setJobsData] = useState([]);
  const [jobApplicationCounts, setJobApplicationCounts] = useState([]);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("accessToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const jobsPerPage = 10;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All");

  const options = [
    { value: "all", label: "All" },
    { value: "live", label: "Live" },
    { value: "closed", label: "Closed" },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    setCurrentPage(1); // Reset to first page when changing filter
    console.log("Selected Option:", option.label); // Debugging line
  };

  // Apply filter based on selectedOption
  const filteredJobs = jobsData.filter((job) => {
    if (selectedOption === "All") return true;
    return job.status.toLowerCase() === selectedOption.toLowerCase();
  });

  const handleClick = (event, job) => {
    setAnchorEl(event.currentTarget);
    setSelectedJob(job); // Set the selected job
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewApplicants = () => {
    const jobNameForUrl = selectedJob.title.replace(/ /g, "-");
    navigate(
      `/dashboard/sidebar-page/hr-manager/view-applicants/${selectedJob.id}/${jobNameForUrl}`
    );
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()]; // Get the month's abbreviation
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const findTotalApplicants = (jobTitle) => {
    const job = jobApplicationCounts.find((item) => item.job === jobTitle);
    return job ? job.total_applicants : 0;
  };

  const handleDeleteJob = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff8800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        setLoading(true);
        const response = await axios.delete(
          `${baseUrl}/api/jobs/company_lateral_jobs/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { id: selectedJob.id },
          }
        );

        setJobsData((prevJobs) => {
          return prevJobs.filter((job) => job.id !== selectedJob.id);
        });

        Swal.fire({
          title: "Deleted!",
          text: "The job has been deleted.",
          icon: "success",
          confirmButtonColor: "#ff8800",
        });
      } catch (error) {
        console.error("Error deleting the job:", error);
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}/api/jobs/company_lateral_jobs/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setJobsData(response.data.jobs);
        setJobApplicationCounts(response.data.job_application_counts);
      } catch (error) {
        console.error("Error fetching the data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobsData();
  }, [baseUrl, token]);

  const handleAddJob = () => {
    navigate("/dashboard/sidebar-page/hr-manager/post-job");
  };

  if (loading) {
    return (
      <div className="_main_content_inner_screen">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <h6>
          {" "}
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <path
              d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
              fill="#222222"
            />
            <path
              d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
              fill="#222222"
            />
          </svg>{" "}
          &nbsp; Lateral-Job-List
        </h6>
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <div className="hr_jobListing_add_job_container">
            <div className="_hr_job_title">All Added Jobs</div>
            <div className="d-flex">
              <div className="filter_container_job_listing">
                <div
                  className="custom-select"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className="select-selected">
                    <img
                      src={filterIcon}
                      alt="Filter Icon"
                      className="filter-icon"
                    />{" "}
                    {selectedOption}{" "}
                    <img
                      className="filterdropdown"
                      src={filterIcondrop}
                      alt="..."
                    />
                  </div>
                  {isOpen && (
                    <div className="select-items">
                      {options.map((option) => (
                        <div
                          key={option.value}
                          onClick={() => handleOptionClick(option)}
                          className="option"
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={handleAddJob}
                className="p-1 px-3 rounded-pill border-0"
                style={{
                  background: "#F38D00",
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Add New Job
              </button>
            </div>
          </div>
          <div className="hr_job_listing_table">
            {currentJobs.length === 0 ? (
              <div className="no_job_post_yet">No job posts yet.</div>
            ) : (
              <table>
                <thead className="hr_job_listing_table_head">
                  <tr>
                    <th>Roles</th>
                    <th>Status</th>
                    <th>Date Posted</th>
                    <th>Due Date</th>
                    <th>Job Type</th>
                    <th>Degree Type</th>
                    <th>Salary Range</th>
                    <th>Applicants</th>
                    <th>Vacancy</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentJobs.map((job, index) => (
                    <tr key={index}>
                      <td>
                        {job.title.length > 13
                          ? `${job.title.slice(0, 13)}...`
                          : job.title}
                      </td>
                      <td>
                        <span className={`status ${job.status.toLowerCase()}`}>
                          {job.status}
                        </span>
                      </td>
                      <td>{formatDate(job.created_at)}</td>
                      <td>{job.end_date ? formatDate(job.end_date) : "N/A"}</td>
                      <td>{job.type}</td>
                      <td>Any Degree</td>
                      <td>
                        {job.min_salary} to {job.max_salary}
                      </td>
                      <td>{findTotalApplicants(job.title)}</td>
                      <td>{job.vacancy}</td>
                      <td>
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, job)}
                          >
                            <img
                              src={threedot}
                              height={18}
                              width={18}
                              alt=".."
                            />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "15ch",
                              },
                            }}
                          >
                            <MenuItem onClick={() => handleDeleteJob(job.id)}>
                              Delete
                            </MenuItem>
                            <MenuItem onClick={handleViewApplicants}>
                              View Applicants
                            </MenuItem>
                          </Menu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {filteredJobs.length > jobsPerPage && (
            <div className="d-flex justify-content-center pagination_custom">
              <Pagination
                count={Math.ceil(jobsData.length / jobsPerPage)}
                page={currentPage}
                color="primary"
                className="mt-3"
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
