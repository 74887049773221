import React, { useState } from "react";
import Png_img_stud from "../../../utils/landingpage/images/Png_img_stud.png";
import MPHASIS_png_stud from "../../../utils/landingpage/images/MPHASIS_png_stud.png";
import Systango_png_stud from "../../../utils/landingpage/images/Systango_png_stud.png";
import Accenture_png_stud from "../../../utils/landingpage/images/Accenture_png_stud.png";
import "../../../styles/dashboards/clgstudent/studentinterview.css";
const data = [
  {
    id: 1,
    companyname: "Microsoft Pvt. Ltd.",
    date: "31 July 2024",
    day: "Wednesday",
    time: "11:00",
    Am: "AM",
    logo: Png_img_stud,
    location: "Pune, Maharashtra",
  },

  {
    id: 2,
    companyname: "Mphasis Pvt. Ltd.",
    date: "31 July 2024",
    day: "Thursday",
    time: "12:00",
    Am: "Pm",
    logo: MPHASIS_png_stud,
    location: "Vadodara , Gujarat",
  },

  {
    id: 3,
    companyname: "Accenture Pvt. Ltd.",
    date: "31 July 2024",
    day: "Friday",
    time: "01:00",
    Am: "Pm",
    logo: Accenture_png_stud,
    location: "Indore, Madhya Pradesh",
  },

  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
];

const data1 = [
  {
    id: 1,
    companyname: "Microsoft Pvt. Ltd.",
    date: "31 July 2024",
    day: "Wednesday",
    time: "11:00",
    Am: "AM",
    logo: Png_img_stud,
    location: "Pune, Maharashtra",
  },

  {
    id: 2,
    companyname: "Mphasis Pvt. Ltd.",
    date: "31 July 2024",
    day: "Thursday",
    time: "12:00",
    Am: "Pm",
    logo: MPHASIS_png_stud,
    location: "Vadodara , Gujarat",
  },

  {
    id: 3,
    companyname: "Accenture Pvt. Ltd.",
    date: "31 July 2024",
    day: "Friday",
    time: "01:00",
    Am: "Pm",
    logo: Accenture_png_stud,
    location: "Indore, Madhya Pradesh",
  },

  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
  {
    id: 4,
    companyname: "Systango Pvt. Ltd.",
    date: "31 July 2024",
    day: "Saturday",
    time: "02:00",
    Am: "Pm",
    logo: Systango_png_stud,
    location: "Indore, Madhya Pradesh",
  },
];

const StudentInterview = () => {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="student_campus_interview_data">
          <div className="student_campus_interview_parent">
            <div className="student_campus_job_interview">
              <div className="student_campus_interview_border">
                <h2 className="student_campus_interview_content">
                  My Scheduled
                  <span className="student_campus_interview_span">
                    (This Week)
                  </span>
                </h2>
              </div>
              <div className="student_campus_scrollBar_container">
                {data.map((job, index) => {
                  return (
                    <div className="student_campus_company_padding">
                      <div
                        key={job.id}
                        className={`student_campus_company_parent1 ${
                          index === data1.length - 1
                            ? "student_campus_no-border"
                            : ""
                        }`}
                      >
                        <div className="student_campus_company_parent">
                          <img
                            src={job.logo}
                            alt="Job Logo"
                            width={50}
                            height={50}
                            className="student_campus_job_logo"
                          />
                          <div>
                            <h2 className="student_campus_job_interviewcompany">
                              {job.companyname}
                            </h2>
                            <p className="student_campus_job_interviewlocation">
                              {job.location}
                            </p>
                          </div>
                        </div>

                        <div className="student_campus_date_day">
                          <h2 className="student_campus_job_interviewdate">
                            {job.date}
                          </h2>
                          <p className="student_campus_job_interviewday">
                            {job.day}
                          </p>
                        </div>

                        <div className="student_campus_time_am">
                          <h2 className="student_campus_job_interviewtime">
                            {job.time}
                          </h2>
                          <p className="student_campus_job_interviewampm">
                            {job.Am}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="student_campus_interview_parent">
            <div className="student_campus_job_interview">
              <div className="student_campus_interview_border">
                <h2 className="student_campus_interview_content">
                  Upcoming Interviews
                </h2>
              </div>
              <div className="student_campus_scrollBar_container">
                {data1.map((job, index) => {
                  return (
                    <div className="student_campus_company_padding">
                      <div
                        key={job.id}
                        className={`student_campus_company_parent1 ${
                          index === data1.length - 1
                            ? "student_campus_no-border"
                            : ""
                        }`}
                      >
                        <div className="student_campus_company_parent">
                          <img
                            src={job.logo}
                            alt="Job Logo"
                            width={50}
                            height={50}
                            className="student_campus_job_logo"
                          />
                          <div>
                            <h2 className="student_campus_job_interviewcompany">
                              {job.companyname}
                            </h2>
                            <p className="student_campus_job_interviewlocation">
                              {job.location}
                            </p>
                          </div>
                        </div>
                        <div className="student_campus_date_day">
                          <h2 className="student_campus_job_interviewdate">
                            {job.date}
                          </h2>
                          <p className="student_campus_job_interviewday">
                            {job.day}
                          </p>
                        </div>

                        <div className="student_campus_time_am">
                          <h2 className="student_campus_job_interviewtime">
                            {job.time}
                          </h2>
                          <p className="student_campus_job_interviewampm">
                            {job.Am}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentInterview;