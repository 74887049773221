import { useEffect, useState } from "react";
import "../../../styles/dashboards/hrdashboard/hrcampus.css";
import "../../../styles/dashboards/hrdashboard/viewappllications.css";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import leftArrow from "../../../utils/dashboards/leftarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import axios from "axios";

export default function HrManagerCampuses(params) {
  const [collegeList, setCollegeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false); // Loader state
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const clgPerPage = 4;
  const base_Url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await axios.get(`${base_Url}/api/show_college/`);
        console.log(response.data);
        setCollegeList(response.data);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };
    fetchColleges();
  }, []);

  // Filter colleges based on search query
  const filteredColleges = collegeList.filter(
    (college) =>
      college.college_api && // Check if college_api exists
      college.college_api.college_name && // Check if college_name exists
      college.college_api.college_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastClg = currentPage * clgPerPage;
  const indexOfFirstClg = indexOfLastClg - clgPerPage;
  const currentColleges = filteredColleges.slice(
    indexOfFirstClg,
    indexOfLastClg
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          {/* ----------------------- */}
          <div className="hr_campus_heading">
            <div className="d-flex align-items-center">
              <img
                onClick={handleNavigate}
                src={leftArrow}
                style={{
                  paddingRight: "1rem",
                  height: "18px",
                  cursor: "pointer",
                }}
                alt="Back"
              />
              <p>Campus Selection</p>
            </div>
            <div className="view_application_search_container">
              <img
                style={{ paddingLeft: "10px" }}
                height={27}
                width={27}
                src={searchIconDash}
                alt="Search"
              />
              <input
                type="search"
                placeholder="Search"
                className="view_application_search"
                name="search"
                id="search"
              />
            </div>
          </div>
          {/* ----------------------- */}
          <div className="circulate_job_list_container mt-5">
            {currentColleges.map((college) => (
              <div className="circulate_job_card" key={college.id}>
                <div className="circulate_job_card_content">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="circulate_job_image_container">
                      <img
                        src={
                          college.college_logo ||
                          "https://via.placeholder.com/150"
                        }
                        alt={`${college.collegename || "College"} Logo`}
                        className="circulate_job_image"
                      />
                    </div>

                    <div className="circulate_job_details">
                      <h4 className="circulate_job_college_name">
                        <span>
                          {college.college_api.college_name?.slice(0, 35) ||
                            "Unnamed College"}
                          {college.college_api.college_name?.length > 35
                            ? "..."
                            : ""}
                        </span>
                      </h4>
                      <p className="circulate_job_location">
                        {college?.college_api?.district_name}{" "}
                        {college?.college_api?.state_name}
                      </p>
                    </div>
                  </div>

                  <div className="circulate_job_info_link">
                    <Link
                      to={`/dashboard/sidebar-page/${userData.roleName}/college-details/${college.id}`}
                    >
                      More Info
                    </Link>
                  </div>
                </div>
              </div>
            ))}

            {/* Pagination */}
            <div className="d-flex justify-content-center align-items-center">
              <Pagination
                count={Math.ceil(filteredColleges.length / clgPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className="mt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
