import React, { useState } from "react";
import Png_img_stud from "../../../utils/landingpage/images/Png_img_stud.png";
import Star_png_stud from "../../../utils/landingpage/images/Star_png_stud.png";
import Vector_logo_stud from "../../../utils/landingpage/images/Vector_logo_stud.png";
import Accenture_png_stud from "../../../utils/landingpage/images/Accenture_png_stud.png";
import Group_png_stud from "../../../utils/landingpage/images/Group_png_stud.png";
import MPHASIS_png_stud from "../../../utils/landingpage/images/MPHASIS_png_stud.png";
import Rectangle_unsave_stud from "../../../utils/landingpage/images/Rectangle_unsave_stud.png";
import Map_png_stud from "../../../utils/landingpage/images/Map_png_stud.png";
import Rectangle_save_stud from "../../../utils/landingpage/images/Rectangle_save_stud.png";
import Search_png_stud from "../../../utils/landingpage/images/Search_png_stud.png";
import "../../../styles/dashboards/clgstudent/studentsavedjobs.css";

const data = [
  {
    id: 1,
    jobtitile: "Front-end Developer",
    companyname: "Infogrians",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: Png_img_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_unsave_stud,
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
    unsavedata: "save",
    jobdetail: "Job Details",
  },
  {
    id: 2,
    jobtitile: "Front-end Developer",
    companyname: "Delopirr",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: Accenture_png_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_save_stud,
    jobdetail: "Job Details",
    unsavedata: "Saved",
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
  },
  {
    id: 3,
    jobtitile: "Front-end Developer",
    companyname: "Mphsis Pvt.Ltd.",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: MPHASIS_png_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_unsave_stud,
    unsavedata: "save",
    jobdetail: "Job Details",
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
  },
  {
    id: 4,
    jobtitile: "Front-end Developer",
    companyname: "Mphsis Pvt.Ltd.",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: Png_img_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_save_stud,
    jobdetail: "Job Details",
    unsavedata: "Saved",
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
  },
];

const StudentSavedJobs = () => {
  const [savedJobs, setSavedJobs] = useState(data.map(() => false));

  const handleSaveClick = (index) => {
    const updatedSavedJobs = [...savedJobs];
    updatedSavedJobs[index] = !updatedSavedJobs[index];
    setSavedJobs(updatedSavedJobs);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="student_campus_parrent_save_data">
          {/* <div className="Saved_job"> */}
          {data.map((job, index) => (
            <div key={job.id} className="student_campus_all_jobs">
              <div className="student_campus_container_save">
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={job.logo}
                      alt="Job Logo"
                      width={50}
                      height={50}
                      className="student_campus_job_logo"
                    />
                  </div>
                  <div>
                    <div>
                      <h3 className="student_campus_Frontend_developer">
                        {job.jobtitile}
                      </h3>
                    </div>
                    <div className="student_campus_company_name">
                      <p className="student_campus_job_companyname">
                        {job.companyname}
                      </p>
                      <p className="student_campus_company_rating">
                        <img
                          src={job.image}
                          alt="Job Logo"
                          className="student_campus_star_img"
                        />{" "}
                        <span>{job.review}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => handleSaveClick(index)}
                  style={{ cursor: "pointer" }}
                >
                  <p className="student_campus_save_data">
                    <img
                      src={
                        savedJobs[index]
                          ? Rectangle_save_stud
                          : Rectangle_unsave_stud
                      }
                      alt={savedJobs[index] ? "Saved" : "Unsaved"}
                      className="student_campus_save_img"
                    />
                    <span className="student_campus_unsave_job">
                      {savedJobs[index] ? "Saved" : "Save"}
                    </span>
                  </p>
                </div>
              </div>

              <div className="student_campus_Contain_div">
                <p className="student_campus_company_experience">
                  <img
                    src={job.explogo}
                    alt="Job Logo"
                    className="student_campus_job_exppro"
                  />
                  <span className="student_campus_job_containt">
                    {job.experience}
                  </span>
                </p>
                <p className="student_campus_company_dollar">
                  <img
                    src={job.dollar}
                    alt="Job Logo"
                    className="student_campus_job_dollar"
                  />{" "}
                  <span className="student_campus_job_containsalery">
                    {job.sallery}
                  </span>
                </p>
                <p className="student_campus_company_map">
                  <img
                    src={job.map}
                    alt="Job Logo"
                    className="student_campus_job_map"
                  />{" "}
                  <span className="student_campus_job_containtlocation">
                    {job.location}
                  </span>
                </p>
              </div>
              <p className="student_campus_paragraf_saved">
                Microsoft Pvt. Ltd. is looking for Junior Front End Developer to
                join our dynamic team and embark on a rewarding career journey.
              </p>
              <div className="student_campus_job_details">
                <p className="student_campus_job_duration">{job.duration}</p>
                <p className="student_campus_detail_job">
                  <a href="#" className="student_campus_job_link">
                    {job.jobdetail}
                  </a>
                </p>
              </div>
            </div>
          ))}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default StudentSavedJobs;