import React from "react";

function AdminPlans() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">AdminPlans</div>
    </div>
  );
}

export default AdminPlans;
