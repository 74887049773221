import React from "react";

function Students() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">Students</div>
    </div>
  );
}

export default Students;
