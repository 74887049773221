import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "../../../../styles/dashboards/hrdashboard/assessment.css";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HRAssessment = ({ setFormData, formData, formErrors }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");
  const [assessmentCategories, setAssessmentCategories] = useState([]);
  const [newCategory, setNewCategory] = useState(""); // State for the new category input

  const handleAssessmentAddCategory = async () => {
    setLoading(true);

    const alphabeticRegex = /^[A-Za-z\s]+$/;

    if (newCategory) {
      if (!alphabeticRegex.test(newCategory)) {
        toast.error("Category name must contain only alphabetic characters.");
        setLoading(false);
        return;
      }

      const categoryExists = assessmentCategories.some(
        (category) => category.name.toLowerCase() === newCategory.toLowerCase()
      );

      if (categoryExists) {
        toast.error(`Category "${newCategory}" already exists.`);
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/assessment-categories/`,
          { name: newCategory },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        toast.success(`Category "${response.data.name}" added successfully!`);
        console.log(response.data.name);
        setAssessmentCategories((prevCategories) => [
          ...prevCategories,
          response.data,
        ]);
        setNewCategory("");
      } catch (error) {
        console.error("Error adding category:", error);
        toast.error("Failed to add category. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please enter a category name.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAssessmentCategories = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assessment-categories/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(response.data);
        setAssessmentCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching assessment categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessmentCategories();
  }, []);
  // const handleDelete = (id) => {
  //   deleteCategory(id)
  //     .then(() => {
  //       setAssessmentCategories((prevCategories) =>
  //         prevCategories.filter((category) => category._id !== id)
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting category:", error);
  //     });
  // };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="assessment_contaier">
            <h5>
              {" "}
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              Assessment
            </h5>
            {/* <ToastContainer /> */}
            {loading && (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            )}
            <div className="assesment_category_conatiner">
              <input
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Enter new category"
                id="add_assessment_category_input"
              />
              <div
                className="assessment_add_category_btn"
                onClick={handleAssessmentAddCategory}
              >
                Add
              </div>
            </div>
            <hr />
            <div className="assessment_questons_container">
              <h5>Your Assessment Category</h5>
              <br />
              <div className="assessment_category_container">
                {assessmentCategories.length > 0 ? (
                  assessmentCategories.map((category) => (
                    <div
                      className="assessment_category_card"
                      key={category._id}
                    >
                      {" "}
                      <h3 className="card__title">{category.name} </h3>
                      <div className="card__date">
                        {new Date(category.created_at).toLocaleDateString()}{" "}
                        <br />
                        <small style={{ fontSize: "12px", color: "orange" }}>
                          Total Questions : {category.total_questions}
                        </small>
                      </div>
                      <div
                        className="card__arrow"
                        onClick={() =>
                          navigate(
                            `/dashboard/sidebar-page/:roleName/assessment/${
                              category.id
                            }/${encodeURIComponent(category.name)}`
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          height="15"
                          width="15"
                        >
                          <path
                            fill="#fff"
                            d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="card__delete"
                        // onClick={() => handleDelete(category._id)}
                      >
                        <svg
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z"
                            fill="#fff"
                          />
                          <path
                            d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z"
                            fill="#Fff"
                          />
                          <path
                            d="M9.64788 15.9999H4.87022C2.27302 15.9999 2.16884 14.5636 2.08698 13.4027L1.60326 5.90877C1.58093 5.60366 1.81907 5.33575 2.12419 5.31343C2.43674 5.29854 2.69721 5.52924 2.71953 5.83435L3.20325 13.3283C3.28511 14.4594 3.31488 14.8836 4.87022 14.8836H9.64788C11.2107 14.8836 11.2404 14.4594 11.3149 13.3283L11.7986 5.83435C11.8209 5.52924 12.0888 5.29854 12.3939 5.31343C12.699 5.33575 12.9372 5.59621 12.9148 5.90877L12.4311 13.4027C12.3493 14.5636 12.2451 15.9999 9.64788 15.9999Z"
                            fill="#fff"
                          />
                          <path
                            d="M8.49403 11.907H6.0159C5.71079 11.907 5.45776 11.654 5.45776 11.3489C5.45776 11.0438 5.71079 10.7908 6.0159 10.7908H8.49403C8.79915 10.7908 9.05217 11.0438 9.05217 11.3489C9.05217 11.654 8.79915 11.907 8.49403 11.907Z"
                            fill="#fff"
                          />
                          <path
                            d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No categories available. Please add a new category.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRAssessment;
