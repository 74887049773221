import React, { useState, useEffect } from "react";
import "../../../../styles/dashboards/hrdashboard/hrprofile.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  fetchHrProfileData,
  updateHrProfileData,
} from "../../../../redux/actions/hr_profile_action";
import { toast } from "react-toastify";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import editrevertIcon from "../../../../utils/dashboards/edit-revert.svg";
import {
  SetIsLoading,
  setGetHrProfileData,
  setIsEditModelOpen,
  setIsSubmitted,
} from "../../../../redux/reducers/hrProfileReducer";

function ProfileCreation() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.hrProfile);
  const reduxProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );

  const [profileCreationData, setProfileCreationData] = useState(() => ({
    company_name: reduxProfileData?.company_name || "",
  }));

  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [uploadError, setUploadError] = useState("");
  const token = sessionStorage.getItem("accessToken");
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadSuccess(false);
      setUploadError("");
      setLoadingProfile(true);

      const formData = new FormData();
      formData.append("company_logo", file);

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/company_profile/${userData.id}/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const updatedImageUrl = response.data.company_logo_url;
          if (updatedImageUrl) {
            setExistingImageUrl(updatedImageUrl);
          }
          setUploadSuccess(true);
          dispatch(setGetHrProfileData(response.data));
          toast.success("Profile Update Successfully");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoadingProfile(false);
      }
    }
  };

  // handle input changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileCreationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch profile data when component mounts
  useEffect(() => {
    dispatch(fetchHrProfileData());
  }, [dispatch]);

  // Set existing image URL when profileData updates
  useEffect(() => {
    if (profileData?.getHrProfileData) {
      const profile = profileData.getHrProfileData;
      setExistingImageUrl(profile?.company_logo_url || "");
      setProfileCreationData((prevData) => ({
        ...prevData,
        company_name: profile?.company_name || "",
        industry: profile?.industry,
        established_year: profile?.established_year,
        contact: profile?.contact,
        gst_number: profile?.gst_number,
        company_desc: profile?.company_desc,
        mission_vision: profile?.mission_vision,
        value_culture: profile?.value_culture,
        ach_awards: profile?.ach_awards,
        project: profile?.project,
      }));
    }
  }, [profileData]);

  const handleEditClick = () => {
    dispatch(setIsEditModelOpen(!profileData.isEditModelOpen));
  };

  // Validation function
  const handleValidation = () => {
    const newErrors = {};
    if (!profileCreationData.company_name)
      newErrors.company_name = "Company name is required";
    if (!profileCreationData.industry)
      newErrors.industry = "Industry name is required";
    if (!profileCreationData.established_year)
      newErrors.established_year = "Established year is required";
    if (!profileCreationData.contact)
      newErrors.contact = "Contact information is required";
    if (!profileCreationData.gst_number)
      newErrors.gst_number = "GST number is required";
    if (!profileCreationData.company_desc)
      newErrors.company_desc = "Company description is required";
    if (!profileCreationData.mission_vision)
      newErrors.mission_vision = "Mission and vision are required";
    if (!profileCreationData.value_culture)
      newErrors.value_culture = "Values and culture are required";
    if (!profileCreationData.ach_awards)
      newErrors.ach_awards = "Achievements and awards are required";
    if (!profileCreationData.project)
      newErrors.project = "Notable project and contributions are required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      dispatch(updateHrProfileData(profileCreationData, dispatch));
    }
  };

  return (
    <div>
      <div className="hrProfile_leftinner">
        {profileData.isLoading ? (
          <>
            <div className="loader"></div>
          </>
        ) : (
          <div className="hrProfile_logo">
            <div className="logo">
              <img
                src={
                  selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : existingImageUrl || "https://via.placeholder.com/150"
                }
                alt="Profile"
                className="profile-image"
              />
            </div>
            <div className="logo_content">
              <div className="logo_content_btn">
                <div className="logo_content_text">
                  <span>Upload your company logo</span>
                  <p>Your photo should be in png or jpeg</p>
                </div>
                <div className="logo_Button">
                  <div className="Selection_btn">
                    <label>
                      Choose Image
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </label>
                  </div>
                  <div className="remove_btn">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedFile(null);
                        setUploadSuccess(false);
                        setExistingImageUrl("");
                        setUploadError("");
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
                {uploadSuccess && <></>}
              </div>
            </div>
          </div>
        )}
      </div>
      {profileData.isSubmitted && (
        <>
          {" "}
          <div>
            <span
              onClick={handleEditClick}
              style={{
                cursor: "pointer",
                float: "right",
                marginRight: "13px",
              }}
            >
              {" "}
              {profileData.isEditModelOpen ? "Edit Details     " : ""}
              <img
                src={profileData.isEditModelOpen ? editIcon : editrevertIcon}
                alt="Edit"
              />{" "}
            </span>
          </div>
        </>
      )}

      <div className="hr_Profile_creation_container">
        {profileData.isEditModelOpen ? (
          <>
            <div className="hr_profile_creation_details_container">
              <div className="hr_profile_creation_details_first_section">
                <div className="details_row">
                  <label>Company Name</label>{" "}
                  <p>{profileCreationData?.company_name || "Not Mentioned"}</p>
                </div>
                <div className="details_row">
                  <label>Industry Name</label>{" "}
                  <p>{profileCreationData?.industry || "Not Mentioned"}</p>
                </div>
                <div className="details_row">
                  <label>Established Year</label>{" "}
                  <p>
                    {profileCreationData?.established_year || "Not Mentioned"}
                  </p>
                </div>
                <div className="details_row">
                  <label>Contact Information</label>{" "}
                  <p>{profileCreationData?.contact || "Not Mentioned"}</p>
                </div>
              </div>
              <div className="details_gst">
                <label>GSt Number</label>{" "}
                <p>{profileCreationData?.gst_number || "Not Mentioned"}</p>
              </div>
              <div className="details_company_description">
                <label>Company Description</label>
                <textarea
                  cols="10"
                  disabled
                  rows="5"
                  value={profileCreationData?.company_desc}
                ></textarea>
              </div>
              <div className="hr_profile_creation_details_first_section">
                <div className="details_gst">
                  <label>Mission and Vision</label>{" "}
                  <p>
                    {profileCreationData?.mission_vision || "Not Mentioned"}
                  </p>
                </div>
                <div className="details_gst">
                  <label>Values and Culture</label>{" "}
                  <p>{profileCreationData?.value_culture || "Not Mentioned"}</p>
                </div>
                <div className="details_gst">
                  <label>Achievements and Awards</label>{" "}
                  <p>{profileCreationData?.ach_awards || "Not Mentioned"}</p>
                </div>
                <div className="details_gst">
                  <label>Notable Project and Contributions</label>{" "}
                  <p>{profileCreationData?.project || "Not Mentioned"}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <>
              <div className="hr_profile_creation_details_container">
                <div className="hr_profile_creation_details_first_section">
                  <div className="details_row">
                    <label>Company Name</label>{" "}
                    <input
                      type="text"
                      value={profileCreationData?.company_name || ""}
                      name="company_name"
                      onChange={handleInputChange}
                    />
                    {errors.company_name && (
                      <span className="hrProfile_error">
                        {errors.company_name}
                      </span>
                    )}
                  </div>
                  <div className="details_row">
                    <label>Industry Name</label>{" "}
                    <input
                      type="text"
                      value={profileCreationData?.industry || ""}
                      name="industry"
                      onChange={handleInputChange}
                    />
                    {errors.industry && (
                      <span className="hrProfile_error">{errors.industry}</span>
                    )}
                  </div>
                  <div className="details_row">
                    <label>Established Year</label>{" "}
                    <input
                      type="date"
                      value={profileCreationData?.established_year || ""}
                      name="established_year"
                      onChange={handleInputChange}
                    />
                    {errors.established_year && (
                      <span className="hrProfile_error">
                        {errors.established_year}
                      </span>
                    )}
                  </div>
                  <div className="details_row">
                    <label>Contact Information</label>{" "}
                    <input
                      type="tel"
                      value={profileCreationData?.contact}
                      name="contact"
                      onChange={handleInputChange}
                    />
                    {errors.contact && (
                      <span className="hrProfile_error">{errors.contact}</span>
                    )}
                  </div>
                </div>

                <div className="details_gst">
                  <label>GSt Number</label> <br />
                  <input
                    type="tel"
                    value={profileCreationData?.gst_number || ""}
                    name="gst_number"
                    onChange={handleInputChange}
                  />
                  {errors.gst_number && (
                    <span className="hrProfile_error">{errors.gst_number}</span>
                  )}
                </div>
                <div className="details_company_description">
                  <label>Company Description</label>
                  {errors.company_desc && (
                    <span className="error">{errors.company_desc}</span>
                  )}
                  <textarea
                    rows="5"
                    value={profileCreationData?.company_desc}
                    name="company_desc"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <h6 className="additional_details_header">
                  Additional Details
                </h6>
                <div className="hr_profile_creation_details_first_section">
                  <div className="details_row">
                    <label>Mission and Vision</label>{" "}
                    <input
                      type="text"
                      value={profileCreationData?.mission_vision || ""}
                      name="mission_vision"
                      onChange={handleInputChange}
                    />
                    {errors.mission_vision && (
                      <span className="hrProfile_error">
                        {errors.mission_vision}
                      </span>
                    )}
                  </div>
                  <div className="details_row">
                    <label>Values and Culture</label>{" "}
                    <input
                      type="text"
                      value={profileCreationData?.value_culture || ""}
                      name="value_culture"
                      onChange={handleInputChange}
                    />
                    {errors.value_culture && (
                      <span className="hrProfile_error">
                        {errors.value_culture}
                      </span>
                    )}
                  </div>
                  <div className="details_row">
                    <label>Achievements and Awards</label>{" "}
                    <input
                      type="text"
                      value={profileCreationData?.ach_awards || ""}
                      name="ach_awards"
                      onChange={handleInputChange}
                    />
                    {errors.ach_awards && (
                      <span className="hrProfile_error">
                        {errors.ach_awards}
                      </span>
                    )}
                  </div>
                  <div className="details_row">
                    <label>Notable Project and Contributions</label>{" "}
                    <input
                      type="tel"
                      value={profileCreationData?.project}
                      name="project"
                      onChange={handleInputChange}
                    />
                    {errors.project && (
                      <span className="hrProfile_error">{errors.project}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="details_row">
                <button type="submit">
                  {profileData?.isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </>
          </form>
        )}
      </div>
    </div>
  );
}

export default ProfileCreation;
