import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../../../styles/dashboards/hrdashboard/collegedetails.css";

const CollegeDetails = () => {
  const { collegeId } = useParams();
  const [collegeDetails, setCollegeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const base_Url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchCollegeDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${base_Url}/api/details_college/${collegeId}/`
        );
        console.log(response.data);

        setCollegeDetails(response.data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch college details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCollegeDetails();
  }, [collegeId, base_Url]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
      <div className="college_details_perant_con">
        <h6 className="page-title">Details</h6>
        <div className="college-details-container">
          <div className="college-logo-container">
            <img
              src={collegeDetails?.logo || "https://via.placeholder.com/100"}
              alt={collegeDetails?.name}
              className="college-logo"
            />
          </div>
          <div className="college-info">
            <div className="info-item">
              <label>College Name</label>
              <p>
                {collegeDetails?.collegename?.length > 40
                  ? `${collegeDetails?.collegename.substring(0, 40)}...`
                  : collegeDetails?.collegename}
              </p>
            </div>
            <div className="info-item">
              <label>University Name</label>
              <p>
                {collegeDetails?.college_api.university_name?.length > 35
                  ? `${collegeDetails?.college_api.university_name.substring(
                      0,
                      35
                    )}...`
                  : collegeDetails?.college_api.university_name}
              </p>
            </div>
            <div className="info-item">
              <label>Location:</label>
              <p>
                {collegeDetails?.college_api.district_name}{" "}
                {collegeDetails.college_api.state_name}
              </p>
            </div>
            <div className="info-item">
              <label>College ID</label>
              <p>
                {collegeDetails?.college_api.college_id}{" "}
                {collegeDetails.college_api.college_id}
              </p>
            </div>
            <div className="info-item">
              <label>University ID</label>
              <p>
                {collegeDetails?.college_api.university_id}{" "}
                {collegeDetails.college_api.university_id}
              </p>
            </div>
          </div>
        </div>
        <div className="college-details-table">
          <table>
            <thead className="college-details-table_head">
              <tr>
                <th>College Type</th>
                <th>Placement Manager</th>
                <th>Student Count</th>
                <th>Course Offered</th>
                <th>Placement On</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{collegeDetails?.type || "Not Mention"}</td>
                <td>
                  {collegeDetails.name} {collegeDetails.college_lastname}
                </td>
                <td>1200</td>
                <td>MBA/M.Tech</td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="college-about">
          <h2>About</h2>
          <p>{collegeDetails?.about}</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CollegeDetails;
