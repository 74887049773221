import React, { useState } from "react";
import Png_img_stud  from "../../../utils/landingpage/images/Png_img_stud.png";
import Arrow_png_stud  from  "../../../utils/landingpage/images/Arrow_png_stud.png"
import Facebook_png_stud  from  "../../../utils/landingpage/images/Facebook_png_stud.png" 
import Mditwitter_png_stud from "../../../utils/landingpage/images/Mditwitter_png_stud.png" 
import   Indeed_png_stud   from "../../../utils/landingpage/images/Indeed_png_stud.png"
import Instragram_png_stud from "../../../utils/landingpage/images/Instragram_png_stud.png";
import Three_png_dot from "../../../utils/landingpage/images/Three_png_dot.png";
import "../../../styles/dashboards/clgstudent/studentmyapplications.css"
let data = [
  {
    id: 1,
    companyname: "Microsoft",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 2,
    companyname: "Infograins",
    Roles: "UI/UX Designer",
    DateApplied: "05 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 3,
    companyname: "Infobeans",
    Roles: "Back End Developer",
    DateApplied: "04 Aug 2024",
    Status: "Declined",
    imgThree: Three_png_dot,
  },
  {
    id: 4,
    companyname: "Accenture",
    Roles: "Flutter Developer",
    DateApplied: "02 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 5,
    companyname: "Microsoft",
    Roles: "Senior UI Designer",
    DateApplied: "08 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 6,
    companyname: "Systango",
    Roles: "Digital Marketing Exe.",
    DateApplied: "15 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 7,
    companyname: "Infosys",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
];

let data1 = [
  {
    id: 1,
    companyname: "Infograins",
    Roles: "UI/UX Designer",
    DateApplied: "08 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 2,
    companyname: "Microsoft",
    Roles: "Back End Developer",
    DateApplied: "05 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 3,
    companyname: "Infobeans",
    Roles: "Back End Developer",
    DateApplied: "04 Aug 2024",
    Status: "Declined",
    imgThree: Three_png_dot,
  },
  {
    id: 4,
    companyname: "Accenture",
    Roles: "Flutter Developer",
    DateApplied: "02 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 5,
    companyname: "Microsoft",
    Roles: "Senior UI Designer",
    DateApplied: "08 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 6,
    companyname: "Systango",
    Roles: "Digital Marketing Exe.",
    DateApplied: "15 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 7,
    companyname: "Infosys",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
];

let data2 = [
  {
    id: 1,
    companyname: "Accenture",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "Declined",
    imgThree: Three_png_dot,
  },
  {
    id: 2,
    companyname: "Microsoft",
    Roles: "UI/UX Designer",
    DateApplied: "08 Aug 2024 ",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 3,
    companyname: "Infobeans",
    Roles: "Back End Developer",
    DateApplied: "04 Aug 2024",
    Status: "Declined",
    imgThree: Three_png_dot,
  },
  {
    id: 4,
    companyname: "Infograins",
    Roles: "Flutter Developer",
    DateApplied: "02 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 5,
    companyname: "Microsoft",
    Roles: "Senior UI Designer",
    DateApplied: "08 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 6,
    companyname: "Systango",
    Roles: "Digital Marketing Exe.",
    DateApplied: "15 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 7,
    companyname: "Infosys",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
];

let data3 = [
  {
    id: 1,
    companyname: "Infosys",
    Roles: "Front End Developer",
    DateApplied: "04 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 2,
    companyname: "Microsoft",
    Roles: "UI/UX Designer",
    DateApplied: "08 Aug 2024 ",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 3,
    companyname: "Infobeans",
    Roles: "Back End Developer",
    DateApplied: "04 Aug 2024",
    Status: "Declined",
    imgThree: Three_png_dot,
  },
  {
    id: 4,
    companyname: "Infograins",
    Roles: "Flutter Developer",
    DateApplied: "02 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 5,
    companyname: "Microsoft",
    Roles: "Senior UI Designer",
    DateApplied: "08 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 6,
    companyname: "Systango",
    Roles: "Digital Marketing Exe.",
    DateApplied: "15 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 7,
    companyname: "Infosys",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
];

let data4 = [
  {
    id: 1,
    companyname: "Systango",
    Roles: "Flutter Developer",
    DateApplied: "08 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 2,
    companyname: "Microsoft",
    Roles: "UI/UX Designer",
    DateApplied: "08 Aug 2024 ",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 3,
    companyname: "Infobeans",
    Roles: "Back End Developer",
    DateApplied: "04 Aug 2024",
    Status: "Declined",
    imgThree: Three_png_dot,
  },
  {
    id: 4,
    companyname: "Infograins",
    Roles: "Flutter Developer",
    DateApplied: "02 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 5,
    companyname: "Microsoft",
    Roles: "Senior UI Designer",
    DateApplied: "08 Aug 2024",
    Status: "Shortlisted",
    imgThree: Three_png_dot,
  },
  {
    id: 6,
    companyname: "Systango",
    Roles: "Digital Marketing Exe.",
    DateApplied: "15 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
  {
    id: 7,
    companyname: "Infosys",
    Roles: "Front End Developer",
    DateApplied: "05 Aug 2024",
    Status: "In-Review",
    imgThree: Three_png_dot,
  },
];




const data_Appli = [
  {
 id: 1,
jobtitile: "Front End Developer",
companyname: "Microsoft Pvt. Ltd.",
logo: Png_img_stud,
posteddate:"Posted Date",
date:"31-7-2024"
  },

];

const StudentMyApplication = () => {
const[ visibledata,Setvisibledata] = useState(true)


  const [activeTab, setActiveTab] = useState("tab1");
  const [showOptions, setShowOptions] = useState(null);
    const handleImageClick = (index) => {
        if(showOptions ===index){
        setShowOptions(null);
        }
        else{
            setShowOptions(index)
        }
     
    };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

const toggledata= ()=>{
Setvisibledata(!visibledata)
}

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          {visibledata ? (
            <div className="student_campus_myapplication_page">
              <div className="student_campus_Myapplication_parrent">
                <div>
                  <h3
                    className="student_campus_keep_heading"
                    onClick={toggledata}
                  >
                    {" "}
                    Keep it up, Amit
                  </h3>
                  <p className="student_campus_keep_paragraf">
                    Here is the job applications status from July 20 - July 30
                  </p>
                </div>
                <p className="student_campus_day_date">July 20 - July 30</p>
              </div>

              <div className="student_campus_tab_div">
                <div className="student_campus_tab-buttons">
                  <button
                    onClick={() => handleTabClick("tab1")}
                    className="student_campus_button_tab"
                  >
                    <p className="student_campus_paragraf_alljobs">
                      {" "}
                      All Jobs (20)
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab2")}
                    className="student_campus_button_tab1"
                  >
                    <p className="student_campus_paragraf_inreview">
                      In Review (06)
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab3")}
                    className="student_campus_button_tab1"
                  >
                    <p className="student_campus_paragraf_inreview">
                      Interviewing (04)
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab4")}
                    className="student_campus_button_tab1"
                  >
                    <p className="student_campus_paragraf_inreview">
                      Shortlisted (04)
                    </p>
                  </button>
                  <button
                    onClick={() => handleTabClick("tab5")}
                    className="student_campus_button_tab5"
                  >
                    <p className="student_campus_paragraf_Offered">
                      Offered (02)
                    </p>
                  </button>
                </div>
                <div className="student_campus_tab-content">
                  {activeTab === "tab1" && (
                    <div>
                      <h2 className="student_campus_alljobs_heading">
                        Applications History
                      </h2>

                      <div className="student_campus_table_div">
                        <table class="student_campus_styled-table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Company Name</th>
                              <th>Roles</th>
                              <th> Date Applied</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.companyname}</td>
                                  <td>{item.Roles}</td>
                                  <td>{item.DateApplied}</td>
                                  <td>
                                    <button className="student_campus_short_list">
                                      {item.Status}
                                    </button>
                                  </td>
                                  <td>
                                    <img
                                      src={item.imgThree}
                                      alt=""
                                      className="student_campus_img_Threedot"
                                      onClick={() => handleImageClick(index)}
                                    />

                                    {showOptions === index && (
                                      <div className="student_campus_popup_box">
                                        <p className="student_campus_popup_withdraw">
                                          Withdraw
                                        </p>
                                        <p className="student_campus_popup_view">
                                          View
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab2" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        In review
                      </h3>
                      <div className="student_campus_table_div">
                        <table class="student_campus_styled-table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Company Name</th>
                              <th>Roles</th>
                              <th> Date Applied</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data1.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.companyname}</td>
                                  <td>{item.Roles}</td>
                                  <td>{item.DateApplied}</td>
                                  <td>
                                    <button className="student_campus_short_list">
                                      {item.Status}
                                    </button>
                                  </td>
                                  <td>
                                    <img
                                      src={item.imgThree}
                                      alt=""
                                      className="student_campus_img_Threedot"
                                      onClick={() => handleImageClick(index)}
                                    />

                                    {showOptions === index && (
                                      <div className="student_campus_popup_box">
                                        <p className="student_campus_popup_withdraw">
                                          Withdraw
                                        </p>
                                        <p className="student_campus_popup_view">
                                          View
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab3" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Interviewing
                      </h3>
                      <div className="student_campus_table_div">
                        <table class="student_campus_styled-table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Company Name</th>
                              <th>Roles</th>
                              <th> Date Applied</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data2.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.companyname}</td>
                                  <td>{item.Roles}</td>
                                  <td>{item.DateApplied}</td>
                                  <td>
                                    <button className="student_campus_short_list">
                                      {item.Status}
                                    </button>
                                  </td>
                                  <td>
                                    <img
                                      src={item.imgThree}
                                      alt=""
                                      className="student_campus_img_Threedot"
                                      onClick={() => handleImageClick(index)}
                                    />

                                    {showOptions === index && (
                                      <div className="student_campus_popup_box">
                                        <p className="student_campus_popup_withdraw">
                                          Withdraw
                                        </p>
                                        <p className="student_campus_popup_view">
                                          View
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {activeTab === "tab4" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Shortlisted
                      </h3>
                      <div className="student_campus_table_div">
                        <table class="student_campus_styled-table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Company Name</th>
                              <th>Roles</th>
                              <th> Date Applied</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data3.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.companyname}</td>
                                  <td>{item.Roles}</td>
                                  <td>{item.DateApplied}</td>
                                  <td>
                                    <button className="student_campus_short_list">
                                      {item.Status}
                                    </button>
                                  </td>
                                  <td>
                                    <img
                                      src={item.imgThree}
                                      alt=""
                                      className="student_campus_img_Threedot"
                                      onClick={() => handleImageClick(index)}
                                    />

                                    {showOptions === index && (
                                      <div className="student_campus_popup_box">
                                        <p className="student_campus_popup_withdraw">
                                          Withdraw
                                        </p>
                                        <p className="student_campus_popup_view">
                                          View
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {activeTab === "tab5" && (
                    <div>
                      <h3 className="student_campus_alljobs_heading">
                        Offered
                      </h3>
                      <div className="student_campus_table_div">
                        <table class="student_campus_styled-table">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Company Name</th>
                              <th>Roles</th>
                              <th> Date Applied</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {data4.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.companyname}</td>
                                  <td>{item.Roles}</td>
                                  <td>{item.DateApplied}</td>
                                  <td>
                                    <button className="student_campus_short_list">
                                      {item.Status}
                                    </button>
                                  </td>
                                  <td>
                                    <img
                                      src={item.imgThree}
                                      alt=""
                                      className="student_campus_img_Threedot"
                                      onClick={() => handleImageClick(index)}
                                    />

                                    {showOptions === index && (
                                      <div className="student_campus_popup_box">
                                        <p className="student_campus_popup_withdraw">
                                          Withdraw
                                        </p>
                                        <p className="student_campus_popup_view">
                                          View
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="student_campus_myapplication_page">
              <div className="student_campus_job_div">
                <img
                  src={Arrow_png_stud}
                  alt="arrow"
                  className="student_campus_img_rrow"
                  onClick={toggledata}
                />
                <span className="student_campus_job_content"> Job Details</span>
              </div>

              <div className="student_campus_view_detailes">
                {data_Appli.map((job, index) => (
                  <div key={job.id} className="student_campus_flex_content">
                    <div className="student_campus_view_job">
                      <img
                        src={job.logo}
                        alt="Job Logo"
                        width={50}
                        height={50}
                        className="student_campus_job_logo"
                      />
                      <div>
                        <p className="student_campus_Frontend_developer">
                          {job.jobtitile}
                        </p>
                        <p className="student_campus_job_companyname">
                          {job.companyname}
                        </p>
                      </div>
                    </div>
                    <div className="student_campus_date_grupe">
                      <h3 className="student_campus_post_date">
                        {job.posteddate}
                      </h3>
                      <h3 className="student_campus_date">{job.date}</h3>
                    </div>
                  </div>
                ))}
              </div>

              <div className="student_campus_job_description">
                <div className="student_campus_job_section">
                  <div className="student_campus_job_contentgrup">
                    <h3 className="student_campus_heading_description">
                      Job Description
                    </h3>
                    <p className="student_campus_pragraf_content">
                      Microsoft Pvt. Ltd. is looking for JUNIOR ENGINEER - FRONT
                      END to join our dynamic team and embark on a rewarding
                      career journey
                    </p>
                  </div>

                  <ul className="student_campus_list_grup">
                    <li>
                      <a>
                        Assist in developing and maintaining front-end
                        components of web applications.
                      </a>
                    </li>
                    <li>Write clean, efficient, and well-documented code.</li>
                    <li>
                      Collaborate with UI/UX designers to implement user
                      interfaces.
                    </li>
                    <li>
                      Participate in code reviews and provide feedback to
                      improve code quality.
                    </li>
                    <li>
                      Test and debug front-end applications to ensure
                      functionality and performance.
                    </li>
                    <li>
                      Stay updated on front-end development trends and best
                      practices.
                    </li>
                    <li>
                      Participate in team meetings and technical discussions.
                    </li>
                    <li>
                      Contribute to project planning and development activities.
                    </li>
                  </ul>
                </div>

                <div className="student_campus_roll_profile">
                  <p className="student_campus_roll_paragraf">
                    <span className="student_campus_roll_span">Role:</span>{" "}
                    Front End Developer{" "}
                  </p>
                  <p className="student_campus_roll_paragraf">
                    <span className="student_campus_roll_span">
                      Industry Type:
                    </span>
                    IT Service{" "}
                  </p>
                  <p className="student_campus_roll_paragraf">
                    <span className="student_campus_roll_span">
                      Employment Type:
                    </span>{" "}
                    Full Time, Permanent{" "}
                  </p>
                  <p className="student_campus_roll_paragraf">
                    <span className="student_campus_roll_span">Education:</span>{" "}
                    Any Graduate / Any Postgraduate{" "}
                  </p>
                  <p className="student_campus_roll_paragraf">
                    <span className="student_campus_roll_span">
                      Key Skills:
                    </span>{" "}
                    HTML, CSS, Jawa script, Tailwind, React Js{" "}
                  </p>
                </div>
              </div>

              <div className="student_campus_about_company">
                <h2 className="student_campus_about_heading">About Company</h2>
                <p className="student_campus_about_paragraf">
                  At Microsoft, our mission is to empower every person and every
                  organization on the planet to achieve more. Our mission is
                  grounded in both the world in which we live and the future we
                  strive to create. Today, we live in a mobile-first,
                  cloud-first world,
                </p>
                <div className="student_campus_social_media">
                  <img
                    src={Facebook_png_stud}
                    alt="Facebook"
                    className="student_campus_img_social_media"
                  />
                  <img
                    src={Mditwitter_png_stud}
                    alt="Mditwitter"
                    className="student_campus_img_social_Mditwitter"
                  />
                  <img
                    src={Indeed_png_stud}
                    alt="Indeed"
                    className="student_campus_img_social_Indeed"
                  />
                  <img
                    src={Instragram_png_stud}
                    alt="Instragram"
                    className="student_campus_img_social_media"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default StudentMyApplication;