import React, { useState, useRef, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import "../../../../styles/dashboards/hrdashboard/hrprofile.css";
import HeadQuarterAddress from "./HeadQuarterAddress";
import CompanyDetails from "./CompanyDetails";
import ProfileCreation from "./ProfileCreation";
import Instagram from "../../../../utils/landingpage/images/blackinstgram.svg";
import Twitter from "../../../../utils/landingpage/images/blacktwiter.svg";
import LinkedIn from "../../../../utils/landingpage/images/blacklinkedin.svg";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import editrevertIcon from "../../../../utils/dashboards/edit-revert.svg";
import {
  fetchHrProfileData,
  fetchProfileCompletion,
} from "../../../../redux/actions/hr_profile_action";
import { useDispatch, useSelector } from "react-redux";

const HRManagerProfile = () => {
  const [activeTab, setActiveTab] = useState("ProfileCreation");
  const [isSocialEditMode, setSocialEditMode] = useState(false);
  const dispatch = useDispatch();
  const hrProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );
  const profileCompletion = useSelector(
    (state) => state.hrProfile.profileCompletion
  );
  const remainingCompletion = 100 - (profileCompletion || 0);
  const pieChartData = {
    datasets: [
      {
        data: [profileCompletion, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };
  console.log(hrProfileData, "---------hrProfileData");
  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  const handleSocialEditClick = () => {
    setSocialEditMode((prev) => !prev);
  };

  useEffect(() => {
    dispatch(fetchHrProfileData());
    dispatch(fetchProfileCompletion());
  }, [dispatch]);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <ul className="student_campus_profile_tabs">
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${
                activeTab === "ProfileCreation" ? "active" : ""
              }`}
              onClick={() => setActiveTab("ProfileCreation")}
            >
              Profile Creation
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${
                activeTab === "HeadQuarterAddress" ? "active" : ""
              }`}
              onClick={() => setActiveTab("HeadQuarterAddress")}
            >
              Head Quarter Address
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${
                activeTab === "CompanyDetails" ? "active" : ""
              }`}
              onClick={() => setActiveTab("CompanyDetails")}
            >
              Company Details
            </button>
          </li>
        </ul>
        <div className="d-flex justify-content-between align-items-start">
          <div className="student_campus_profile_card">
            <div className="student_campus_profile_card_body">
              {activeTab === "ProfileCreation" && (
                <>
                  <ProfileCreation hrProfileData={hrProfileData} />
                </>
              )}
              {activeTab === "HeadQuarterAddress" && (
                <>
                  <HeadQuarterAddress hrProfileData={hrProfileData} />
                </>
              )}
              {activeTab === "CompanyDetails" && (
                <>
                  <CompanyDetails hrProfileData={hrProfileData} />
                </>
              )}
            </div>
          </div>
          <div style={{ width: "28%" }}>
            <div className="student_campus_profile_social">
              <p>Complete Your Profile</p>
              <div className="mr-4 epf-chart-wrapper">
                <div className="epf-chart-container student_campus_canvas_container">
                  <Pie data={pieChartData} options={pieChartOptions} />
                  <div className="student_campus_canvas_percentage_value">
                    {`${profileCompletion}%` || "0%"}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="student_campus_profile_social">
              <h3 className="student_campus_profile_social_title">
                <span
                  onClick={handleSocialEditClick}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginRight: "13px",
                  }}
                >
                  <img
                    src={isSocialEditMode ? editrevertIcon : editIcon}
                    alt="Edit"
                  />
                </span>
                Social Media Links
              </h3>
              <div className="student_campus_profile_social_list">
                {" "}
                {isSocialEditMode ? (
                  <>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={Instagram} alt="Instagram" />
                      </div>
                      <div>
                        {/* <span>Instagram</span> */}
                        <input
                          type="text"
                          name="instagram"
                          // value={socialLinks.instagram}
                          // onChange={handleChange}
                          className="socialLinks_input"
                        />
                      </div>
                    </div>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={Twitter} alt="Twitter" />
                      </div>
                      <div>
                        {/* <span>Twitter</span> */}
                        <input
                          type="text"
                          name="twitter"
                          // value={socialLinks.twitter}
                          // onChange={handleChange}
                          className="socialLinks_input"
                        />
                      </div>
                    </div>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={LinkedIn} alt="LinkedIn" />
                      </div>
                      <div>
                        {/* <span>LinkedIn</span> */}
                        <input
                          type="text"
                          name="linkedin"
                          // value={socialLinks.linkedin}
                          // onChange={handleChange}
                          className="socialLinks_input"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={Instagram} alt="Instagram" />
                      </div>
                      <div>
                        <span>Instagram</span>
                        <br />
                        <a
                          // href={socialLinks.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* {socialLinks.instagram} */}
                        </a>
                      </div>
                    </div>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={Twitter} alt="Twitter" />
                      </div>
                      <div>
                        <span>Twitter</span>
                        <br />
                        <a
                          // href={socialLinks.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* {socialLinks.twitter} */}
                        </a>
                      </div>
                    </div>
                    <div className="sites_icon">
                      <div className="images">
                        <img src={LinkedIn} alt="LinkedIn" />
                      </div>
                      <div>
                        <span>LinkedIn</span>
                        <br />
                        <a
                          // href={socialLinks.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* {socialLinks.linkedin} */}
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HRManagerProfile;
