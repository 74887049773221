import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import "../../../styles/landing_page/landingpage.css";
import ArrowDown from "../../../utils/landingpage/images/arrow-down.svg";
import ArrowUp from "../../../utils/landingpage/images/arrow-up.svg";
import axios from "axios";

const Faq = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const toggleAnswer = useCallback(
    (faqIndex) => {
      setExpandedFaq(expandedFaq === faqIndex ? null : faqIndex);
    },
    [expandedFaq]
  );

  // Fetch FAQs from the API
  const fetchFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/landing_page/`
      );
      setFaqs(response.data[0].questions); // Ensure this matches your API structure
    } catch (error) {
      console.log("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <>
      <section className="faq-secion">
        <motion.div
          className="faq-header"
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1>Frequently Asked Questions</h1>
          <p>
            Find quick answers about StartNaukri's account setup, job search tools, employer,
            <br /> interactions, privacy policies, and more
          </p>
        </motion.div>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {/* Render FAQs dynamically */}
          {faqs.map((faq, index) => (
            <div key={faq.id} className={`faq-item ${expandedFaq === index + 1 ? "active" : ""}`}>
              <label
                htmlFor={`faq${index + 1}`} // Unique ID for each FAQ
                className="faq-question"
                onClick={() => toggleAnswer(index + 1)}
              >
                <div>
                  <span className="number">{String(index + 1).padStart(2, '0')}</span>
                  <span className="faq-text">{faq.question}</span>
                </div>
                <img
                  className="arrow"
                  src={expandedFaq === index + 1 ? ArrowUp : ArrowDown}
                  alt="Arrow"
                />
              </label>
              <div
                className="faq-answer"
                style={{ display: expandedFaq === index + 1 ? "block" : "none" }}
              >
                <p>{faq.answer || "No answer provided."}</p>
              </div>
            </div>
          ))}
        </motion.div>
      </section>
    </>
  );
};

export default Faq;