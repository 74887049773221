import React from "react";
import Notification_png_stud from "../../../utils/landingpage/images/Notification_png_stud.png";
import "../../../styles/dashboards/clgstudent/studentsettings.css"
const StudentSettings = () => {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="student_campus_setting_page">
          <div className="student_campus_login_page">
            <div className="student_campus_login_detail">
              <h2 className="student_campus_login_heading">Login Details</h2>
            </div>
            <div className="student_campus_basic_information">
              <h3 className="student_campus_basic_heading">
                Basic Information
              </h3>
              <p className="student_campus_paragraf_infomation">
                This is your personal information that you can update anytime{" "}
              </p>
            </div>

            <div className="student_campus_password_div">
              <div className="student_campus_password_headingpara">
                <h3 className="student_campus_password_heading">Password</h3>
                <p className="student_campus_password_paragraf">
                  If you wish to change your password, you can change from here.
                </p>
              </div>
              <div className="student_campus_input_parantdiv">
                <input
                  type="password"
                  placeholder="Enter your current password"
                  className="student_campus_input_passord"
                />

                <div className="student_campus_input_divgroup">
                  <input
                    type="password"
                    placeholder="Enter your current password"
                    className="student_campus_input_newpassord"
                  />
                  <input
                    type="password"
                    placeholder="Enter your current password"
                    className="student_campus_input_newpassord"
                  />
                </div>
              </div>

              <div className="student_campus_submmit_buttondiv">
                <button className="student_campus_submmmit_button">
                  <p className="student_campus_button_text">Submit</p>
                </button>
              </div>
            </div>
            <div className="student_campus_notification_anytime">
              <div className="student_campus_notification_information">
                <div>
                  <h3 className="student_campus_notification_headding">
                    Notifications
                  </h3>
                  <p className="student_campus_notification_paragraf">
                    This is your personal information that you can update
                    anytime
                  </p>
                </div>
                <img
                  src={Notification_png_stud}
                  alt="notification"
                  className="student_campus_notification_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentSettings;