import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import filterIcon from "../../../utils/dashboards/filterIcon.svg";
import filterIcondrop from "../../../utils/dashboards/filterarrowdropdown.svg";
import "../../../styles/dashboards/hrdashboard/viewappllications.css";
import "../../../styles/dashboards/hrdashboard/circulateJob.css";
import "../../../styles/dashboards/hrdashboard/postjobs.css";
import { toast } from "react-toastify";

function HRManagerAllApplications() {
  const navigate = useNavigate();
  const [applicants, setApplicants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const applicantDataPerPage = 10;
  const token = sessionStorage.getItem("accessToken");
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const [isOpen, setIsOpen] = useState(false);
  const [candidateSelectionData, setCandidateSelection] = useState("All");
  const [applicantType, setApplicantType] = useState("Lateral"); // New state for applicant type

  const candidateSelection = [
    { value: "All", label: "All", color: "#000000" },
    { value: "in_review", label: "In-review", color: "#FFA500" },
    { value: "interviewed", label: "Interview", color: "#FFA500" },
    { value: "Shortlisted", label: "Shortlisted", color: "#32CD32" },
    { value: "Declined", label: "Declined", color: "#FF0000" },
    { value: "Offered", label: "Offered", color: "#0000FF" },
  ];

  const handleOptionClick = (option) => {
    setCandidateSelection(option.value);
    setIsOpen(false);
    setCurrentPage(1); // Reset to first page when changing filter
  };

  const deleteApplicationByID = async (deleteApplication) => {
    setDeleting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/delete_applicants_for_my_jobs/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            application_ids: [deleteApplication],
          },
        }
      );
      await fetchApplicants();
      if (response.status === 200) {
        toast.success("Delete successful");
      }
    } catch (error) {
      toast.error("Error deleting application");
    } finally {
      setDeleting(false);
    }
  };

  const fetchApplicants = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/applicants_for_my_jobs/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Combine campus and lateral applicants into a single array for easier filtering
      const combinedApplicants = [
        ...response.data.detailed_applications.lateral,
        ...response.data.detailed_applications.campus,
      ];
      setApplicants(combinedApplicants);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching applicants", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicants();
  }, [token]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const filteredApplicants = applicants.filter((applicant) => {
    // Filter by applicant type
    const isMatchingType =
      (applicantType === "Campus" && applicant.job_audience === "campus") ||
      (applicantType === "Lateral" && applicant.job_audience === "lateral") ||
      applicantType === "All";

    // Filter by search query
    const fullName =
      `${applicant.student.name} ${applicant.student.student_last_name}`.toLowerCase();
    const searchMatch = fullName.includes(searchQuery.toLowerCase());

    // Filter by status
    if (candidateSelectionData === "All") {
      return isMatchingType && searchMatch;
    }
    return (
      isMatchingType &&
      applicant.status.toLowerCase() === candidateSelectionData.toLowerCase() &&
      searchMatch
    );
  });

  const indexOfLastApplicant = currentPage * applicantDataPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantDataPerPage;
  const currentApplicants = filteredApplicants.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );

  const totalPages = Math.ceil(
    filteredApplicants.length / applicantDataPerPage
  );

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="view_application_main_container">
            <h6>
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              &nbsp;All Applicants
            </h6>
            <div className="d-flex gap-4 align-items-center">
              <div className="view_application_search_container">
                <img
                  style={{ paddingLeft: "10px" }}
                  height={27}
                  width={27}
                  src={searchIconDash}
                  alt="Search"
                />
                <input
                  type="search"
                  placeholder="Search"
                  className="view_application_search"
                  name="search"
                  id="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div>
                <div className="filter_container_job_listing">
                  <div
                    className="custom-select"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <div className="select-selected">
                      <img
                        src={filterIcon}
                        alt="Filter Icon"
                        className="filter-icon"
                      />{" "}
                      {candidateSelectionData}{" "}
                      <img
                        className="filterdropdown"
                        src={filterIcondrop}
                        alt="..."
                      />
                    </div>
                    {isOpen && (
                      <div className="select-items">
                        {candidateSelection.map((option) => (
                          <div
                            key={option.value}
                            onClick={() => handleOptionClick(option)}
                            className="option"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              style={{
                                background: `${option.color}`,
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            ></span>
                            {option.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setApplicantType("Campus")}
                  className={`all_applicants_toggle_btn ${
                    applicantType === "Campus" ? "active" : ""
                  }`}
                >
                  Campus
                </button>
                <button
                  onClick={() => setApplicantType("Lateral")}
                  className={`all_applicants_toggle_btn ${
                    applicantType === "Lateral" ? "active" : ""
                  }`}
                >
                  Lateral
                </button>
              </div>
              <h6>Total : {filteredApplicants.length}</h6>
            </div>
          </div>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="view_applicationlisting_table">
              {filteredApplicants.length === 0 ? (
                <div className="no_job_post_yet">No applicants yet.</div>
              ) : (
                <table>
                  <thead className="view_applicationlisting_table_head">
                    <tr>
                      <th></th>
                      <th>Full Name</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Applied</th>
                      <th>Resume</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentApplicants.map((applicant) => (
                      <tr key={applicant._id}>
                        <td>
                          <div className="table_circle_container">
                            <div className="table_circle">
                              <input type="checkbox" name="" id="" />
                            </div>
                          </div>
                        </td>
                        <td>
                          {applicant.student.name.charAt(0).toUpperCase() +
                            applicant.student.name.slice(1).toLowerCase()}{" "}
                          {applicant.student.student_last_name
                            .charAt(0)
                            .toUpperCase() +
                            applicant.student.student_last_name
                              .slice(1)
                              .toLowerCase()}
                        </td>
                        <td>
                          <span
                            className={`application_status ${applicant.status.toLowerCase()}`}
                          >
                            {applicant.status}
                          </span>
                        </td>
                        <td>
                          {applicant.job_audience === "lateral"
                            ? "Lateral"
                            : "Campus"}
                        </td>
                        <td>
                          {new Date(applicant.applied_at).toLocaleString()}
                        </td>
                        <td>
                          <button
                            className="view_application_btn"
                            onClick={() => {
                              if (applicantType === "Lateral") {
                                navigate(
                                  `/dashboard/sidebar-page/hr-manager/lateral/view-candidate-application/${applicant.student.id}/${applicant.application_id}`
                                );
                              } else if (applicantType === "Campus") {
                                navigate(
                                  `/dashboard/sidebar-page/hr-manager/campus/view-candidate-application/${applicant.id}`
                                );
                              }
                            }}
                          >
                            View CV
                          </button>
                        </td>
                        <td>
                          {" "}
                          {isDeleting ? (
                            <span>
                              <span className="loader"></span>
                            </span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0,0,256,256"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // setDeleteApplication();
                                deleteApplicationByID(applicant.application_id);
                              }}
                            >
                              <g
                                fill="#f38d00"
                                fill-rule="nonzero"
                                stroke="none"
                                stroke-width="1"
                                stroke-linecap="butt"
                                stroke-linejoin="miter"
                                stroke-miterlimit="10"
                                stroke-dasharray=""
                                stroke-dashoffset="0"
                                font-family="none"
                                font-weight="none"
                                font-size="none"
                                text-anchor="none"
                              >
                                <g transform="scale(8.53333,8.53333)">
                                  <path d="M14.98438,2.48633c-0.55152,0.00862 -0.99193,0.46214 -0.98437,1.01367v0.5h-5.5c-0.26757,-0.00363 -0.52543,0.10012 -0.71593,0.28805c-0.1905,0.18793 -0.29774,0.44436 -0.29774,0.71195h-1.48633c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h18c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-1.48633c0,-0.26759 -0.10724,-0.52403 -0.29774,-0.71195c-0.1905,-0.18793 -0.44836,-0.29168 -0.71593,-0.28805h-5.5v-0.5c0.0037,-0.2703 -0.10218,-0.53059 -0.29351,-0.72155c-0.19133,-0.19097 -0.45182,-0.29634 -0.72212,-0.29212zM6,9l1.79297,15.23438c0.118,1.007 0.97037,1.76563 1.98438,1.76563h10.44531c1.014,0 1.86538,-0.75862 1.98438,-1.76562l1.79297,-15.23437z"></path>
                                </g>
                              </g>
                            </svg>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
          <div className="pagination_custom view_application_container_pagination">
            <Pagination
              count={Math.ceil(
                filteredApplicants.length / applicantDataPerPage
              )}
              color="primary"
              className="mt-3"
              page={currentPage}
              onChange={handlePageChange}
            />
            <div className="view_application_container_pagination_btn">
              <button className="excel">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 10.3333V13.4444C15 13.857 14.8361 14.2527 14.5444 14.5444C14.2527 14.8361 13.857 15 13.4444 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V10.3333M4.11111 6.44444L8 10.3333M8 10.3333L11.8889 6.44444M8 10.3333V1"
                    stroke="#F38D00"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                &nbsp; Excel
              </button>
              <button className="email">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5 2.75C18.5 1.7875 17.7125 1 16.75 1H2.75C1.7875 1 1 1.7875 1 2.75M18.5 2.75V13.25C18.5 14.2125 17.7125 15 16.75 15H2.75C1.7875 15 1 14.2125 1 13.25V2.75M18.5 2.75L9.75 8.875L1 2.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                &nbsp; Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HRManagerAllApplications;
