import React from "react";

function Companies() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">Companies</div>
    </div>
  );
}

export default Companies;
