import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../styles/landing_page/landingpage.css";
import logo from "../../../utils/landingpage/images/logo.svg";
import imagetext from "../../../utils/landingpage/images/imagetext.svg";
import Vector from "../../../utils/landingpage/images/Vector.svg";
import Menuicon from "../../../utils/landingpage/images/menuiconTwo.svg";
import Downarrow from "../../../utils/landingpage/images/down-arow.svg";
import notificationIcon from "../../../utils/landingpage/images/notificationIcons.png";
import userIcon from "../../../utils/landingpage/images/logdedUser.png";
import LogOut from "../../reusablecomponents/Logout";
import { fetchLoginUserDetailas } from "../../../redux/actions/letralHiringAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Navbar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLoginUserDetailas());
  }, [dispatch]);

  const [menu, setMenu] = useState(false);
  const [isToken, setIsToken] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [userImg, setUserImg] = useState(null);

  const dropdownRef = useRef(null);

  const sidebarRef = useRef(null);
  const menuButtonRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const sessionToken = sessionStorage.getItem("accessToken");
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));

  const toggleMenu = () => {
    setMenu((prevMenu) => !prevMenu);
  };

  useEffect(() => {
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.id;

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Set the user image URL from the response
        if (response.data.student_photo_url) {
          setUserImg(response.data.student_photo_url);
        }
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (
      menuButtonRef.current &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function hanldeRegister() {
    if (location.pathname.includes("lateral") || location.pathname === "/") {
      navigate("/lateral/register");
    } else {
      navigate("/register");
    }
  }

  function handleProfileClick(event) {
    event.stopPropagation();
    if (location.pathname.includes("lateral")) {
      navigate("/lateral/myProfile");
    }
  }

  function handleSettingClick(event) {
    event.stopPropagation();
    if (location.pathname.includes("lateral")) {
      navigate("/lateral/setting");
    }
  }

  function handleLogin() {
    navigate("/login");
  }

  useEffect(() => {
    if (!!sessionToken) {
      setIsToken(sessionToken);
    }

  }, [sessionToken]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="nav_bar_main">
        <div className="nav_container">
          <div className="nav_row">
            <div className="menu_logo_main_container">
              <div
                ref={menuButtonRef}
                className="menu_icon"
                onClick={toggleMenu}
              >
                <img src={Menuicon} alt="menu-icon" />
              </div>
              <div className="nav_logo">
                {location.pathname.includes("lateral") ? (
                  <Link to="/lateral-hiring">
                    <img src={logo} alt="logo" />
                  </Link>
                ) : (
                  <Link to="/digital-campus-hiring">
                    <img src={logo} alt="logo" />
                  </Link>
                )}
              </div>
            </div>

            <div className="nav_tabs">
              <ul>
                <li>
                  <p
                    className={
                      location.pathname == "/digital-campus-hiring" ? "nav-active-tabs" : ""
                    }
                  >
                    {location.pathname.includes("lateral") ? (
                      <Link to="/lateral-hiring">Home</Link>
                    ) : (
                      <Link to="/digital-campus-hiring">Home</Link>
                    )}
                  </p>
                </li>
                <li className="feature">
                  <span id="nav_solution">
                    <p>Solutions</p>{" "}
                    <span>
                      <img src={Vector} alt="icon" />
                    </span>
                  </span>
                  <div className="nav_drop_down">
                    <p>
                      <Link
                        to="/lateral-hiring"
                        className={
                          location.pathname.includes('lateral') || location.pathname === "/"
                            ? "dropDown-active-tabs"
                            : ""
                        }
                      >
                        Lateral Hiring
                      </Link>
                    </p>

                    <p>
                      <Link
                        to="/digital-campus-hiring"
                        className={
                          location.pathname == "/digital-campus-hiring" ? "dropDown-active-tabs" : ""
                        }
                      >
                        Digital Campus Hiring
                      </Link>
                    </p>
                  </div>
                </li>

                {location.pathname.includes("lateral") ? (
                  isToken == null ? (
                    <li>
                      <p
                        className={
                          location.pathname === "/lateral/alljobs"
                            ? "nav-active-tabs"
                            : ""
                        }
                      >
                        <Link to="/lateral/alljobs">Jobs</Link>
                      </p>
                    </li>
                  ) : (
                    <li className="feature">
                      <span id="nav_solution">
                        <p>Jobs</p>{" "}
                        <span>
                          <img src={Vector} alt="icon" />
                        </span>
                      </span>
                      <div className="nav_drop_down">
                        <p>
                          <Link
                            to="/lateral/alljobs"
                            className={
                              location.pathname == "/lateral/alljobs"
                                ? "dropDown-active-tabs"
                                : ""
                            }
                          >
                            Search Jobs
                          </Link>
                        </p>
                        <p>
                          <Link
                            to="/lateral/savedJobs"
                            className={
                              location.pathname == "/lateral/savedJobs"
                                ? "dropDown-active-tabs"
                                : ""
                            }
                          >
                            Saved Jobs
                          </Link>
                        </p>
                        <p>
                          <Link
                            to="/lateral/myApplication"
                            className={
                              location.pathname == "/lateral/myApplication"
                                ? "dropDown-active-tabs"
                                : ""
                            }
                          >
                            My Application
                          </Link>
                        </p>
                      </div>
                    </li>
                  )
                ) : (
                  <li>
                    <p
                      className={
                        location.pathname == "/campuses"
                          ? "nav-active-tabs"
                          : ""
                      }
                    >
                      <Link to="/campuses">Campuses</Link>
                    </p>
                  </li>
                )}
                <li>
                  <p
                    className={
                      location.pathname == "/about" ? "nav-active-tabs" : ""
                    }
                  >
                    <Link
                      to={
                        (location.pathname.includes("lateral") || location.pathname === "/")
                          ? "/lateral/about"
                          : "/about"
                      }
                    >
                      About
                    </Link>
                  </p>
                </li>
                <li>
                  <p
                    className={
                      location.pathname == "/contact" ? "nav-active-tabs" : ""
                    }
                  >
                    <Link
                      to={
                        (location.pathname.includes("lateral") || location.pathname === "/")
                          ? "/lateral/contact"
                          : "/contact"
                      }
                    >
                      Contact
                    </Link>
                  </p>
                </li>
              </ul>
            </div>

            {isToken == null ? (
              <div className="nav_login">
                <button onClick={handleLogin}>
                  <p>Login</p>
                </button>

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  id="btn"
                  onClick={hanldeRegister}
                >
                  Sign Up
                </motion.button>
              </div>
            ) : (
              <div className="user-profile-container">
                <div className="position-relative">
                  <img
                    src={notificationIcon}
                    width="22.5px"
                    height="24px"
                    alt="..."
                  />
                  <div className="user_notif_cont"></div>
                </div>

                <div
                  className="user-profile"
                  onClick={() => toggleDropdown()}
                  ref={dropdownRef}
                >
                  <img
                    src={userImg || null}
                    alt="user img"
                    className="avatar"
                  />
                  <span className="name">
                    {userData.name ? userData.name : `User`}
                  </span>
                  <span className={`dropdown-icon ${isOpen ? "open" : ""}`}>
                    ▼
                  </span>
                </div>

                {isOpen && (
                  <div
                    className="dropdown-content"
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <ul>
                      <li onClick={(e) => handleProfileClick(e)}>Profile</li>
                      <li onClick={(e) => handleSettingClick(e)}>Settings</li>
                      <li onClick={(e) => e.stopPropagation()}>
                        <LogOut color={"#272727"} />
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}

          </div>
        </div>
      </nav>

      <div ref={sidebarRef} className={`sidebar ${menu ? "open " : "closed "}`}>
        <ul>
          <li>
            <div className="imagetextdiv">
              <a href="/lateral-hiring">
                <img className="imagetext" src={imagetext} alt="logo" />
              </a>
            </div>
            <p>
              <Link
                to="/digital-campus-hiring"
                onClick={toggleMenu}
                className={location.pathname === "/digital-campus-hiring" ? "navactivemobile" : ""}
              >
                Home
              </Link>
            </p>
          </li>
          <li className="feature">
            <span id="sidebar_solution">
              <p>Solutions</p>
              {""} &nbsp;&nbsp;&nbsp;
              <span className="down-arrow">
                <img src={Downarrow} alt="down-arrow" />
              </span>
            </span>
            <div className="feature_sub_menu px-3">
              <p className="py-1">
                <Link
                  to="/campuses"
                  onClick={toggleMenu}
                  className={
                    location.pathname === "/campuses" ? "navactivemobile" : ""
                  }
                >
                  Campus Hiring
                </Link>
              </p>
              <p className="py-2">
                <Link
                  to="/lateral-hiring"
                  onClick={toggleMenu}
                  className={
                    location.pathname.includes('lateral')
                      ? "navactivemobile"
                      : ""
                  }
                >
                  Lateral Hiring
                </Link>
              </p>
            </div>
          </li>
          <li>
            <p>
              <li className="feature">
                <span id="sidebar_solution">
                  <p>Jobs</p>
                  {""} &nbsp;&nbsp;&nbsp;
                  <span className="down-arrow">
                    <img src={Downarrow} alt="down-arrow" />
                  </span>
                </span>
                <div className="feature_sub_menu px-3">
                  <p className="py-1">
                    <Link
                      to="/lateral/alljobs"
                      onClick={toggleMenu}
                      className={
                        location.pathname === "/lateral/alljobs"
                          ? "navactivemobile"
                          : ""
                      }
                    >
                      Search Jobs
                    </Link>
                  </p>
                  <p className="py-2">
                    <Link
                      to="/lateral/savedJobs"
                      onClick={toggleMenu}
                      className={
                        location.pathname === "/lateral/savedJobs"
                          ? "navactivemobile"
                          : ""
                      }
                    >
                      Saved Jobs
                    </Link>
                  </p>
                  <p className="py-1">
                    <Link
                      to="/lateral/myApplication"
                      onClick={toggleMenu}
                      className={
                        location.pathname === "/lateral/myApplication"
                          ? "navactivemobile"
                          : ""
                      }
                    >
                      My Application
                    </Link>
                  </p>
                </div>
              </li>

              <Link
                to="/campuses"
                onClick={toggleMenu}
                className={
                  location.pathname === "/campuses" ? "navactivemobile" : ""
                }
              >
                Campuses
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link
                to={
                  (location.pathname.includes("lateral") || location.pathname === "/")
                    ? "/lateral/about"
                    : "/about"
                }
                onClick={toggleMenu}
                className={
                  location.pathname === "/about" ? "navactivemobile" : ""
                }
              >
                About
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link
                to={
                  (location.pathname.includes("lateral") || location.pathname === "/")
                    ? "/lateral/contact"
                    : "/contact"
                }
                onClick={toggleMenu}
                className={
                  location.pathname === "/contact" ? "navactivemobile" : ""
                }
              >
                Contact
              </Link>
            </p>
          </li>
          {isToken == null ? (
            <>
              {" "}
              <li>
                <button
                  onClick={handleLogin}
                  className="menu_login_btn btn border w-100 "
                >
                  <p>Login</p>
                </button>
              </li>
              <li>
                <button
                  onClick={hanldeRegister}
                  className="menu_sign_up_btn btn text-light w-100"
                >
                  <p>Sign Up</p>
                </button>
              </li>
            </>
          ) : (
            <button className="menu_sign_up_btn btn text-light w-40">
              <LogOut color={"white"} />
            </button>
          )}
        </ul>
      </div>
      {/* Mobile and tablet view menu end */}
    </>
  );
};

export default Navbar;