import React from "react";
import { motion } from "framer-motion";
import "../../../styles/landing_page/landingpage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import userreview from "../../../utils/landingpage/images/userreview.svg";
import rightarrow from "../../../utils/landingpage/images/rightarrow.svg";
import leftarrow from "../../../utils/landingpage/images/leftarrow.svg";
import infograins from "../../../utils/landingpage/images/infograins.svg";

export const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={leftarrow} style={{ height: "31px" }} />
    </div>
  );
};

export const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={rightarrow} style={{ height: "31px" }} />
    </div>
  );
};

const testimonials = [
  {
    id: 1,
    imageSrc: infograins,
    altText: "testimonial-img",
    reviewText: `StartNaukri.com has completely streamlined our recruitment process. With its user-friendly platform, we can easily post jobs for both freshers and experienced candidates, covering campus and lateral hiring needs effortlessly. The automation features make it simple to schedule interviews, update candidate status based on skills, and manage every stage of hiring—all in one place.`,
    userName: "Ajay Shukla",
    userPosition: "CEO",
  },
  {
    id: 2,
    imageSrc: infograins,
    altText: "testimonial-img",
    reviewText: `StartNaukri.com has completely streamlined our recruitment process. With its user-friendly platform, we can easily post jobs for both freshers and experienced candidates, covering campus and lateral hiring needs effortlessly. The automation features make it simple to schedule interviews, update candidate status based on skills, and manage every stage of hiring—all in one place.`,
    userName: "Ajay Shukla",
    userPosition: "CEO",
  },
  {
    id: 3,
    imageSrc: infograins,
    altText: "testimonial-img",
    reviewText: `StartNaukri.com has completely streamlined our recruitment process. With its user-friendly platform, we can easily post jobs for both freshers and experienced candidates, covering campus and lateral hiring needs effortlessly. The automation features make it simple to schedule interviews, update candidate status based on skills, and manage every stage of hiring—all in one place.`,
    userName: "Ajay Shukla",
    userPosition: "CEO",
  },
];

const Testimonial = () => {
  // testimonial slider settings
  const testimonial = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      <section className="testimonial_main">
        <div className="testimonial_container">
          <motion.div
            className="testimonial_heading"
            initial={{ y: -60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h3>What our client say about us.</h3>
            <h4>See what our user are saying. </h4>
          </motion.div>
          <motion.div
            className="slider-container"
            initial={{ y: 60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Slider {...testimonial}>
              {testimonials.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="testimonial_slider_container"
                >
                  <div className="testimonial_hero_section">
                    <div className="testimonial_image">
                      <div className="testimonial_img_background"></div>
                      <div className="testimonial_user_image">
                        <img
                          src={testimonial.imageSrc}
                          alt={testimonial.altText}
                        />
                      </div>
                    </div>

                    <div className="testimonial_content">
                      <p>{testimonial.reviewText}</p>
                      <h5>{testimonial.userName}</h5>
                      <h6>{testimonial.userPosition}</h6>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;