import axios from "axios";
import { setGetStudentProfileData } from "../../reducers/student_profile_reducer";
import { setIsSubmitted } from "../../reducers/student_profile_reducer";
import { setIsSubmittedSkill } from "../../reducers/student_profile_reducer";

export const fetchStudentProfileData = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userSessionData = sessionStorage.getItem("userDetails");

  if (accessToken && userSessionData) { 
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.id;

    if (!userId) return; 

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`;

    try {
      const response = await axios.get(url, config);

      dispatch(setGetStudentProfileData(response.data));

      console.log(response.data)

      // if (response.data.certificates?.length > 0) {
      //   dispatch(setIsSubmittedCertification(true));
      // }
      if (response.data.skill_name?.length > 0) {
        dispatch(setIsSubmittedSkill(true));
      }
    //   if (response.data.employments?.length > 0) {
    //     dispatch(setIsSubmittedEmployment(true));
    //   }
      if (response.data.dob !== null && response.data.phone_no !== null) {
        dispatch(setIsSubmitted(true))
      }
    //   if (response.data.qualifications?.length > 0) {
    //     dispatch(setIsSubmittedQualification(true));
    //   }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User session data or access token is missing.");
  }
};
