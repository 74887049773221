import React from "react";
import "../../../styles/admin/adminhome.css";
function AdminHome() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">AdminHome</div>
    </div>
  );
}

export default AdminHome;
