import React from "react";

function AdminJobListing() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        AdminJobListing
      </div>
    </div>
  );
}

export default AdminJobListing;
