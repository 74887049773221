import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/assignmentModel/assignmentModel.css"
import { useLocation } from 'react-router-dom';
import searchIcon from "../../../utils/landingpage/images/search-icon.svg";
import allFilters from "../../../utils/landingpage/images/allFilters.svg";
import upArrow from "../../../utils/landingpage/images/uparrowIcon.svg";
import downArrow from "../../../utils/landingpage/images/downIcon.svg";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg"
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg"
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg"
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png"
import dummy_logo from '../../../utils/landingpage/images/dummy_logo.png'
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg"
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg"
import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import logofacebook from "../../../utils/landingpage/images/logo_facebook.svg";
import logotwitter from "../../../utils/landingpage/images/logo_twitter.svg";
import logoyoutube from "../../../utils/landingpage/images/logo_youtube.svg";
import logoinsta from "../../../utils/landingpage/images/logo_insta.svg";
import googleicon from "../../../utils/auth/images/googleicon.svg";
import axios from 'axios';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from '../../../redux/actions/loginAction';

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    password: Yup.string().required("Password is required"),
});

const initialValues = {
    email: "",
    password: "",
};

const JobDetails = () => {

    const [experience, setExperience] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [save, setSave] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Most Relevance');
    const [isToken, setIsToken] = useState(false);
    const [token, setToken] = useState(null)
    const [isLogin, setIsLogin] = useState(null);
    const [jobDetails, setDetails] = useState([]);
    const [similarJobData, setSimilarJobData] = useState()
    const [jobId, setJobId] = useState(null);
    const { id } = useParams();
    const [assignmentModel, setAssignmentModel] = useState(false);
    const [assignmentQuestions, setAssignmentQuestions] = useState([])

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [timeRemaining, setTimeRemaining] = useState(10 * 60);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [profileCompletion, setProfileCompletion] = useState('');

    const sliderRef = useRef(null);
    const progressRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const { loading, error, user } = useSelector((state) => state.auth);
    const loginResponse = useSelector((state) => state.auth.loginResponse);

    useEffect(() => {
        if (sliderRef.current && progressRef.current) {
            const percentage = (experience / 30) * 100;
            progressRef.current.style.width = `${percentage}%`;
        }
    }, [experience]);
    useEffect(() => {
        if (!!loginResponse.access_token) {
            setIsToken(false)
            const getToken = sessionStorage.getItem('accessToken');
            setIsLogin(getToken);
        }
    }, [loginResponse])
    const getPostedDate = (createdAt) => {
        return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
    };

    const options = ['Most Relevance', 'Date Posted', 'Salary', 'Experience Level'];

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const companyName = "Microsoft Pvt. Ltd.";
    const maxLength = 19;

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await dispatch(loginUser(values, navigate, 'applyLogin', jobId));
        },
    });

    useEffect(() => {
        const getToken = sessionStorage.getItem('accessToken');
        setIsLogin(getToken);
        setJobId(id);

        const newSession = sessionStorage.getItem('accessToken');
        setToken(newSession);

    }, [id, isLogin]);

    useEffect(() => {
        if (jobId || token) {  // Check if jobId is available
            const userToken = token;

            axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                headers: {
                    Authorization: userToken && `Bearer ${userToken}`,
                }
            })
                .then(response => {
                    console.log('Job details fetched successfully', response.data);
                    setDetails(response.data)
                })
                .catch(error => {
                    console.log('Error occurred', error);
                });
        } else {
            console.log('Job ID is not available, skipping API call.');
        }
    }, [jobId, token]);

    function handleLogin() {
        console.log('----logged');
        setIsToken(true);
    }

    const formatSalary = (salary) => {
        // Ensure the salary is a number and round it properly
        if (salary) {
            const floatSalary = parseFloat(salary);  // Convert string to float
            return floatSalary % 1 === 0 ? floatSalary.toFixed(0) : floatSalary.toFixed(2);
        }
        return null; // If salary is null or undefined, return null
    };

    function handleSave(index) {
        const userDetails = {
            job_id: index,
        };

        const userToken = token;

        axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/job/save/`,
            userDetails,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                }
            }
        )
            .then(response => {
                console.log('Save successful', response.data.detail);
                setSave(response.data.detail); // Set the saved message if needed
                toast.success(response.data.detail); // Use the API response message in Toastify

                if (jobId) {  // Check if jobId is available
                    const userToken = token;

                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        }
                    })
                        .then(response => {
                            console.log('Job details fetched successfully', response.data);
                            setDetails(response.data)
                        })
                        .catch(error => {
                            console.log('Error occurred', error);
                        });
                } else {
                    console.log('Job ID is not available, skipping API call.');
                }
            })
            .catch(error => {
                console.log('Error occurred', error);
                toast.error('Failed to save the job. Please try again.');

                if (jobId) {  // Check if jobId is available 
                    const userToken = token;

                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                        headers: {
                            Authorization: userToken ? `Bearer ${userToken}` : '',
                        }
                    })
                        .then(response => {
                            console.log('Job details fetched successfully', response.data);
                            setDetails(response.data)
                        })
                        .catch(error => {
                            console.log('Error occurred', error);
                        });
                } else {
                    console.log('Job ID is not available, skipping API call.');
                }
            });
    }

    function handleUnSave(index) {
        const userToken = token;

        axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/job/unsave/`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
                data: {
                    job_id: index,
                }
            }
        )
            .then(response => {
                console.log('UnSave successful', response);
                // Show success toast notification
                toast.success(response.data.detail);

                if (jobId) {  // Check if jobId is available
                    const userToken = token;

                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/${jobId}/`, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        }
                    })
                        .then(response => {
                            console.log('Job details fetched successfully', response.data);
                            setDetails(response.data)
                        })
                        .catch(error => {
                            console.log('Error occurred', error);
                        });
                } else {
                    console.log('Job ID is not available, skipping API call.');
                }

            })
            .catch(error => {
                console.log('Error occurred', error);
                // Show error toast notification
                toast.success('Something Getting Wrong');
            });
    }

    useEffect(() => {
        if (jobId) {  // Check if jobId is available
            const userToken = token;

            axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/similar_jobs/`, {
                headers: {
                    Authorization: userToken && `Bearer ${userToken}`,
                }
            })
                .then(response => {
                    console.log('Similar Job details fetched successfully', response.data);
                    setSimilarJobData(response.data);  // Set the fetched job data
                })
                .catch(error => {
                    console.log('Error occurred', error);
                });
        } else {
            console.log('Job ID is not available, skipping API call.');
        }
    }, [jobId]);

    const handleApplyNow = async () => {

        if (profileCompletion !== 100) {
            Swal.fire({
                title: 'Profile Incomplete',
                text: 'Complete your profile to 100% before applying.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Complete Now',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/lateral/myProfile');
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.close();
                }
            });
            return;
        }

        if (jobId) {
            try {
                const accessToken = sessionStorage.getItem('accessToken');

                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }
                );

                console.log('Application successful:', response.data.assessment_required);

                if (response.data.assessment_required === true) {
                    const transformedQuestions = response.data.questions.map((question) => {
                        let correctOptionId;
                        // Mapping correct_option text to corresponding option id
                        if (question.correct_option === question.option_1) correctOptionId = '1';
                        if (question.correct_option === question.option_2) correctOptionId = '2';
                        if (question.correct_option === question.option_3) correctOptionId = '3';
                        if (question.correct_option === question.option_4) correctOptionId = '4';

                        return {
                            ...question,
                            options: [
                                { id: '1', text: question.option_1 },
                                { id: '2', text: question.option_2 },
                                { id: '3', text: question.option_3 },
                                { id: '4', text: question.option_4 }
                            ],
                            correctAnswer: correctOptionId // Store the correct option id here
                        };
                    });

                    setAssignmentModel(true);
                    setAssignmentQuestions(transformedQuestions);
                } else {
                    toast.success('Application submitted successfully!');
                }
            } catch (error) {
                const errorMessage = error.response
                    ? error.response.data.detail || 'Application failed. Please try again.'
                    : error.message;

                console.error('Error applying for job:', errorMessage);
                toast.error(errorMessage);
            }
        } else {
            toast.info('Job ID is missing.');
        }
    };

    // const handleApplyNow = async () => {
    //     if (jobId) {
    //         try {
    //             const accessToken = sessionStorage.getItem('accessToken');

    //             const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`, {
    //             }, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${accessToken}`
    //                 }
    //             });

    //             console.log('Application successful:', response.data.assessment_required);
    //             if (response.data.assessment_required === true) {
    //                 const transformedQuestions = response.data.questions.map((question) => {
    //                     let correctOptionId;
    //                     // Mapping correct_option text to corresponding option id
    //                     if (question.correct_option === question.option_1) correctOptionId = '1';
    //                     if (question.correct_option === question.option_2) correctOptionId = '2';
    //                     if (question.correct_option === question.option_3) correctOptionId = '3';
    //                     if (question.correct_option === question.option_4) correctOptionId = '4';

    //                     return {
    //                         ...question,
    //                         options: [
    //                             { id: '1', text: question.option_1 },
    //                             { id: '2', text: question.option_2 },
    //                             { id: '3', text: question.option_3 },
    //                             { id: '4', text: question.option_4 }
    //                         ],
    //                         correctAnswer: correctOptionId // Store the correct option id here
    //                     };
    //                 });

    //                 setAssignmentModel(true);
    //                 setAssignmentQuestions(transformedQuestions);
    //             }
    //             else {
    //                 toast.success('Application submitted successfully!');
    //             }
    //         } catch (error) {
    //             const errorMessage = error.response ? error.response.data.detail || 'Application failed. Please try again.' : error.message;

    //             console.error('Error applying for job:', errorMessage);
    //             toast.error(errorMessage);
    //         }
    //     } else {
    //         toast.info('Job ID is missing.');
    //     }
    // };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prev) => {
                if (prev <= 0) {
                    clearInterval(timer);
                    // handleSubmit();
                    setAssignmentModel(false)
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleAnswerSelect = (optionId) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestion]: optionId
        });

        const correctAnswerId = assignmentQuestions[currentQuestion].correctAnswer;

        console.log(`Selected Option ID: ${optionId}, Correct Option ID: ${correctAnswerId}`);

        if (optionId === correctAnswerId) {
            console.log(`Question ${currentQuestion + 1} selected correct answer: ${optionId}`);
        } else {
            console.log(`Question ${currentQuestion + 1}: Incorrect answer selected. You selected: ${optionId}`);
        }
    };

    const handleNext = () => {
        if (currentQuestion < assignmentQuestions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handleSkip = () => {
        handleNext();
    };

    const handleSubmit = async () => {
        let correctAnswers = [];

        // Collect correct answers
        assignmentQuestions.forEach((question, index) => {
            const selectedAnswer = selectedAnswers[index];

            if (selectedAnswer && selectedAnswer === question.correctAnswer) {
                const selectedOptionText = question.options.find(option => option.id === selectedAnswer)?.text;

                correctAnswers.push({
                    question_id: question.id,
                    selected_option: selectedOptionText
                });
            }
        });

        const results = {
            answers: correctAnswers
        };

        console.log('Quiz Results:', results);

        if (jobId) {
            try {
                const accessToken = sessionStorage.getItem('accessToken');

                // Send results as part of the POST body
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
                    results, // Send 'results' object in the body
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }
                );

                if (response.data.detail) {
                    setAssignmentModel(false)
                    toast.success('Application submitted successfully!');
                    setQuizCompleted(true);
                    setAssignmentModel(false)
                    setCurrentQuestion(0);
                    setSelectedAnswers({})
                    setTimeRemaining(10 * 60)

                } else {
                    setAssignmentModel(false)
                    toast.error('Application failed!');
                    setQuizCompleted(true);
                    setCurrentQuestion(0);
                    setSelectedAnswers({})
                    setTimeRemaining(0)
                }
            } catch (error) {
                const errorMessage = error.response
                    ? error.response.data.detail || 'Application failed. Please try again.'
                    : error.message;

                console.error('Error applying for job:', errorMessage);
                setQuizCompleted(true);
                setAssignmentModel(false)
                setCurrentQuestion(0);
                setSelectedAnswers({})
                setTimeRemaining(10 * 60)
                toast.error(errorMessage);
            }
        }
    };

    const handleClickRedirect = () => {
        navigate('/lateral/register');
    }

    const handleBackToHome = () => {
        setAssignmentModel(false)
        setQuizCompleted(true);
        setCurrentQuestion(0);
        setSelectedAnswers({})
        setTimeRemaining(10 * 60)
    }

    const fetchProfileCompletion = async () => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/profile_completion/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,  // Pass the token in the Authorization header
                },
            });

            const completionValue = parseInt(response.data.profile_completion_percentage.replace('%', '')); // Get value as integer
            setProfileCompletion(completionValue);
        } catch (error) {
            console.error('Error fetching profile completion:', error);
        }
    };

    useEffect(() => {
        fetchProfileCompletion();
    }, []);

    return (<>
        {/* <ToastContainer /> */}
        <div className='allJobs_main_container'>
            <div className='allJobs_container'>
                <section className="contact_header alljobs_header jobDetails_header">
                    <h2 className='d-grid align-items-center'>
                        <span>Job Details</span>
                    </h2>
                    <h6>Full Details About Job and Company.</h6>
                </section>

                <section className='jobDetails_main_container'>
                    <div className='jobDetails_first_container'>
                        <b className='jobDetails_b'>Job Deatils</b>

                        <div className='pt-4 pb-2'>
                            <div className="job-listings">
                                <div className="job-listing">
                                    <div className="company-info">
                                        <img src={jobDetails.company_profile?.company_logo_url || dummy_logo} alt={`logo`} className="company-logo" />
                                        <div>
                                            <h2>{jobDetails.title}</h2>
                                            <div className='d-flex gap-2 jdrc_class'>
                                                <div className="company-name">{jobDetails?.company || 'Not Mentioned'}</div>
                                                <div className="rating">
                                                    <span className="star">★</span> {jobDetails.company_profile?.company_rating || '0'} <span style={{ fontWeight: "300", color: "#3c3c3c69" }}>|</span> {jobDetails?.company_profile?.company_review || '0'} Reviews
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="job-details job_details_new">
                                        <span className="detail"> <span className='details_job_icon'><img src={experienceIcon} alt='...' /> </span> {jobDetails?.min_experience || '0'}-{jobDetails?.max_experience || '0'} yrs <span className='stand_icon'>|</span> </span>
                                        <span className="detail">
                                            <span className='details_job_icon'><img src={dollerIcon} alt='...' /></span>
                                            {jobDetails?.min_salary && jobDetails?.max_salary
                                                ? `${formatSalary(jobDetails.min_salary)}-${formatSalary(jobDetails.max_salary)} LPA`
                                                : 'Not specified'}
                                            <span className='stand_icon'>|</span>
                                        </span>
                                        <span className="detail"> <span className='details_job_icon'><img src={locationIcon} alt='...' /> </span> {jobDetails?.job_location || 'Not Mentioned'} </span>
                                    </div>
                                    <div className="listing-footer">
                                        <div className="job-details">
                                            <span className="detail">
                                                Posted:
                                                <span className='custom_fonts'>
                                                    {jobDetails?.created_at ? getPostedDate(jobDetails?.created_at) : 'Date not available'}
                                                </span>
                                                <span className='stand_icon'>|</span>
                                            </span>
                                            <span className="detail"> Openings: <span className='custom_fonts'>{jobDetails?.vacancy || '0'}</span> <span className='stand_icon'>|</span> </span>
                                            <span className="detail"> Applicants: <span className='custom_fonts'>{jobDetails?.total_applicants | '0'}</span> </span>
                                        </div>
                                        {isLogin ?
                                            <div className='job_listing_button_reverse' >
                                                {jobDetails.is_already_saved == true && (<button className="rounded-3 btn jobDetails_register_to_apply_btn " onClick={() => handleUnSave(jobDetails.id)}>
                                                    Saved
                                                </button>)}
                                                {jobDetails.is_already_saved == false && (<button className="rounded-3 btn jobDetails_register_to_apply_btn " onClick={() => handleSave(jobDetails.id)}>
                                                    Save
                                                </button>)}
                                                <button onClick={handleApplyNow} className="jobDetails_button">Apply Now</button>
                                            </div>
                                            :
                                            <div className='d-flex gap-2'>
                                                <button onClick={handleClickRedirect} className="rounded-3 btn jobDetails_register_to_apply_btn ">
                                                    Register to apply
                                                </button>
                                                <button onClick={handleLogin} className="jobDetails_button">Login to apply</button>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='jobDatails_description'>
                            <h2>Job Description</h2>
                            <p>{jobDetails?.description}</p> <br />
                            <p><span>Role:</span> {jobDetails?.title}</p>
                            <p><span>Industry Type:</span> {jobDetails?.category}</p>
                            <p><span>Employment Type:</span> {jobDetails?.type || 'Not Mentioned'}</p>
                            <p><span>Education:</span> {jobDetails?.qualification}</p>
                            <p><span>Key Skills:</span>
                                {jobDetails?.job_skills?.map((skill, index) => (
                                    <span style={{ fontWeight: '400' }} key={index}> {skill.skill_name}{index !== jobDetails.job_skills.length - 1 ? ', ' : ''}</span>
                                ))}
                            </p>
                        </div>

                        <div className="about-company">
                            <h2>About Company</h2>
                            <p>
                                {jobDetails?.company_profile?.company_desc || 'Not Mentioned'}
                            </p>
                            <div className="social-icons">
                                <a href={jobDetails?.company_profile?.facebook} className="social-icon">
                                    <img src={logofacebook} alt="Group-img" />
                                </a>
                                <a href={jobDetails?.company_profile?.twitter} className="social-icon">
                                    <img src={logotwitter} alt="Group-img" />
                                </a>
                                <a href={jobDetails?.company_profile?.linked_in} className="social-icon">
                                    <img src={logoyoutube} alt="Group-img" />
                                </a>
                                <a href={jobDetails?.company_profile?.instagram} className="social-icon">
                                    <img src={logoinsta} alt="Group-img" />
                                </a>
                            </div>
                        </div>

                    </div>

                    <div className={`jobDetails_second_container ${similarJobData?.length < 1 ? `No_similar_jobs_availble` : ''}`} >
                        <b className='jobDetails_b'>Similar Jobs</b>

                        <div className='pt-4'>
                            {similarJobData && similarJobData.length > 0 ? (
                                similarJobData.slice(0, 4).map((job) => (
                                    <div className="job-listings" key={job.id}>
                                        <div className="job-listing">
                                            <div className="company-info">
                                                <Link to={`/lateral/jobDetails/${job.id}`}>
                                                    <img
                                                        src={job.company_profile?.company_logo_url || dummy_logo}
                                                        alt={'logo'}
                                                        className="company-logo"
                                                    />
                                                </Link>
                                                <div>
                                                    <Link to={`/lateral/jobDetails/${job.id}`}>
                                                        <h2>{job.title}</h2>
                                                    </Link>
                                                    <div className='d-flex gap-2'>
                                                        <div className="company-name">
                                                            {job?.company_profile?.company_name
                                                                ? (job?.company_name?.length > maxLength
                                                                    ? `${job.company_name.substring(0, maxLength)}...`
                                                                    : job.company_name)
                                                                : "Not Mentioned"}
                                                        </div>
                                                        <div className="rating">
                                                            <span className="star">★</span> {job?.company_profile?.company_rating || '0'}
                                                            <span style={{ fontWeight: "300", color: "#3c3c3c69" }}>|</span>
                                                            <span>{`${job?.company_profile?.company_review || '0'} Reviews`.slice(0, 7) + ".."}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="job-details">
                                                <span className="detail">
                                                    <span className='details_job_icon'><img src={experienceIcon} alt='...' /> </span>
                                                    {`${job?.min_experience || '0'} - ${job?.max_experience || '0'} yrs`}
                                                    <span className='stand_icon'>|</span>
                                                </span>
                                                <span className="detail">
                                                    <span className='details_job_icon'><img src={dollerIcon} alt='...' /> </span>
                                                    {job.min_salary ? `${job.min_salary}-${job.max_salary} LPA` : '0'}
                                                    <span className='stand_icon'>|</span>
                                                </span>
                                                <span className="detail">
                                                    <span className='details_job_icon'><img src={locationIcon} alt='...' /> </span>
                                                    {job.job_location ? job?.job_location?.slice(0, 9) + ".." : "Not Mentioned"}
                                                </span>
                                            </div>
                                            <p style={{ fontSize: "14px" }}>
                                                {job.description.slice(0, 73) + ".."}
                                            </p>
                                            <div className="listing-footer">
                                                <div className="job-details">
                                                    <span className="detail"> Posted: <span className='custom_fonts'>
                                                        {`${getPostedDate(job?.created_at)}`}
                                                    </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No similar jobs available.</p>
                            )}
                        </div>
                    </div>

                </section>
            </div>
        </div>

        {isToken && (
            <div className='jobDetails_login_popup_main'>
                <div className='jobDetails_login_popup_second_main'>
                    <div className='jobDeatils_login_crossIcon' onClick={() => setIsToken(false)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9999 14.9999L1 1M15 1L1 15" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className="register_content_second_container jobDetails_login_popup_second_container">
                        <h2 className="login_heading">Login</h2>
                        <div className="register_header_line"></div>
                        <div className="register_account_options">
                            <p>Please enter your login details</p>
                        </div>
                        <form
                            onSubmit={formik.handleSubmit}
                            className="register_form_container"
                        >
                            <div className="register_form_section_candidate">
                                <div className="candidate_input_group_container">
                                    <div className="login_candidate_input_group">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="register_error_msg">
                                                {formik.errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="login_candidate_input_group">
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="Enter your password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="register_error_msg">
                                                {formik.errors.password}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                {/* <div className="forgot_password_container d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <input type="checkbox" style={{ width: "10px" }} />
                                        <p className="remember_password">Remember Password</p>
                                    </div>
                                    <div>
                                        <p className="forgot_Password">forgot Password?</p>
                                    </div>
                                </div> */}
                            </div>

                            <div className="register_send_btn_container pt-4">
                                <button
                                    type="submit"
                                    className="login_submit_btn"
                                    disabled={formik.isSubmitting}
                                >
                                    {loading ? <div className="spinner"></div> : "Login"}
                                </button>
                                <div className="Login_partitan_line">
                                    <div className="Login_partitan_line_one"></div>
                                    <span>or</span>
                                    <div className="Login_partitan_line_tow"></div>
                                </div>
                                <button className="google_register_send_btn">
                                    <img src={googleicon} alt="google-auth" />
                                    <div className='fw-medium'>Login with google </div>
                                </button>
                                <div className="mt-4 pb-4 text-center fw-normal" style={{ fontSize: "14px" }}>
                                    You don’t have an account?{" "}
                                    <Link to={"/register"} style={{ color: "#F38D00", textDecoration: "none" }}>Signup</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )}

        {assignmentModel && (<>
            <div className="quiz-container">
                <div className="content-wrapper">
                    <div className="header">
                        <button className="back-button" onClick={handleBackToHome}>
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 13L1.8858 7.75926C1.42019 7.36016 1.42019 6.63984 1.8858 6.24074L8 1" stroke="#F38D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Back To Home
                        </button>
                    </div>

                    <div className="quiz-layout">
                        <div className="quiz-main">
                            <div className="main-content">
                                <h1 className="quiz-title">Clear the all assessment questions for enter</h1>
                                <p className="quiz-subtitle">Answer all questions to complete the assessment </p>

                                {assignmentQuestions.length > 0 && (
                                    <div className="question-card">
                                        <h2 className="question-text">
                                            {assignmentQuestions[currentQuestion].question_text}
                                        </h2>
                                        <div className="options-container">
                                            {assignmentQuestions[currentQuestion].options.map((option) => (
                                                <button
                                                    key={option.id}
                                                    className={`option-button ${selectedAnswers[currentQuestion] === option.id ? 'selected' : ''}`}
                                                    onClick={() => handleAnswerSelect(option.id)}
                                                >
                                                    <span className="option-id">{option.id}</span>
                                                    <span className="option-text">{option.text}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div className="action-buttons">
                                    {currentQuestion < assignmentQuestions.length - 1 ? (
                                        <>
                                            <button onClick={handleNext} className="btn-primary">
                                                Save & Next
                                            </button>
                                            <button onClick={handleSkip} className="btn-secondary">
                                                Skip
                                            </button>
                                        </>
                                    ) : (
                                        <button onClick={handleSubmit} className="btn-primary">
                                            Submit Quiz
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="quiz-sidebar">
                            <div className="timer-card">
                                <div className="timer-circle-container">
                                    <div className="timer-display">
                                        <span className="time">{formatTime(timeRemaining)}</span>
                                    </div>
                                    <svg className="timer-svg" viewBox="0 0 36 36">
                                        <path
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                            className="timer-circle-bg"
                                        />
                                        <path
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                            className="timer-circle"
                                            style={{
                                                strokeDasharray: `${(timeRemaining / (10 * 60)) * 100}, 100`
                                            }}
                                        />
                                    </svg>
                                </div>
                                <p className="timer-text">Time Remaining</p>
                            </div>

                            <div className="questions-list-card">
                                <h3 className="list-title">Question List</h3>
                                <div className="questions-list">
                                    {assignmentQuestions.map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setCurrentQuestion(index)}
                                            className={`question-list-item ${index === currentQuestion ? 'active' : ''} ${selectedAnswers[index] ? 'completed' : ''}`}
                                        >
                                            <span style={{ fontWeight: "500" }}>Quiz Question {index + 1}</span>
                                            {selectedAnswers[index] && (
                                                <span className="check-icon">✓</span>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
        }

    </>);
};

export default JobDetails;