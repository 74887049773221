import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import '../.../../../../../styles/dashboards/clgstudent/studentprofile.css'
import PersonalInformation from './student_Personal_Info';
import StudentQualification from './student_qualification_info';
import StudentCertificationInfo from './student_certification_info';
import { fetchStudentProfileCompletion } from '../../../../redux/actions/student_profilePercentage';
import { useSelector, useDispatch } from 'react-redux';
import StudentSkillInfo from './student_skill_info';

const StudentProfile = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [profileCompletion, setProfileCompletion] = useState(70);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchStudentProfileCompletion());
  }, [dispatch])
  
  const UserPercenatageValue = useSelector((state) => state.studentProfilePercentage.studentProfilePercentageValue)

  const remainingCompletion = 100 - (UserPercenatageValue || 0);

  const pieChartData = {
    datasets: [
      {
        data: [UserPercenatageValue || 0, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">

        <ul className="student_campus_profile_tabs">
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'personal' ? 'active' : ''}`}
              onClick={() => setActiveTab('personal')}
            >
              Personal Information
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'qualifications' ? 'active' : ''}`}
              onClick={() => setActiveTab('qualifications')}
            >
              Qualifications
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'certifications' ? 'active' : ''}`}
              onClick={() => setActiveTab('certifications')}
            >
              Certifications
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'skills' ? 'active' : ''}`}
              onClick={() => setActiveTab('skills')}
            >
              Skills
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'experience' ? 'active' : ''}`}
              onClick={() => setActiveTab('experience')}
            >
              Internship/Work Experience
            </button>
          </li>
        </ul>

        <div className='d-flex justify-content-between align-items-start'>
          <div className="student_campus_profile_card">
            <div className="student_campus_profile_card_body">
              {activeTab === 'personal' && (<>
                <PersonalInformation />
              </>)}

              {activeTab === 'qualifications' && (
                <StudentQualification />
              )}

              {activeTab === 'certifications' && (
                <StudentCertificationInfo />
              )}

              {activeTab === 'skills' && (
                <StudentSkillInfo/>
              )}

              {activeTab === 'experience' && (
                <h1>hello my experience details are here</h1>
              )}
            </div>
          </div>

          <div style={{ width: "28%" }}>
            <div className='student_campus_profile_social'>
              <p>Complete Your Profile</p>
              <div className="mr-4 epf-chart-wrapper">
                <div className="epf-chart-container student_campus_canvas_container">
                  <Pie data={pieChartData} options={pieChartOptions} />
                  <div className='student_campus_canvas_percentage_value'>{`${UserPercenatageValue}%` || "0%"}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="student_campus_profile_social">
              <h3 className="student_campus_profile_social_title">
                Social Media Links
                <i className="bi bi-box-arrow-up-right student_campus_profile_social_icon"></i>
              </h3>
              <div className="student_campus_profile_social_list">
                {[
                  { platform: 'Instagram', url: 'instagram.com/username', icon: 'bi-instagram' },
                  { platform: 'Twitter', url: 'twitter.com/username', icon: 'bi-twitter' },
                  { platform: 'LinkedIn', url: 'linkedin.com/in/username', icon: 'bi-linkedin' }
                ].map((social) => (
                  <div key={social.platform} className="student_campus_profile_social_item">
                    <div className="student_campus_profile_social_link">
                      <i className={`bi ${social.icon} student_campus_profile_social_platform_icon`}></i>
                      <span className="student_campus_profile_social_url">{social.url}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

      </div>

    </div >
  );
};

export default StudentProfile;