import "../../../styles/dashboards/campusecandidaterequest.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import filterIcon from "../../../utils/dashboards/filterIcon.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
export default function AdminRequest() {
  const [pendingCompanies, setPendingCompanies] = useState([]);
  const [rejectedCompanies, setRejectedCompanies] = useState([]);
  const [pendingColleges, setPendingColleges] = useState([]);
  const [rejectedColleges, setRejectedColleges] = useState([]);
  const [activeTab, setActiveTab] = useState("pending");
  const [selectedType, setSelectedType] = useState("company");
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [loadingApproveUserId, setLoadingApproveUserId] = useState(null);
  const [loadingRejectUserId, setLoadingRejectUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState("");

  const token = sessionStorage.getItem("accessToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const [
          pendingCompaniesResponse,
          pendingCollegesResponse,
          rejectedCompaniesResponse,
          rejectedCollegesResponse,
        ] = await Promise.all([
          axios.get(`${baseUrl}/api/pending-companies/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${baseUrl}/api/pending-colleges/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${baseUrl}/api/rejected-companies-list/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${baseUrl}/api/rejected-colleges-list/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setPendingCompanies(pendingCompaniesResponse.data);
        setRejectedCompanies(rejectedCompaniesResponse.data);
        setPendingColleges(pendingCollegesResponse.data);
        setRejectedColleges(rejectedCollegesResponse.data);
      } catch (error) {
        console.error("Error fetching requests:", error);
        toast.error("Failed to fetch requests.");
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [token, baseUrl]);

  const getName = (user) => {
    if (selectedType === "company") {
      return user.company_name || "Unknown Company";
    } else if (selectedType === "college") {
      return user.college_name || "Unknown College";
    }
    return "Unknown";
  };

  const handleApprove = async (id, type) => {
    setLoadingApproveUserId(id);
    try {
      const response = await axios.get(
        `${baseUrl}/api/approve/${type}/${id}/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        if (type === "company") {
          setPendingCompanies(
            pendingCompanies.filter((company) => company.id !== id)
          );
          setRejectedCompanies([
            ...rejectedCompanies,
            { id, name: `Company ${id}` },
          ]);
        } else {
          setPendingColleges(
            pendingColleges.filter((college) => college.id !== id)
          );
          setRejectedColleges([
            ...rejectedColleges,
            { id, name: `College ${id}` },
          ]);
        }
        toast.success(`Approved ${type} request.`);
      }
    } catch (error) {
      console.error("Error approving request:", error);
      toast.error("Failed to approve request.");
    } finally {
      setLoadingApproveUserId(null);
    }
  };

  const handleReject = async (id, type) => {
    setLoadingRejectUserId(id);
    try {
      const response = await axios.post(
        `${baseUrl}/api/reject-${type}/${id}/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        if (type === "company") {
          setPendingCompanies(
            pendingCompanies.filter((company) => company.id !== id)
          );
          setRejectedCompanies([
            ...rejectedCompanies,
            { id, name: `Company ${id}` },
          ]);
        } else {
          setPendingColleges(
            pendingColleges.filter((college) => college.id !== id)
          );
          setRejectedColleges([
            ...rejectedColleges,
            { id, name: `College ${id}` },
          ]);
        }
        toast.success(`Rejected ${type} request.`);
      }
    } catch (error) {
      console.error("Error rejecting request:", error);
      toast.error("Failed to reject request.");
    } finally {
      setLoadingRejectUserId(null);
    }
  };

  const handleViewDetails = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/details_${selectedType}/${id}/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDetails(response.data);
      setShowModal(true);

      console.log(response);
    } catch (error) {
      console.error("Error fetching details:", error);
      setError("Failed to fetch details.");
      toast.error("Failed to fetch details.");
    }
  };

  // console.log(response);

  const getRequests = () => {
    if (selectedType === "company") {
      return {
        pendingRequests: pendingCompanies,
        rejectedRequests: rejectedCompanies,
      };
    } else {
      return {
        pendingRequests: pendingColleges,
        rejectedRequests: rejectedColleges,
      };
    }
  };

  const { pendingRequests, rejectedRequests } = getRequests();
  console.log(pendingCompanies);
  console.log(rejectedCompanies);
  console.log();
  const filteredRequests = (requests) => {
    return requests.filter((user) =>
      getName(user).toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const renderNoRequestsMessage = () => (
    <div className="no-requests-message">No requests found.</div>
  );

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="campuse_student_request_container">
            <div className="campuse_student_request_header">
              <div className="campuse_student_request_dropdown">
                <img src={filterIcon} alt="Filter Icon" />
                <select
                  className="campuse_student_request_dropdown_section"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="company">Company ▼</option>
                  <option value="college">College ▼</option>
                </select>
              </div>
              <div className="d-flex">
                <div className="campuse_student_request_search-bar">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="campuse_student_request_dropdown">
                  <img src={filterIcon} alt="Filter Icon" />
                  <select
                    className="campuse_student_request_dropdown_section"
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    <option value="pending">Pending ▼</option>
                    <option value="rejected">Rejected ▼</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="campuse_student_request_body">
              <div className="campuse_student_request_sub_container">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="campuse_student_request_list">
                    {activeTab === "pending" &&
                      (filteredRequests(pendingRequests).length > 0
                        ? filteredRequests(pendingRequests).map((user) => (
                            <div
                              key={user.id}
                              className="campuse_student_request_card"
                            >
                              <p>{getName(user)}</p>
                              <div className="campuse_student_request_actions">
                                <div>
                                  <button
                                    className="campuse_student_request_approve_button"
                                    onClick={() =>
                                      handleApprove(user.id, selectedType)
                                    }
                                    disabled={loadingApproveUserId === user.id}
                                  >
                                    {loadingApproveUserId === user.id ? (
                                      <span className="loader"></span>
                                    ) : (
                                      "Approve"
                                    )}
                                  </button>
                                  <button
                                    className="campuse_student_request_reject-button"
                                    onClick={() =>
                                      handleReject(user.id, selectedType)
                                    }
                                    disabled={loadingRejectUserId === user.id}
                                  >
                                    {loadingRejectUserId === user.id ? (
                                      <span className="loader"></span>
                                    ) : (
                                      "Reject"
                                    )}
                                  </button>
                                </div>
                                <Link
                                  onClick={() => handleViewDetails(user.id)}
                                  className="details-link"
                                >
                                  View Details &rarr;
                                </Link>
                              </div>
                            </div>
                          ))
                        : renderNoRequestsMessage())}

                    {activeTab === "rejected" &&
                      (filteredRequests(rejectedRequests).length > 0
                        ? filteredRequests(rejectedRequests).map((user) => (
                            <div
                              key={user.id}
                              className="campuse_student_request_card"
                            >
                              <p>{getName(user)}</p>
                              <div className="campuse_student_request_actions">
                                <div>
                                  {/* <button
                                  className="campuse_student_request_approve_button"
                                  onClick={() =>
                                    handleApprove(user.id, selectedType)
                                  }
                                  disabled={loadingApproveUserId === user.id}
                                >
                                  {loadingApproveUserId === user.id ? (
                                    <span className="loader"></span>
                                  ) : (
                                    "Approve"
                                  )}
                                </button> */}
                                  <button
                                    className="campuse_student_request_rejected_button"
                                    disabled
                                  >
                                    Rejected
                                  </button>
                                </div>
                                <Link
                                  onClick={() => handleViewDetails(user.id)}
                                  className="details-link"
                                >
                                  View Details &rarr;
                                </Link>
                              </div>
                            </div>
                          ))
                        : renderNoRequestsMessage())}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          {selectedType === "company" ? (
            details ? (
              <>
                <div
                  className="text-center"
                  style={{ padding: "1rem", fontSize: "2rem" }}
                >
                  Company Details
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicCompanyName">
                    <Form.Label className="ml-1">Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={details.company_name}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="ml-1">HR Name</Form.Label>
                    <Form.Control type="text" value={details.email} readOnly />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCompanyCity">
                    <Form.Label className="ml-1">Company City</Form.Label>
                    <Form.Control
                      type="text"
                      value={details.company_city}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicMobile">
                    <Form.Label className="ml-1">Contact</Form.Label>
                    <Form.Control
                      type="text"
                      value={details.all_mobno}
                      readOnly
                    />
                  </Form.Group>
                </Form>
              </>
            ) : (
              <p>Loading...</p>
            )
          ) : details ? (
            <>
              {" "}
              <div
                className="text-center"
                style={{ padding: "1rem", fontSize: "2rem" }}
              >
                College Details
              </div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicCollegeName">
                  <Form.Label className="ml-1">College Manager Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={`${details.name} ${details.college_lastname}`}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="ml-1">College Email</Form.Label>
                  <Form.Control type="text" value={details.email} readOnly />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCollegeName">
                  <Form.Label className="ml-1">College Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={details.collegename}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicMobile">
                  <Form.Label className="ml-1">Contact</Form.Label>
                  <Form.Control
                    type="text"
                    value={details.all_mobno}
                    readOnly
                  />
                </Form.Group>
              </Form>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <ToastContainer /> */}
    </>
  );
}
