import React, { useState } from "react";
import "../../../styles/dashboards/clgdashboard/campusmanagerprofile.css";
import Instgramicon from "../../../utils/landingpage/images/blackinstgram.svg";
import twitericon from "../../../utils/landingpage/images/blacktwiter.svg";
import linkedinicon from "../../../utils/landingpage/images/blacklinkedin.svg";
import socialediticon from "../../../utils/landingpage/images/socialediticon.svg";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

function CampusManagerProfile() {
  // State to manage which form is active
  const [activeForm, setActiveForm] = useState("campusManager");
  const [profileImage, setProfileImage] = useState(
    "https://via.placeholder.com/100"
  ); // Default image URL

  // Function to switch between forms
  const handleFormSwitch = (formName) => {
    setActiveForm(formName);
  };

  // Validation Schema for Campus Manager Form
  const campusManagerValidationSchema = Yup.object().shape({
    collegeName: Yup.string().required("College Name is required"),
    establishedYear: Yup.number()
      .required("Established Year is required")
      .min(1800, "Year must be after 1800")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
    Type: Yup.string().required("Type is required"),
    affiliatedUniversity: Yup.string().required(
      "Affiliated University is required"
    ),
    location: Yup.string().required("location is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("state is required"),
    country: Yup.string().required("Country is required"),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]+$/, "Contact Number must be digits")
      .min(10, "Contact Number must be at least 10 digits"),
    emailAddress: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address"),
    websiteUrl: Yup.string().url("Invalid URL"),
  });

  // Validation Schema for Administrative Details Form
  const administrativeDetailsValidationSchema = Yup.object().shape({
    principalDeanName: Yup.string().required("Principal/Dean Name is required"),
    designation: Yup.string().required("Designation is required"),
    adminContactName: Yup.string().required(
      "Administrative Contact Person is required"
    ),
    admincontactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]+$/, "Contact Number must be digits")
      .min(10, "Contact Number must be at least 10 digits"),
    adminEmail: Yup.string()
      .required("Email is required")
      .email("Invalid Email Address"),
  });

  // Validation Schema for Placement Cell Details Form
  const placementCellUpValidationSchema = Yup.object().shape({
    placementOfficerName: Yup.string().required(
      "Placement Officer Name is required"
    ),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]+$/, "Contact Number must be digits only")
      .min(10, "Contact Number must be at least 10 digits")
      .max(10, "Contact Number cannot be more than 10 digits"),
    emailAddress: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address"),
  });

  const placementCellDownValidationSchema = Yup.object({
    numberofStudent: Yup.string().required("Number of students is required"),
    genderRatio: Yup.string().required("Gender ratio is required"),
    placementRecord: Yup.string().required("Placement record is required"),
    internshipRecord: Yup.string().required("Internship record is required"),
  });

  // Formik for Campus Manager Form
  const campusManagerFormik = useFormik({
    initialValues: {
      collegeName: "",
      establishedYear: "",
      Type: "",
      affiliatedUniversity: "",
      location: "",
      city: "",
      state: "",
      country: "",
      contactNumber: "",
      emailAddress: "",
      websiteUrl: "",
    },
    validationSchema: campusManagerValidationSchema,
    onSubmit: (values) => {
      alert("Successful");
      console.log("College Profile Creation", values);
    },
  });

  // Administrative Details Formik
  const administrativeDetailsFormik = useFormik({
    initialValues: {
      principalDeanName: "",
      designation: "",
      adminContactName: "",
      admincontactNumber: "",
      adminEmail: "",
    },
    validationSchema: administrativeDetailsValidationSchema,
    onSubmit: (values) => {
      console.log("Administrative Details form values", values);
    },
  });

  // Placement Cell Details Formik
  const placementCellUpFormik = useFormik({
    initialValues: {
      placementOfficerName: "",
    },
    validationSchema: placementCellUpValidationSchema,
    onSubmit: (values) => {
      console.log("Placement Cell Details form values", values);
    },
  });

  const placementCellDownFormik = useFormik({
    initialValues: {
      numberofStudent: "",
      genderRatio: "",
      placementRecord: "",
      internshipRecord: "",
    },
    validationSchema: placementCellDownValidationSchema,
    onSubmit: (values) => {
      console.log("Placement Cell Details form student values", values);
    },
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Campus Manager Form
  const renderCampusManagerForm = () => (
    <div className="clg-profile-creation-left-section">
      <form onSubmit={campusManagerFormik.handleSubmit}>
        <div className="clg-profile-creation-profile-section">
          <img
            src={profileImage}
            alt="Profile"
            className="clg-profile-creation-profile-picture"
          />
          <div className="profile-section-sometext">
            <h6>Upload Your College Logo</h6>
            <p>Your photo should be in PNG or JPG format</p>
            <input
              type="file"
              id="fileInput"
              className="clg-profile-creation-edit-profile-button"
              accept="image/png, image/jpeg"
              onChange={handleImageUpload}
            />
            <label htmlFor="fileInput" className="clg-custom-file-upload">
              Choose Image
            </label>
          </div>
        </div>

        {/* Input Fields */}
        <div className="clg-profile-creation-inputs">
          <div className="clg-profile-creation-left-inputs">
            <input
              type="text"
              name="collegeName"
              placeholder="College Name"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.collegeName}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.collegeName &&
            campusManagerFormik.errors.collegeName ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.collegeName}{" "}
              </div>
            ) : null}
            <input
              type="text"
              name="Type"
              placeholder="Type(Public/Private)"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.Type}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.Type &&
            campusManagerFormik.errors.Type ? (
              <div  className="errorprofile">{campusManagerFormik.errors.Type} </div>
            ) : null}
            <input
              type="text"
              name="location"
              placeholder="location"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.location}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.location &&
            campusManagerFormik.errors.location ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.location}{" "}
              </div>
            ) : null}
            <input
              type="text"
              name="state"
              placeholder="State"
              className="clg-profile-input"
              value={campusManagerFormik.values.state}
              onBlur={campusManagerFormik.handleBlur}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.state &&
            campusManagerFormik.errors.state ? (
              <div className="errorprofile">{campusManagerFormik.errors.state} </div>
            ) : null}
            <input
              type="text"
              name="contactNumber"
              placeholder="Contact Number"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.contactNumber}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.contactNumber &&
            campusManagerFormik.errors.contactNumber ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.contactNumber}{" "}
              </div>
            ) : null}
          </div>
          <div className="clg-profile-creation-right-inputs">
            <input
              type="text"
              name="establishedYear"
              placeholder="Established Year"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.establishedYear}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.establishedYear &&
            campusManagerFormik.errors.establishedYear ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.establishedYear}{" "}
              </div>
            ) : null}
            <input
              type="text"
              name="affiliatedUniversity"
              placeholder="Affiliated University"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.affiliatedUniversity}
              onChange={campusManagerFormik.handleChange}
            />
            {campusManagerFormik.touched.affiliatedUniversity &&
            campusManagerFormik.errors.affiliatedUniversity ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.affiliatedUniversity}
              </div>
            ) : null}
            <input
              type="text"
              name="city"
              placeholder="City"
              className="clg-profile-input"
              value={campusManagerFormik.values.city}
              onBlur={campusManagerFormik.handleBlur}
              onChange={campusManagerFormik.handleChange}
            />{" "}
            {campusManagerFormik.touched.city &&
            campusManagerFormik.errors.city ? (
              <div className="errorprofile">{campusManagerFormik.errors.city} </div>
            ) : null}
            <input
              type="text"
              name="country"
              placeholder="Country"
              className="clg-profile-input"
              value={campusManagerFormik.values.country}
              onChange={campusManagerFormik.handleChange}
              onBlur={campusManagerFormik.handleBlur}
            />{" "}
            {campusManagerFormik.touched.country &&
            campusManagerFormik.errors.country ? (
              <div className="errorprofile">{campusManagerFormik.errors.country} </div>
            ) : null}
            <input
              type="email"
              name="emailAddress"
              placeholder="Email Address"
              className="clg-profile-input"
              value={campusManagerFormik.values.emailAddress}
              onChange={campusManagerFormik.handleChange}
              onBlur={campusManagerFormik.handleBlur}
            />{" "}
            {campusManagerFormik.touched.emailAddress &&
            campusManagerFormik.errors.emailAddress ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.emailAddress}{" "}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <input
            type="text"
            name="websiteUrl"
            placeholder="Website Url"
            className="clg-profile-input"
            onBlur={campusManagerFormik.handleBlur}
            value={campusManagerFormik.values.websiteUrl}
            onChange={campusManagerFormik.handleChange}
          />{" "}
          {campusManagerFormik.touched.websiteUrl &&
          campusManagerFormik.errors.websiteUrl ? (
            <div className="errorprofile">
              {campusManagerFormik.errors.websiteUrl}{" "}
            </div>
          ) : null}
        </div>
        <button
          type="submit"
          className="profile-creation-save-continue-button"
          onClick={() => Formik.handleSubmit}
        >
          Save and Continue
        </button>
      </form>
    </div>
  );

  // Administrative Details Form
  const renderAdministrativeDetailsForm = () => (
    <div className="clg-profile-creation-left-section">
      <form onSubmit={administrativeDetailsFormik.handleSubmit}>
        <div className="clg-profile-creation-inputs">
          <div className="clg-profile-creation-left-inputs">
            <input
              type="text"
              name="principalDeanName"
              placeholder="Principal/Dean Name"
              className="clg-profile-input"
              onBlur={administrativeDetailsFormik.handleBlur}
              value={administrativeDetailsFormik.values.principalDeanName}
              onChange={administrativeDetailsFormik.handleChange}
            />
            {administrativeDetailsFormik.touched.principalDeanName &&
            administrativeDetailsFormik.errors.principalDeanName ? (
              <div className="errorprofile">
                {administrativeDetailsFormik.errors.principalDeanName}{" "}
              </div>
            ) : null}
            <input
              type="text"
              name="designation"
              placeholder="Designation"
              className="clg-profile-input"
              onBlur={administrativeDetailsFormik.handleBlur}
              value={administrativeDetailsFormik.values.designation}
              onChange={administrativeDetailsFormik.handleChange}
            />{" "}
            {administrativeDetailsFormik.touched.designation &&
            administrativeDetailsFormik.errors.designation ? (
              <div className="errorprofile">
                {administrativeDetailsFormik.errors.designation}{" "}
              </div>
            ) : null}
            <input
              type="email"
              name="adminEmail"
              placeholder="Email Address"
              className="clg-profile-input"
              onBlur={administrativeDetailsFormik.handleBlur}
              value={administrativeDetailsFormik.values.adminEmail}
              onChange={administrativeDetailsFormik.handleChange}
            />{" "}
            {administrativeDetailsFormik.touched.adminEmail &&
            administrativeDetailsFormik.errors.adminEmail ? (
              <div className="errorprofile">
                {administrativeDetailsFormik.errors.adminEmail}{" "}
              </div>
            ) : null}
          </div>
          <div className="clg-profile-creation-right-inputs">
            <input
              type="text"
              name="adminContactName"
              placeholder="Administrative Contact Person"
              className="clg-profile-input"
              onBlur={administrativeDetailsFormik.handleBlur}
              value={administrativeDetailsFormik.values.adminContactName}
              onChange={administrativeDetailsFormik.handleChange}
            />{" "}
            {administrativeDetailsFormik.touched.adminContactName &&
            administrativeDetailsFormik.errors.adminContactName ? (
              <div className="errorprofile">
                {administrativeDetailsFormik.errors.adminContactName}{" "}
              </div>
            ) : null}
            <input
              type="text"
              name="admincontactNumber"
              placeholder="Contact Number"
              className="clg-profile-input"
              value={administrativeDetailsFormik.values.admincontactNumber}
              onBlur={administrativeDetailsFormik.handleBlur}
              onChange={administrativeDetailsFormik.handleChange}
            />
            {administrativeDetailsFormik.touched.admincontactNumber &&
            administrativeDetailsFormik.errors.adminContactName ? (
              <div className="errorprofile">
                {administrativeDetailsFormik.errors.admincontactNumber}{" "}
              </div>
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          className="profile-creation-save-continue-button"
          onClick={() => Formik.handleSubmit}
        >
          Save and Continue
        </button>
      </form>
    </div>
  );

  // Placement Cell Details Form
  const renderPlacementCellDetailsForm = () => (
    <div>
      {/* First Form section */}
      <div className="placement-cell-student-information-section">
        <h5> Placement Cell Detail</h5>
        <form onSubmit={placementCellUpFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs " id="">
            <div className="placement-cell-inputs">
              <input
                type="text"
                name="placementOfficerName"
                placeholder="Placement Officer Name"
                className="clg-profile-input"
                value={placementCellUpFormik.values.placementOfficerName}
                onChange={placementCellUpFormik.handleChange}
                onBlur={placementCellUpFormik.handleBlur}
              />{" "}
              {placementCellUpFormik.touched.placementOfficerName &&
              placementCellUpFormik.errors.placementOfficerName ? (
                <span className="errorprofile">
                  {placementCellUpFormik.errors.placementOfficerName}
                </span>
              ) : null}
              <input
                type="text"
                name="contactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                value={placementCellUpFormik.values.contactNumber}
                onChange={placementCellUpFormik.handleChange}
                onBlur={placementCellUpFormik.handleBlur}
              />{" "}
              {placementCellUpFormik.touched.contactNumber &&
              placementCellUpFormik.errors.contactNumber ? (
                <span className="errorprofile">
                  {placementCellUpFormik.errors.contactNumber}
                </span>
              ) : null}
              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="clg-profile-input"
                value={placementCellUpFormik.values.emailAddress}
                onChange={placementCellUpFormik.handleChange}
                onBlur={placementCellUpFormik.handleBlur}
              />
              {placementCellUpFormik.touched.emailAddress &&
              placementCellUpFormik.errors.emailAddress ? (
                <span className="errorprofile">
                  {placementCellUpFormik.errors.emailAddress}
                </span>
              ) : null}
            </div>
          </div>
          <button
            type="submit"
            className="placement-cell-profile-creation-save-button"
            onClick={Formik.handleSubmit}
          >
            Save
          </button>
        </form>
      </div>

      {/* Second Form section */}
      <div className="placement-cell-student-information-section">
        <h5>Student Information</h5>
        <form onSubmit={placementCellDownFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">
              <input
                type="text"
                name="numberofStudent"
                placeholder="Total Number of Students"
                className="clg-profile-input"
                value={placementCellDownFormik.values.numberofStudent}
                onChange={placementCellDownFormik.handleChange}
                onBlur={placementCellDownFormik.handleBlur}
              />
              {placementCellDownFormik.touched.numberofStudent &&
              placementCellDownFormik.errors.numberofStudent ? (
                <div className="errorprofile">
                  {placementCellDownFormik.errors.numberofStudent}
                </div>
              ) : null}
              <input
                type="text"
                name="genderRatio"
                placeholder="Gender Ratio"
                className="clg-profile-input"
                value={placementCellDownFormik.values.genderRatio}
                onChange={placementCellDownFormik.handleChange}
              />
              {placementCellDownFormik.touched.genderRatio &&
              placementCellDownFormik.errors.genderRatio ? (
                <div className="errorprofile">
                  {placementCellDownFormik.errors.genderRatio}
                </div>
              ) : null}
              <input
                type="text"
                name="placementRecord"
                placeholder="Placement Record (Past year data)"
                className="clg-profile-input"
                value={placementCellDownFormik.values.placementRecord}
                onChange={placementCellDownFormik.handleChange}
              />
              {placementCellDownFormik.touched.genderRatio &&
              placementCellDownFormik.errors.genderRatio ? (
                <div className="errorprofile">
                  {placementCellDownFormik.errors.genderRatio}
                </div>
              ) : null}
              <input
                type="text"
                name="internshipRecord"
                placeholder="Internship Record"
                className="clg-profile-input"
                value={placementCellDownFormik.values.internshipRecord}
                onChange={placementCellDownFormik.handleChange}
              />
              {placementCellDownFormik.touched.internshipRecord &&
              placementCellDownFormik.errors.internshipRecord ? (
                <div className="errorprofile">
                  {placementCellDownFormik.errors.internshipRecord}
                </div>
              ) : null}
            </div>
          </div>
          <button
            type="submit"
            className="placement-cell-profile-creation-save-button"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );

  // Shared right section in both pages
  const renderRightSection = () => (
    <div className="clg-profile-creation-right-section">
      <div className="clg-profile-creation-top-right">
        Complete Your Profile
      </div>
      <div className="clg-profile-creation-bottom-right">
        <div className="cpcbr-header">
          Social Media Links <img src={socialediticon} alt="Edit" />
        </div>
        <div className="cpcbr-body">
          <div className="black-instagram-icon">
            <img
              src={Instgramicon}
              alt="Instagram Icon"
              className="social-icon"
            />
            <p>Instagram</p>
            <a
              href="https://instagram.com/Amitsharma"
              target="_blank"
              rel="noopener noreferrer"
            >
              instagram.com/Amitsharma
            </a>
          </div>

          <div className="black-twiter-icon">
            <img src={twitericon} alt="Twitter Icon" className="social-icon" />
            <p>Twitter</p>
            <a
              href="https://twitter.com/Amitsharma"
              target="_blank"
              rel="noopener noreferrer"
            >
              twitter.com/Amitsharma
            </a>
          </div>

          <div className="black-linkedin-icon">
            <img
              src={linkedinicon}
              alt="LinkedIn Icon"
              className="social-icon"
            />
            <p>LinkedIn</p>
            <a
              href="https://linkedin.com/in/Amitsharma"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin.com/in/Amitsharma
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="clg-profile-navigation">
          <div className="d-flex clg-profile-new-navigate">
            <button
              className={
                activeForm === "campusManager" ? "clg_active_button" : ""
              }
              onClick={() => handleFormSwitch("campusManager")}
            >
              <h5>Profile Creation</h5>
            </button>
            <button
              className={
                activeForm === "administrativeDetails"
                  ? "clg_active_button"
                  : ""
              }
              onClick={() => handleFormSwitch("administrativeDetails")}
            >
              <h5>Administrative Details</h5>
            </button>
            <button
              className={
                activeForm === "placementCell" ? "clg_active_button" : ""
              }
              onClick={() => handleFormSwitch("placementCell")}
            >
              <h5>Placement Cell Details</h5>
            </button>
          </div>
        </div>

        {/* Conditionally render left form based on activeForm state */}
        <div className="clg-profile-creation">
          {activeForm === "campusManager" && renderCampusManagerForm()}
          {activeForm === "administrativeDetails" &&
            renderAdministrativeDetailsForm()}
          {activeForm === "placementCell" && renderPlacementCellDetailsForm()}

          {/* Right section remains the same */}
          {renderRightSection()}
        </div>
      </div>
    </div>
  );
}

export default CampusManagerProfile;
