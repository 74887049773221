import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";

const jobs = [
  {
    logo: "https://i.postimg.cc/FKK599RW/pngimg-com-microsoft-PNG13-1.png",
    company: "Microsoft Pvt. Ltd.",
    profile: "Frontend Developer",
    studentsEnrolled: 40,
  },
];

export default function CampusManagerJobs() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const jobsPerPage = 6;

  const navigate = useNavigate();
  const token = sessionStorage.getItem("accessToken");
  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/companies_for_college/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setJobs(response.data);
        console.log('---response data--', response.data)
      } catch (error) {
        console.error(error);
        toast.error("error failed");
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  // Filter jobs based on search input
  const filteredJobs = jobs?.filter(
    (job) =>
      (job.jobs[0]?.company &&
        job.jobs[0]?.company.toLowerCase().includes(search.toLowerCase())) ||
      (job.jobs[0]?.title && job.jobs[0]?.title.toLowerCase().includes(search.toLowerCase()))
  );

  // const filteredJobs = [...jobs]
  

  // Calculate indexes for pagination
  const indexOfLastJob = page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs?.slice(indexOfFirstJob, indexOfLastJob);

  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1); // Reset to first page on search
  };

  // Handle page changes
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle job selection
  const handleJobClick = () => {
    // setSelectedJob(job);
    navigate("/dashboard/sidebar-page/:roleName/job-details");
  };

  // Handle data download
  const handleDownload = () => {
    console.log("Download Data");
    toast.info("Data download initiated.");
  };

  useEffect(() => {
    console.log('===job list new===', filteredJobs)
  }, [filteredJobs])

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {
          <>
            {/* Job Listing */}
            <div className="all-job-header">
              <h5>All Jobs</h5>
              <div className="all-job-header-right">
                <div className="all-jobs-search-input-div">
                  <img src={searchIconDash} alt="Search Icon" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearchChange}
                    className="all-job-search-input"
                  />
                </div>
                <button onClick={handleDownload} className="download-button">
                  Download Data
                </button>
              </div>
            </div>
            <div className="job-list">
              {currentJobs?.length === 0 ? (
                <h3 className="nojobsfound"> No Jobs Found </h3>
              ) : (
                currentJobs?.map((job, index) => (
                  <div key={index} className="job-item">
                    <div
                      onClick={() => handleJobClick(job)}
                      className="job-left"
                    >
                      <img
                        src={job.jobs[0]?.company_profile?.company_logo_url || dummy_logo}
                        alt={'logo'}
                        className="company-logo"
                      />
                      <div className="job-info">
                        <h6>{job.jobs[0]?.title || "N/A"}</h6>
                        <p>{job.jobs[0]?.company || "N/A"}</p>
                      </div>
                    </div>
                    <div className="job-right">
                      <span>{job.jobs[0]?.total_applicants} Enrolled Students</span>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className=" pagination-container pagination_custom">
              <Pagination
                count={Math.ceil(filteredJobs?.length / jobsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                className="mt-3"
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: () => <img src={leftArrowIcon} alt="prev" />,
                      next: () => <img src={rightIconArrow} alt="next" />,
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          </>
        }
      </div>
    </div>
  );
}
