import React from "react";

function Collages() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">Collages</div>
    </div>
  );
}

export default Collages;
