import React, { useState, useEffect } from 'react';

const DonutChart = () => {
  const [chartData, setChartData] = useState([
      { value: 24, color: '#FF652D' },
      { value: 14, color: '#00854A' },
      { value: 62, color: '#FBBD31' },
  ]);

  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    processData();
  }, [chartData]);

  const processData = () => {
    let currentAngle = 0;

    const processed = chartData.map((item) => {
      const baseThickness = 40;
      const thicknessScale = 40;
      const thickness = baseThickness + (thicknessScale * (item.value / 100));

      const segment = {
        value: item.value,
        color: item.color,
        startAngle: currentAngle,
        thickness: thickness
      };

      currentAngle += (segment.value / 100) * 360;
      return segment;
    });

    setProcessedData(processed);
  };

  const size = 370;
  const centerX = size / 2;
  const centerY = size / 2;
  const baseRadius = size / 2;
  const innerRadius = baseRadius - 120;

  const createArcPath = (startAngle, endAngle, thickness) => {
    const startRad = (startAngle - 90) * (Math.PI / 180);
    const endRad = (endAngle - 90) * (Math.PI / 180);
    const outerRadius = innerRadius + thickness;

    const x1 = centerX + outerRadius * Math.cos(startRad);
    const y1 = centerY + outerRadius * Math.sin(startRad);
    const x2 = centerX + outerRadius * Math.cos(endRad);
    const y2 = centerY + outerRadius * Math.sin(endRad);

    const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

    const outerArc = `M ${x1} ${y1} A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${x2} ${y2}`;

    const x3 = centerX + innerRadius * Math.cos(endRad);
    const y3 = centerY + innerRadius * Math.sin(endRad);
    const x4 = centerX + innerRadius * Math.cos(startRad);
    const y4 = centerY + innerRadius * Math.sin(startRad);

    const innerArc = `L ${x3} ${y3} A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${x4} ${y4} Z`;

    return outerArc + innerArc;
  };

  return (
    <div className="flex items-center justify-center w-full h-full">
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {processedData.map((segment, index) => {
          const endAngle = segment.startAngle + (segment.value / 100) * 360;
          const segmentCenterAngle = segment.startAngle + (segment.value / 2 / 100) * 360;
          const labelRadius = innerRadius + (segment.thickness / 2);
          
          return (
            <g key={index}>
              <path
                d={createArcPath(segment.startAngle, endAngle, segment.thickness)}
                fill={segment.color}
                className="transition-all duration-300 hover:opacity-90"
              />
              <text
                x={centerX + labelRadius * Math.cos((segmentCenterAngle - 90) * Math.PI/180)}
                y={centerY + labelRadius * Math.sin((segmentCenterAngle - 90) * Math.PI/180)}
                fill="white"
                textAnchor="middle"
                dominantBaseline="middle"
                className="text-lg font-bold"
              >
                {`${segment.value}%`}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
};

export default DonutChart;