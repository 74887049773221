import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../../../styles/dashboards/hrdashboard/viewcandidateapplication.css";
import copyIcon from "../../../utils/auth/images/copyicon.svg";
import axios from "axios";
import { Button, Drawer } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function ViewLateralCandidateApplication() {
  const { id, application_id } = useParams();
  const [interviewScheduleData, setInterviewScheduleData] = useState(null);
  const token = sessionStorage.getItem("accessToken");
  const [candidateData, setCandidateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("ApplicationProfile");
  const navigate = useNavigate();

  const getInterviewDetails = async () => {
    const payload = { application_id: application_id };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/schedule_interview/retrieve_interview_details/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInterviewScheduleData(response.data.interview_details);
      console.log(response.data.interview_details);
    } catch (error) {
      console.error("Error fetching interview details:", error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "ApplicationProfile":
        return (
          <ApplicationProfile
            candidateData={candidateData}
            applicationId={application_id}
          />
        );
      case "Resume":
        return (
          <Resume
            candidateData={candidateData}
            applicationId={application_id}
          />
        );
      case "HiringProcess":
        return (
          <HiringProcess
            candidateData={candidateData}
            applicationId={application_id}
            interviewScheduleData={interviewScheduleData}
            getInterviewDetails={getInterviewDetails}
          />
        );
      case "InterviewSchedule":
        return interviewScheduleData ? (
          <InterviewSchedule
            candidateData={candidateData}
            applicationId={application_id}
            interviewScheduleData={interviewScheduleData}
          />
        ) : (
          <p className="text-center">No interview scheduled</p>
        );

      default:
        return (
          <ApplicationProfile
            candidateData={candidateData}
            applicationId={application_id}
          />
        );
    }
  };

  async function getCandidateDetails() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${id}/`
      );
      setCandidateData(response.data);
    } catch (error) {
      console.error("Error fetching candidate details:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCandidateDetails();
    getInterviewDetails();
  }, [id]);
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            <h6>
              {" "}
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              &nbsp; Applicant Details
            </h6>
            {/* main container start */}
            <div className="view_candidate_application_container">
              <div className="view_candidate_application_profile_sub_details">
                <div className="view_candidate_application_profile_first">
                  <img
                    height={80}
                    width={80}
                    style={{ borderRadius: "50%" }}
                    src={
                      candidateData?.student_photo_url ||
                      "https://via.placeholder.com/150"
                    }
                  />
                  <div className="name_and_designation">
                    <p>
                      {candidateData?.first_name} {candidateData?.last_name}
                    </p>
                    <p>
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
                <div className="view_candidate_application_profile_second">
                  <div className="job_type">
                    <p id="applied_jobs">Applied Jobs</p>
                    <p>{candidateData?.applied_date || "N/A"}</p>
                  </div>
                  <hr />
                  <div className="job_category_title">
                    <p className="category">
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "N/A"}
                    </p>
                    <p className="title">
                      {candidateData?.employments?.[0]?.employment_type ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
                <div className="view_candidate_application_profile_third">
                  <h6>Contact</h6>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="Email"
                    />
                    <div className="social-details-container">
                      <label>Email</label>
                      <br />
                      <a
                        href={`mailto:${candidateData?.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.email || "Not specified"}
                      </a>
                    </div>
                  </div>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="Phone"
                    />
                    <div className="social-details-container">
                      <label>Phone</label>
                      <br />
                      <a
                        href={`tel:${candidateData?.phone_no}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.phone_no || "Not specified"}
                      </a>
                    </div>
                  </div>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="Twitter"
                    />
                    <div className="social-details-container">
                      <label>Twitter</label>
                      <br />
                      <a
                        href={candidateData?.twitter || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.twitter || "Not specified"}
                      </a>
                    </div>
                  </div>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="LinkedIn"
                    />
                    <div className="social-details-container">
                      <label>LinkedIn</label>
                      <br />
                      <a
                        href={candidateData?.linkedin || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.linkedin || "Not specified"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* =============== */}

              <div className="view_candidate_application_process_container">
                <div className="application_tab">
                  <p onClick={() => setActiveTab("ApplicationProfile")}>
                    <span
                      className={
                        activeTab === "ApplicationProfile" ? "active" : ""
                      }
                    >
                      Applicant Profile
                    </span>
                  </p>
                  <p onClick={() => setActiveTab("Resume")}>
                    <span className={activeTab === "Resume" ? "active" : ""}>
                      Resume
                    </span>
                  </p>
                  <p onClick={() => setActiveTab("HiringProcess")}>
                    <span
                      className={activeTab === "HiringProcess" ? "active" : ""}
                    >
                      Hiring Process
                    </span>
                  </p>
                  <p onClick={() => setActiveTab("InterviewSchedule")}>
                    <span
                      className={
                        activeTab === "InterviewSchedule" ? "active" : ""
                      }
                    >
                      Interview Schedule
                    </span>
                  </p>
                </div>

                <hr />
                <div>{renderTabContent()}</div>
              </div>
            </div>
            {/* main container end */}
          </div>
        )}
      </div>
    </div>
  );
}

const ApplicationProfile = ({ candidateData }) => {
  return (
    <div className="candidate_application-profile">
      {/* Personal Info Section */}
      <div className="candidate_personal-info-section">
        <p className="personal_info">Personal Info</p>
        <div className="candidate_personal-info">
          <div className="detail-item">
            <p>Full Name</p>
            <p>
              {candidateData?.first_name} {candidateData?.last_name}
            </p>
          </div>
          <div className="detail-item">
            <p>Gender</p>
            <p>{candidateData?.gender || "Not specified"}</p>
          </div>
          <div className="detail-item">
            <p>Date of Birth</p>
            <p>
              {candidateData?.dob
                ? new Date(candidateData.dob).toLocaleDateString()
                : "Not specified"}
            </p>
          </div>
          <div className="detail-item">
            <p>Language</p>
            <p>{candidateData?.languages || "Not specified"}</p>
          </div>
          <div className="detail-item">
            <p>Address</p>
            <p>{candidateData?.current_location || "Not specified"}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="candidate_professional_info_section">
        <div className="candidate_about_me">
          <h6>About Me</h6>
          <p>{candidateData?.bio || "No information provided."}</p>
        </div>

        <div className="candidate_professional_details">
          <div className="detail-item">
            <p>Current Job</p>
            <p>
              {candidateData?.employments?.[0]?.current_job_title ||
                "Not specified"}
            </p>
          </div>
          <div className="detail-item">
            <p>Experience in years</p>
            <p>{candidateData?.experience || "Not specified"}</p>
          </div>
          <div className="detail-item">
            <p>Highest Qualification Held</p>
            <p>
              {candidateData?.qualifications?.[0]?.degree || "Not specified"}
            </p>
          </div>
          <div className="detail-item">
            <p>Skills Set</p>
            <p>
              {candidateData?.skill_name
                ?.map((skill) => skill.skill_name)
                .join(", ") || "No skills specified"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Resume = ({ candidateData }) => {
  return (
    <div className="hr_application_resume_body">
      <div className="hr_application_resume_container">
        {candidateData?.studentlateral_resume_url ? (
          <>
            <a
              href={candidateData.studentlateral_resume_url}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
              style={{
                padding: "10px 20px",
                color: "#fff",
                textDecoration: "none",
                borderRadius: "5px",
              }}
            >
              Download Resume
            </a>
          </>
        ) : (
          <p>No resume available for download.</p>
        )}
      </div>
    </div>
  );
};

const HiringProcess = ({
  candidateData,
  applicationId,
  interviewScheduleData,
  getInterviewDetails,
}) => {
  const [currentStatus, setCurrentStatus] = useState("in_review");
  const [isOpen, setIsOpen] = useState(false);
  const [statusLoading, setStatusLoading] = useState({
    in_review: false,
    shortlisted: false,
    interviewed: false,
    discarded: false,
    offered: false,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("accessToken");

  const getApplicationStatus = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/get_application_status/`,
        {
          application_id: applicationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCurrentStatus(response.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register: registerVirtual,
    handleSubmit: handleSubmitVirtual,
    formState: { errors: errorsVirtual },
    reset: resetVirtual,
  } = useForm();

  const {
    register: registerPhysical,
    handleSubmit: handleSubmitPhysical,
    formState: { errors: errorsPhysical },
    reset: resetPhysical,
  } = useForm();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    console.log(`Selected option: ${option}`);
    setIsOpen(false);
    setSelectedOption(option);
    setDrawerOpen(true);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const onSubmitVirtual = async (data) => {
    const formattedDateTime = formatDateTime(data.interview_date_time);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/student_lateral_schedule_interview/`,
        {
          application_ids: [applicationId],
          interview_date_time: formattedDateTime,
          interview_type: "virtual",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resetVirtual();
      getInterviewDetails();
      setDrawerOpen(false);
      toast.success("Virtual interview scheduled successfully!");
    } catch (error) {
      console.error("Error scheduling virtual interview:", error);
      toast.error("Failed to schedule virtual interview."); // Notify error
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust the delay as needed
    }
  };

  const onSubmitPhysical = async (data) => {
    setLoading(true);
    const formattedDateTime = formatDateTime(data.interview_date_time);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/student_lateral_schedule_interview/`,
        {
          application_ids: [applicationId],
          interview_date_time: formattedDateTime,
          interview_type: "physical",
          location: data.location,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Physical interview scheduled successfully:", response.data);

      resetPhysical();
      setDrawerOpen(false);
      getInterviewDetails();
      toast.success("Physical interview scheduled successfully!"); // Notify success
    } catch (error) {
      console.error("Error scheduling physical interview:", error);
      toast.error("Failed to schedule physical interview."); // Notify error
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust the delay as needed
    }
  };

  const changeStatus = async (newStatus) => {
    try {
      setStatusLoading((prevState) => ({
        ...prevState,
        [newStatus]: true,
      }));
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        {
          application_ids: [applicationId],
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Status Update");
      setCurrentStatus(newStatus);
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setStatusLoading((prevState) => ({
        ...prevState,
        [newStatus]: false, // Reset loading for the clicked status
      }));
    }
  };

  useEffect(() => {
    getApplicationStatus();
  }, []);

  return (
    <>
      <div className="hiring_process_container">
        <p className="hiring_process_heading">Current Stage</p>
        <div className="hiring_process_btn_status">
          <button
            className={currentStatus === "in_review" ? "active" : ""}
            onClick={() => changeStatus("in_review")}
            disabled={
              currentStatus === "shortlisted" ||
              currentStatus === "interviewed" ||
              currentStatus === "offered"
            }
          >
            {statusLoading["in_review"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "In-Review"
            )}
          </button>

          <button
            className={currentStatus === "shortlisted" ? "active" : ""}
            onClick={() => changeStatus("shortlisted")}
            disabled={
              currentStatus === "interviewed" || currentStatus === "offered"
            }
          >
            {statusLoading["shortlisted"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Shortlisted"
            )}
          </button>

          <button
            className={currentStatus === "interviewed" ? "active" : ""}
            onClick={() => changeStatus("interviewed")}
            disabled={currentStatus === "offered"}
          >
            {statusLoading["interviewed"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Interviewed"
            )}
          </button>

          {currentStatus === "interviewed" && (
            <div
              className="hiring_process_interview_dropdown"
              ref={dropdownRef}
            >
              <button
                className="hiring_process_interview"
                onClick={toggleDropdown}
              >
                {interviewScheduleData ? "Re-schedule" : "Schedule"} &nbsp;
                <svg
                  width="9"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.78531 5.84961L0.641382 0.825359L8.92924 0.825359L4.78531 5.84961Z"
                    fill="white"
                  />
                </svg>
              </button>

              {isOpen && (
                <div className="hiring_process_dropdown_menu">
                  <div
                    className="hiring_process_dropdown_option"
                    onClick={() => handleOptionClick("Virtual")}
                  >
                    Virtual
                  </div>
                  <div
                    className="hiring_process_dropdown_option"
                    onClick={() => handleOptionClick("Physical")}
                  >
                    Physical
                  </div>
                </div>
              )}
            </div>
          )}
          <button
            onClick={() => changeStatus("discarded")}
            className={currentStatus === "discarded" ? "active" : ""}
            disabled={currentStatus === "offered"}
          >
            {statusLoading["discarded"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Declined"
            )}{" "}
          </button>
          <button
            onClick={() => changeStatus("offered")}
            className={currentStatus === "offered" ? "active" : ""}
          >
            {" "}
            {statusLoading["offered"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Offered"
            )}{" "}
          </button>
        </div>
        {currentStatus === "offered" || interviewScheduleData == null ? (
          ""
        ) : (
          <>
            {!interviewScheduleData?.zoom_meeting_link ? (
              <div className="mt-3 p-4">
                <div className="interview_details">
                  <h6>Interview Schedule Details</h6>
                  <div>
                    <p>
                      Date: <span>{interviewScheduleData?.interview_date}</span>
                    </p>
                    <p>Location: {interviewScheduleData?.interview_location}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-3 p-4">
                <div className="interview_details">
                  <h6>Interview Schedule Details</h6>
                  <div>
                    <p>
                      Date: <span>{interviewScheduleData?.interview_date}</span>
                    </p>
                    <p>
                      Zoom Link:{" "}
                      <a
                        href={interviewScheduleData?.zoom_meeting_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Join Meeting
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
        PaperProps={{
          style: {
            width: "100%",
            margin: "0 auto",
            backgroundColor: "#f4f6fc",
          },
        }}
      >
        <div className="interview_schedule_form">
          {selectedOption === "Virtual" && (
            <form
              className="hr_schedule_interview_form"
              onSubmit={handleSubmitVirtual(onSubmitVirtual)}
            >
              <h2>Virtual Interview</h2>
              <div className="hr_schedule_interview_form_data">
                <label htmlFor="interview_date_time">
                  Interview Date and Time
                </label>
                <input
                  type="datetime-local"
                  id="interview_date_time"
                  {...registerVirtual("interview_date_time", {
                    required: "Interview date and time are required",
                  })}
                />

                {errorsVirtual.interview_date_time && <span>*</span>}
              </div>

              <button
                className="interview_schedule_btn"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <span>
                    <span className="loader"></span>
                    Scheduling...
                  </span>
                ) : (
                  "Schedule Interview"
                )}
              </button>

              <button className="interview_schedule_btn" onClick={closeDrawer}>
                Close
              </button>
            </form>
          )}

          {selectedOption === "Physical" && (
            <form
              className="hr_schedule_interview_form"
              onSubmit={handleSubmitPhysical(onSubmitPhysical)}
            >
              <h2>Physical Interview</h2>
              <div className="hr_schedule_interview_form_data">
                <label htmlFor="interview_date_time">
                  Interview Date and Time
                </label>
                <input
                  type="datetime-local"
                  id="interview_date_time"
                  {...registerPhysical("interview_date_time", {
                    required: "Interview date and time are required",
                  })}
                />
                {errorsPhysical.interview_date_time && <span>*</span>}
              </div>
              <div className="hr_schedule_interview_form_data">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  id="location"
                  {...registerPhysical("location", {
                    required: "Location is required",
                  })}
                />
                {errorsPhysical.location && <span>*</span>}
              </div>

              <button
                className="interview_schedule_btn"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <span>
                    <span className="loader"></span>
                    Scheduling...
                  </span>
                ) : (
                  "Schedule Interview"
                )}
              </button>
              <button className="interview_schedule_btn" onClick={closeDrawer}>
                Close
              </button>
            </form>
          )}
        </div>
      </Drawer>
    </>
  );
};

const InterviewSchedule = ({ interviewScheduleData, candidateData }) => {
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(interviewScheduleData?.zoom_meeting_link)
      .then(() => {
        setCopied(true);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };
  return (
    <>
      {" "}
      {!interviewScheduleData?.zoom_meeting_link ? (
        <div className="mt-3 p-4">
          <div className="interview_details">
            <h6>Interview</h6>
            <div className="interview_schedule_details_lateral_container">
              <div className="interview_schedule_details_lateral">
                <div className="interview_schedule_details_lateral_candidate_details">
                  <img
                    height={60}
                    width={60}
                    style={{ borderRadius: "50%" }}
                    src={
                      candidateData?.student_photo_url ||
                      "https://via.placeholder.com/150"
                    }
                  />
                  <div className="name_and_designation">
                    <p>
                      {candidateData?.first_name} {candidateData?.last_name}
                    </p>
                    <p>
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="interview_schedule_lateral_date">
                <p>Date & Time</p>
                <p>{interviewScheduleData?.interview_date}</p>
              </div>
              <div className="interview_schedule_lateral_link">
                <p>Location</p>
                <p className="interview_schedule_lateral_link_copy">
                  {interviewScheduleData?.interview_location}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 p-4">
          <div className="interview_details">
            <h6>Interview</h6>
            <div className="interview_schedule_details_lateral_container">
              <div className="interview_schedule_details_lateral">
                <div className="interview_schedule_details_lateral_candidate_details">
                  <img
                    height={60}
                    width={60}
                    style={{ borderRadius: "50%" }}
                    src={
                      candidateData?.student_photo_url ||
                      "https://via.placeholder.com/150"
                    }
                  />
                  <div className="name_and_designation">
                    <p>
                      {candidateData?.first_name} {candidateData?.last_name}
                    </p>
                    <p>
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="interview_schedule_lateral_date">
                <p>Date & Time</p>
                <p>{interviewScheduleData?.interview_date}</p>
              </div>
              <div className="interview_schedule_lateral_link">
                <p> Zoom Link </p>
                <p className="interview_schedule_lateral_link_copy">
                  <a
                    href={interviewScheduleData?.zoom_meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Join Meeting
                  </a>
                  &nbsp;&nbsp;
                  <button onClick={handleCopyLink} className="copy-link-button">
                    <img src={copyIcon} alt="." />
                  </button>
                  {showTooltip && (
                    <span className="tooltip">
                      {copied ? "Copied!" : "Copy"}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
