import { createSlice } from "@reduxjs/toolkit";

const StudentProfileSlice = createSlice({
    name:"studentProfile",
    initialState: {
        getStudentProfileData: [],
        isEditModelOpen: false,
        isSubmitted: false,
        isSubmittedSkill: false,
    },
    reducers:{
        setGetStudentProfileData: (state, action) => {
            state.getStudentProfileData = action.payload;
        },
        setIsSubmittedCertification: (state, action) => {
            state.isEditModelOpen = action.payload;
        },
        setIsSubmitted: (state, action) => {
            state.isSubmitted = action.payload;
        },
        setIsSubmittedSkill: (state,action) => {
            state.isSubmittedSkill = action.payload;
        }
    }
});

export const {setGetStudentProfileData, setIsEditModelOpen, setIsSubmitted, setIsSubmittedSkill, isSubmittedSkill} = StudentProfileSlice.actions;

export default  StudentProfileSlice.reducer;