import React from "react";
import "../../../../styles/dashboards/adminDashboard/setting.css"

function Settings() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">

        <div className="admin_setting_login_details_main_container">
          Settings neww
        </div>

      </div>
    </div>
  );
}

export default Settings;
