export default function HRManagerMySchedule() {
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          HRManagerMySchedule
        </div>
      </div>
    </>
  );
}
