import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../styles/dashboards/dashboard.css";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/myApplication/myapplication.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Stack from "@mui/material/Stack";
import ChartDataLabels from "chartjs-plugin-datalabels";
import logo from "../../../utils/landingpage/images/logo.svg";
import Downarrow from "../../../utils/landingpage/images/down-arow.svg";
import notificationIcon from "../../../utils/landingpage/images/notificationIcons.png";
import userIcon from "../../../utils/landingpage/images/logdedUser.png";
import startNaukriNewLogo from "../../../utils/dashboards/startNaukriNewLogo.svg";
import homeIcon from "../../../utils/dashboards/homeIcon.svg";
import msgIcon from "../../../utils/dashboards/msgIcon.svg";
import myApplication from "../../../utils/dashboards/myApplicationIcon.svg";
import savedIcon from "../../../utils/dashboards/savedIcon.svg";
import myInterviewIcon from "../../../utils/dashboards/myInterview.svg";
import myAssesmentIocn from "../../../utils/dashboards/assementIcon.svg";
import settingIcon from "../../../utils/dashboards/settingIcon.svg";
import myprofileIocn from "../../../utils/dashboards/myProfileIcon.svg";
import logoutIcon from "../../../utils/dashboards/logoutIcon.svg";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import Microsoft from "../../../utils/landingpage/images/microsoft-logo.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import LogOut from "../../reusablecomponents/Logout";
function CampusManagerHome() {
  const [isOpen, setIsOpen] = useState(false);
  const [experience, setExperience] = useState(0);
  const [isOpenNew, setIsOpenNew] = useState(null);
  const [activeFilter, setActiveFilter] = useState("Shortlisted");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");

  const navigate = useNavigate();

  function handleSignOut() {
    sessionStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 1500);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const recentJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
    { company: "Systango Pvt. Ltd.", logo: "Y" },
  ];

  const popularJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
  ];

  const data = {
    labels: [
      "In-Review Applications",
      "Rejected Applications",
      "Shortlisted Applications",
    ],
    datasets: [
      {
        data: [62, 24, 16],
        backgroundColor: ["#f6c23e", "#e74a3b", "#1cc88a"],
        hoverBackgroundColor: ["#f6b23e", "#e73b3b", "#1cc8a7"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#fff",
        anchor: "end",
        align: "start",
        offset: -10,
        borderWidth: 2,
        borderColor: "#fff",
        borderRadius: 25,
        backgroundColor: (context) => context.dataset.backgroundColor,
        font: {
          weight: "bold",
          size: 14,
        },
        formatter: (value) => `${value}%`,
      },
    },
    cutout: "50%",
  };

  const [applications, setApplications] = useState({
    Shortlisted: [
      {
        id: 1,
        company: "Microsoft Pvt. Ltd",
        location: "Pune, Maharashtra",
        date: "26th July 2024",
        status: "Shortlisted",
        logo: "M",
      },
      {
        id: 2,
        company: "Mphasis Pvt. Ltd",
        location: "Vadodara, Gujarat",
        date: "25th July 2024",
        status: "Shortlisted",
        logo: "M",
      },
      {
        id: 3,
        company: "Accenture Pvt. Ltd",
        location: "Indore, Madhya Pradesh",
        date: "18th July 2024",
        status: "Shortlisted",
        logo: ">",
      },
      {
        id: 4,
        company: "Systango Pvt. Ltd",
        location: "Indore, Madhya Pradesh",
        date: "20th July 2024",
        status: "Shortlisted",
        logo: "Y",
      },
    ],
    "In-Review": [
      {
        id: 1,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
      {
        id: 2,
        company: "Amazon Web Services",
        location: "Mumbai, Maharashtra",
        date: "23rd July 2024",
        status: "In-Review",
        logo: "A",
      },
      {
        id: 3,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
      {
        id: 4,
        company: "Amazon Web Services",
        location: "Mumbai, Maharashtra",
        date: "23rd July 2024",
        status: "In-Review",
        logo: "A",
      },
      {
        id: 7,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
    ],
    Rejected: [
      {
        id: 1,
        company: "Facebook",
        location: "Hyderabad, Telangana",
        date: "19th July 2024",
        status: "Rejected",
        logo: "F",
      },
      {
        id: 2,
        company: "Twitter",
        location: "Delhi, NCR",
        date: "21st July 2024",
        status: "Rejected",
        logo: "T",
      },
      {
        id: 3,
        company: "Facebook",
        location: "Hyderabad, Telangana",
        date: "19th July 2024",
        status: "Rejected",
        logo: "F",
      },
      {
        id: 4,
        company: "Twitter",
        location: "Delhi, NCR",
        date: "21st July 2024",
        status: "Rejected",
        logo: "T",
      },
    ],
  });

  // Calculate total pages based on activeFilter
  const totalPages = Math.ceil(
    applications[activeFilter].length / itemsPerPage
  );

  // Get current items for pagination
  const currentItems = applications[activeFilter].slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropDown = (id) => {
    setIsOpen(isOpen === id ? null : id);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="_main_content">
            <div className="_main_first_content">
              <div className="stat-container">
                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={applicationIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Students</h3>
                    <p>50</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={applicationIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Job Profiles</h3>
                    <p>30</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={applicationIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Rejected</h3>
                    <p>12</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={applicationIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Shortlisted</h3>
                    <p>08</p>
                  </div>
                </div>
              </div>
              <div className="_chart_section">
                <div className="_chart_section_b">
                  <b>Department Wise Placement</b>
                </div>
                <div className="_applicationStatisticsContainer">
                  <div
                    className="_pieChartContainer"
                    style={{ visibility: "hidden" }}
                  >
                    {/* <Pie data={data} options={options} plugins={[ChartDataLabels]} /> */}
                    <Pie data={data} options={options} />
                  </div>
                  <h3 style={{ marginLeft: "-13rem", color: "#716e6e" }}>
                    Coming Soon{" "}
                  </h3>
                </div>
              </div>

              <div className="_applications_history _campus_dashboard_div">
                <section className="_campus_dashboard_application_history">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                      <b>Upcoming Placements</b>
                      <div
                        style={{ visibility: "hidden" }}
                        className="status-filters"
                      >
                        <button
                          className={`filter ${
                            activeFilter === "Shortlisted"
                              ? "Shortlisted_active"
                              : "filter_shortlisted"
                          }`}
                          onClick={() => {
                            setActiveFilter("Shortlisted");
                            setCurrentPage(1);
                          }}
                        >
                          Shortlisted ({applications.Shortlisted.length})
                        </button>
                        <button
                          className={`filter ${
                            activeFilter === "In-Review"
                              ? "review_active"
                              : "filter_review"
                          }`}
                          onClick={() => {
                            setActiveFilter("In-Review");
                            setCurrentPage(1);
                          }}
                        >
                          In-Review ({applications["In-Review"].length})
                        </button>
                        <button
                          className={`filter ${
                            activeFilter === "Rejected"
                              ? "rejected_active"
                              : "filter_rejected"
                          }`}
                          onClick={() => {
                            setActiveFilter("Rejected");
                            setCurrentPage(1);
                          }}
                        >
                          Rejected ({applications.Rejected.length})
                        </button>
                      </div>
                    </div>

                    <h3
                      style={{
                        marginLeft: "13rem",
                        color: "#716e6e",
                        marginTop: "3rem",
                      }}
                    >
                      Coming Soon
                    </h3>

                    <div style={{ visibility: "hidden" }}>
                      {currentItems.map((app) => (
                        <div key={app.id} className="application-item">
                          <div className="d-flex align-items-center">
                            <div className="application-company-logo">
                              <img src={microsoftIMG} alt="company logo" />
                            </div>
                            <div className="application-details">
                              <h3>Front End Developer</h3>
                              <p>
                                {`${app.company}, ${app.location}`.length > 30
                                  ? `${`${app.company}, ${app.location}`.slice(
                                      0,
                                      30
                                    )}...`
                                  : `${app.company}, ${app.location}`}
                              </p>
                            </div>
                          </div>
                          <div className="application-date">
                            <p>Date Applied</p>
                            <p>{app.date}</p>
                          </div>
                          <div
                            className="d-flex justify-content-between align-items-center gap-2"
                            style={{
                              paddingRight: "25px",
                              position: "relative",
                            }}
                          >
                            <span
                              className={`status ${app.status.toLowerCase()}`}
                            >
                              {app.status}
                            </span>
                            <button
                              onClick={() => handleDropDown(app.id)}
                              className="more-options"
                            >
                              ⋮
                            </button>
                            {isOpen === app.id && (
                              <div className="more-options-dropdown">
                                <ul>
                                  <li>
                                    <a href="#">Withdraw</a>
                                  </li>
                                  <li>
                                    <Link to="/lateral/jobDetails">View</Link>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="d-grid gap-4 _recent_jobs_head">
              <div className="_recent_jobs">
                <div className="_recentadded_container">
                  <div className="_popularjobs_section">
                    <div className="_popularjobs_header">
                      <b>Placement Trend</b>
                      {/* <a href="#" className="_popularjobs_seeAllLink">See All</a> */}
                    </div>
                    <hr />
                    <div className="_popularjobs_jobList">
                      <center>
                        <b>Coming Soon</b>
                      </center>
                      {recentJobs.map((job, index) => (
                        <div
                          key={index}
                          className="_recentadded_jobCard"
                          style={{ visibility: "hidden" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="_recentadded_companyLogo">
                                <img
                                  src={Microsoft}
                                  alt="logo"
                                  className="campus_dashboard_icon"
                                />
                              </div>
                              <div>
                                <h3 className="_recentadded_jobTitle">
                                  Front End Developer
                                </h3>
                                <p className="_recentadded_companyName">
                                  {job.company}
                                </p>
                              </div>
                            </div>
                            <button className="_recentadded_viewMoreBtn">
                              View More
                            </button>
                          </div>
                          <div className="_recentadded_jobInfo">
                            <p className="card-text">
                              <span>
                                <img
                                  src={locationIcon}
                                  width="9px"
                                  height="13px"
                                  alt="..."
                                />
                              </span>
                              <small className="small">
                                <i className="bi bi-geo-alt"></i> Pune,
                                Maharashtra
                              </small>
                            </p>
                            <p className="_recentadded_jobDate">2 Days Ago</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampusManagerHome;
