import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const LogOut = ({color}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Disable back button in the browser
    const preventGoBack = () => {
      window.history.pushState(null, null, window.location.href);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, []);

  const handleLogout = async () => {
    try {
      setLoading(true);

      // Clear all session storage
      sessionStorage.clear();

      // Redirect to login page after a short delay
      setTimeout(() => {
        navigate("/login");
      }, 800);
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setLoading(false);
    }
  };

  const confirmLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out!",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "custom-swal-confirm-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout();
        Swal.fire({
          title: "Logged Out!",
          text: "You have been successfully logged out.",
          icon: "success",
          timer: 1500, // Time in milliseconds before the popup is automatically closed
          timerProgressBar: true, // Show a progress bar that counts down
          showConfirmButton: false, // Hide the confirm button
          customClass: {
            confirmButton: "custom-swal-confirm-button",
          },
        });
      }
    });
  };

  return (
    <button
      onClick={confirmLogout}
      style={{
        background: "none",
        outline: "none",
        border: "none",
        cursor: "pointer",
        fontSize: "14px",
        fontFamily: "Poppins",
        position: "relative",
      }}
    >
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "4px solid rgba(0, 123, 255, 0.2)",
            borderTop: "4px solid #007bff",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            animation: "spin 1s linear infinite",
          }}
        ></div>
      ) : (
        "Log Out"
      )}
    </button>
  );
};

// Add keyframes for the spin animation
const style = document.createElement("style");
style.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);

export default LogOut;
