import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/dashboards/clgstudent/studenthome.css";
import DonutChart from "./studentDonutChart";

import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import inReview from "../../../utils/dashboards/inReviewIcon.svg";
import rejectedIcon from "../../../utils/dashboards/rejectedIcon.svg";
import shortListed from "../../../utils/dashboards/shortlistedIcon.svg";
import Microsoft from "../../../utils/landingpage/images/microsoft-logo.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";

function StudentHome() {
  const [isOpen, setIsOpen] = useState(null);
  const [experience, setExperience] = useState(0);
  const [isOpenNew, setIsOpenNew] = useState(null);
  const [activeFilter, setActiveFilter] = useState("Shortlisted");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const dropdownRef = useRef(null);

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropDown = (id) => {
    setIsOpen(isOpen === id ? null : id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(null); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const recentJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
    { company: "Systango Pvt. Ltd.", logo: "Y" },
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
    { company: "Systango Pvt. Ltd.", logo: "Y" },
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
    { company: "Systango Pvt. Ltd.", logo: "Y" },
  ];

  const popularJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
  ];

  const size = 300;
  const centerX = size / 2;
  const centerY = size / 2;
  const radius = size / 2;
  const donutWidth = radius * 0.3; 

  const createArcPath = (startAngle, endAngle, radius) => {
    const startRad = (startAngle - 90) * (Math.PI / 180);
    const endRad = (endAngle - 90) * (Math.PI / 180);

    const x1 = centerX + radius * Math.cos(startRad);
    const y1 = centerY + radius * Math.sin(startRad);
    const x2 = centerX + radius * Math.cos(endRad);
    const y2 = centerY + radius * Math.sin(endRad);

    const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

    const outerRadius = radius;
    const innerRadius = radius - donutWidth;

    const outerArc = `M ${x1} ${y1} A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${x2} ${y2}`;

    const x3 = centerX + innerRadius * Math.cos(endRad);
    const y3 = centerY + innerRadius * Math.sin(endRad);
    const x4 = centerX + innerRadius * Math.cos(startRad);
    const y4 = centerY + innerRadius * Math.sin(startRad);

    const innerArc = `L ${x3} ${y3} A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${x4} ${y4} Z`;

    return outerArc + innerArc;
  };


  const [applications, setApplications] = useState({
    Shortlisted: [
      {
        id: 1,
        company: "Microsoft Pvt. Ltd",
        location: "Pune, Maharashtra",
        date: "26th July 2024",
        status: "Shortlisted",
        logo: "M",
      },
      {
        id: 2,
        company: "Mphasis Pvt. Ltd",
        location: "Vadodara, Gujarat",
        date: "25th July 2024",
        status: "Shortlisted",
        logo: "M",
      },
      {
        id: 3,
        company: "Accenture Pvt. Ltd",
        location: "Indore, Madhya Pradesh",
        date: "18th July 2024",
        status: "Shortlisted",
        logo: ">",
      },
      {
        id: 4,
        company: "Systango Pvt. Ltd",
        location: "Indore, Madhya Pradesh",
        date: "20th July 2024",
        status: "Shortlisted",
        logo: "Y",
      },
    ],
    "In-Review": [
      {
        id: 1,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
      {
        id: 2,
        company: "Amazon Web Services",
        location: "Mumbai, Maharashtra",
        date: "23rd July 2024",
        status: "In-Review",
        logo: "A",
      },
      {
        id: 3,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
      {
        id: 4,
        company: "Amazon Web Services",
        location: "Mumbai, Maharashtra",
        date: "23rd July 2024",
        status: "In-Review",
        logo: "A",
      },
      {
        id: 7,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
    ],
    Rejected: [
      {
        id: 1,
        company: "Facebook",
        location: "Hyderabad, Telangana",
        date: "19th July 2024",
        status: "Rejected",
        logo: "F",
      },
      {
        id: 2,
        company: "Twitter",
        location: "Delhi, NCR",
        date: "21st July 2024",
        status: "Rejected",
        logo: "T",
      },
      {
        id: 3,
        company: "Facebook",
        location: "Hyderabad, Telangana",
        date: "19th July 2024",
        status: "Rejected",
        logo: "F",
      },
      {
        id: 4,
        company: "Twitter",
        location: "Delhi, NCR",
        date: "21st July 2024",
        status: "Rejected",
        logo: "T",
      },
    ],
  });

  const totalPages = Math.ceil(
    applications[activeFilter].length / itemsPerPage
  );

  const currentItems = applications[activeFilter].slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="_main_content">
            <div className="_main_first_content">
              <div className="stat-container">
                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={applicationIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Applications</h3>
                    <p>50</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={inReview} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>In-Review</h3>
                    <p>30</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={rejectedIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Rejected</h3>
                    <p>12</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={shortListed} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Shortlisted</h3>
                    <p>08</p>
                  </div>
                </div>
              </div>
              <div className="student_chart_section">
                <div className="_chart_section_b">
                  <b>Statistics of All Applications</b>
                </div>
                <div className="_applicationStatisticsContainer student_applicationStatisticsContainer">
                  <div className="_pieChartContainer">
                    <DonutChart />
                  </div>
                  <div className="_legendContainer">
                    <div style={{ height: '20px' }}>
                      <svg width="110" height="2" viewBox="0 0 110 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="1" x2="110" y2="1" stroke="#F38D00" stroke-width="2" />
                      </svg>
                    </div>
                    <h3 className="_legendTitle">
                      Applications{" "}
                      <span style={{ color: "#F38D00" }}>Pie Chart</span>
                    </h3>
                    <ul className="_legendList">
                      <li className="_legendItem">
                        <span className="_legendColor _inReviewColor"></span>
                        In-Review Applications
                      </li>
                      <li className="_legendItem">
                        <span className="_legendColor _rejectedColor"></span>
                        Rejected Applications
                      </li>
                      <li className="_legendItem">
                        <span className="_legendColor _shortlistedColor"></span>
                        Shortlisted Applications
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="_applications_history _campus_dashboard_div">
                <section className="_campus_dashboard_application_history">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                      <b>Recent Applications History</b>
                      <div className="status-filters">
                        <button
                          className={`filter ${activeFilter === "Shortlisted"
                            ? "Shortlisted_active student_shortlisted_active"
                            : "filter_shortlisted student__filter_shortlisted"
                            }`}
                          onClick={() => {
                            setActiveFilter("Shortlisted");
                            setCurrentPage(1);
                          }}
                        >
                          Shortlisted ({applications.Shortlisted.length})
                        </button>
                        <button
                          className={`filter ${activeFilter === "In-Review"
                            ? "review_active student__filter_review_active"
                            : "filter_review student__filter_review"
                            }`}
                          onClick={() => {
                            setActiveFilter("In-Review");
                            setCurrentPage(1);
                          }}
                        >
                          In-Review ({applications["In-Review"].length})
                        </button>
                        <button
                          className={`filter ${activeFilter === "Rejected"
                            ? "rejected_active student__filter_rejected_active"
                            : "filter_rejected student__filter_rejected"
                            }`}
                          onClick={() => {
                            setActiveFilter("Rejected");
                            setCurrentPage(1);
                          }}
                        >
                          Rejected ({applications.Rejected.length})
                        </button>
                      </div>
                    </div>

                    <div className="student_myApplication_list" >
                      {currentItems.map((app) => (
                        <div key={app.id} className="application-item student_application_item">
                          <div className="d-flex align-items-center">
                            <div className="application-company-logo student_application_company_logo">
                              <img src={microsoftIMG} alt="company logo" style={{ width: "25px" }} />
                            </div>
                            <div className="application-details student_application_details">
                              <h3>Front End Developer</h3>
                              <p>
                                {`${app.company}, ${app.location}`.length > 30
                                  ? `${`${app.company}, ${app.location}`.slice(
                                    0,
                                    30
                                  )}...`
                                  : `${app.company}, ${app.location}`}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <div className="application-date student_application_date">
                              <p>Date Applied</p>
                              <p>{app.date}</p>
                            </div>
                            <div
                              className="d-flex justify-content-between align-items-center gap-2"
                              style={{
                                paddingRight: "25px",
                                position: "relative",
                              }}
                            >

                              <button
                                onClick={() => handleDropDown(app.id)}
                                className="more-options student_more_options"
                              >
                                ⋮
                              </button>
                              {isOpen === app.id && (
                                <div className="more-options-dropdown student_more_options_dropdown">
                                  <ul>
                                    <li>
                                      <a href="#">Withdraw</a>
                                    </li>
                                    <li>
                                      <Link to="/lateral/jobDetails">View</Link>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="d-grid gap-4 _recent_jobs_head">
              <div className="_recent_jobs">
                <div className="_recentadded_container student_recent_container">
                  <div className="_popularjobs_section">
                    <div className="_popularjobs_header student_popularjobs_header">
                      <b>Recent Added Jobs</b>
                      <a href="#" className="_popularjobs_seeAllLink">
                        See All
                      </a>
                    </div>
                    <div className="_popularjobs_jobList student_popularJobs_list">
                      {recentJobs.map((job, index) => (
                        <div key={index} className="_recentadded_jobCard student_recentAdded_jobCard">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="_recentadded_companyLogo">
                                <img
                                  src={Microsoft}
                                  alt="logo"
                                  className="campus_dashboard_icon"
                                />
                              </div>
                              <div>
                                <h3 className="_recentadded_jobTitle">
                                  Front End Developer
                                </h3>
                                <p className="_recentadded_companyName">
                                  {job.company}
                                </p>
                              </div>
                            </div>
                            <button className="_recentadded_viewMoreBtn">
                              View More
                            </button>
                          </div>
                          <div className="_recentadded_jobInfo">
                            <p className="card-text">
                              <span>
                                <img
                                  src={locationIcon}
                                  width="9px"
                                  height="13px"
                                  alt="..."
                                />
                              </span>
                              <small className="small">
                                <i className="bi bi-geo-alt"></i> Pune,
                                Maharashtra
                              </small>
                            </p>
                            <p className="_recentadded_jobDate">2 Days Ago</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default StudentHome;
