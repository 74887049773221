import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

const StudentSkillInfo = () => {
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [isSubmittedSkill, setIsSubmittedSkill] = useState(false);
    const [isEditModelOpenSkill, setIsEditModelOpenSkill] = useState(false);
    const [updatedData, setUpdatedData] = useState([]);
    const [suggestedSkills, setSuggestedSkills] = useState([]);

    const isSubmittedSkills = useSelector((state) => state.studentProfile.isSubmittedSkill);
    const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        skills: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            skills: selectedSkills.join(", "),
        }));
    }, [selectedSkills]);

    const handleEditSkillCancel = () => {
        setIsSubmittedSkill(true);
        setIsEditModelOpenSkill(false);
    };

    useEffect(() => {
        if (NewupdatedData?.skill_name?.length > 0) {
            const skillsArray = NewupdatedData.skill_name.map(
                (skill) => skill.skill_name
            );
            setFormData((prevData) => ({
                ...prevData,
                skills: skillsArray.join(", "),
            }));
            setSelectedSkills(skillsArray);
        }
    }, [NewupdatedData]);

    const handleSkillClick = (skill) => {
        if (selectedSkills.includes(skill)) {
            setSelectedSkills(selectedSkills.filter((s) => s !== skill));
        } else {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    const handleSkillSubmit = async (e) => {
        e.preventDefault();
        const userSessionData = sessionStorage.getItem("userDetails");

        const parsedData = JSON.parse(userSessionData);
        const userId = parsedData.id;

        const manualSkills = formData.skills
            .split(",")
            .map((skill) => skill.trim())
            .filter((skill) => skill);

        const allSkills = [...new Set([...selectedSkills, ...manualSkills])];

        const payload = {
            skill_name: allSkills.map((skill) => ({ skill_name: skill })),
        };

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
                payload
            );
            // setUpdatedData(response.data);
            toast.success("Added Successfully");
            // fetchProfileCompletion();
            setIsSubmittedSkill(true);
            setIsEditModelOpenSkill(false);
        } catch (error) {
            toast.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({ ...errors, [name]: "" });
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/job-skills/`)
            .then((response) => {
                const skills = response.data.map((skill) => skill.skill_name);
                setSuggestedSkills(skills);
            })
            .catch((error) => {
                console.error("Error fetching skills:", error);
            });
    }, []);

    return (<>
        <div className="pt-4 pb-4">
            <div
                className=""
                style={
                    isSubmittedSkills ? { position: "relative" } : { display: "block" }
                }
            >
                <div className="qualification-form-container">
                    <div className="epf_form_name_heading">
                        <h2 className="epf-form-heading">
                            {isSubmittedSkills ? "Edit Skills Details" : "Skills Details"}
                        </h2>
                        <hr />
                    </div>

                    {isEditModelOpenSkill === false && (
                        <div
                            className="epf_edit_Details_button"
                            onClick={() => setIsEditModelOpenSkill(true)}
                        >
                            <b>
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                                        fill="#F38D00"
                                    />
                                </svg>
                                <span className="pl-4"> Add Skills</span>
                            </b>
                        </div>
                    )}

                    {isEditModelOpenSkill === true || isSubmittedSkills === false ? (
                        <div className="epf-skill-form">
                            <form onSubmit={handleSkillSubmit}>
                                <div className="epf-input-wrapper">
                                    <input
                                        type="text"
                                        id="skills"
                                        name="skills"
                                        placeholder="Add your skills"
                                        value={formData.skills}
                                        onChange={handleInputChange}
                                        className="epf-input-field"
                                    />
                                </div>

                                <div className="epf-suggested-skills">
                                    <div className="epf-skill-options">
                                        {suggestedSkills &&
                                            suggestedSkills?.map((skill) => {
                                                const isSelected = selectedSkills.includes(skill);

                                                return (
                                                    <button
                                                        type="button"
                                                        key={skill}
                                                        className={`epf-skill-button ${isSelected ? "selected" : ""
                                                            }`}
                                                        onClick={() => handleSkillClick(skill)}
                                                    >
                                                        {skill}
                                                    </button>
                                                );
                                            })}
                                    </div>
                                </div>

                                <button type="submit" className="epf-submit-btn mt-4">
                                    Save
                                </button>
                            </form>
                        </div>
                    ) : null}

                    {isSubmittedSkills === true && isEditModelOpenSkill === false && (
                        <div className="epf-suggested-skills">
                            <div className="epf-skill-options">
                                {selectedSkills?.length > 0 ? (
                                    selectedSkills.map((skill, index) => (
                                        <button
                                            type="button"
                                            key={skill || index}
                                            className="epf-skill-button"
                                        >
                                            {skill}
                                        </button>
                                    ))
                                ) : (
                                    <p>No skills available</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>)
};

export default StudentSkillInfo;