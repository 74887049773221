import React, { useEffect, useState } from "react";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import jobsharingw from "../../../utils/dashboards/jobsharingw.svg";
import jobsharingm from "../../../utils/dashboards/jobsharingm.svg";
import jobsharinge from "../../../utils/dashboards/jobsharinge.svg";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import axios from "axios";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";

const CampusJobDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [jobDetail, setJobDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/campus-jobs/2/`
        );
        setJobDetail(response.data);
        setLoading(false); 
      } catch (error) {
        console.error("Error fetching job details:", error);
        toast.error("Error fetching job details");
        setLoading(false); 
      }
    };

    fetchJobDetail(); 
  }, []); 

  // Handle modal open/close
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Handle email sending
  const handleSend = () => {
    console.log("Email:", email);
    console.log("URL:", url);
    setEmail(""); 
    setUrl("");
    closeModal(); 
  };

  if (loading) {
    return <div>Loading job details...</div>;
  }

  if (!jobDetail) {
    return <div>Unable to fetch job details.</div>;
  }

  // Function to download PDF
  const downloadPdf = () => {
    const element = document.getElementById("job-detail-container"); // Targeting the element to convert into PDF
    if (!element) {
      console.error("Element not found. Unable to download PDF.");
      return; // Exit if the element is not found
    }

    const options = {
      filename: "job-details.pdf",  
      image: { type: "jpeg", quality: 0.98 },   
      html2canvas: { scale: 2 },     
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },   
    };

    console.log("Starting PDF download...");

    html2pdf()
      .from(element) 
      .set(options)   
      .save()        
      .then(() => {
        console.log("PDF downloaded successfully.");
      })
      .catch((error) => {
        console.error("Error during PDF download:", error);
      });
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_container">
        <div className="job-detail-page">
          <div className="job-detail-header">
            <button
              className="job-detail-back-button"
              onClick={() => window.history.back()}
            >
              <img src={leftArrowIcon} alt="Back" />
            </button>
            <h5>Job Details</h5>
            <div className="job-detail-Download-Pdf">
              <button onClick={downloadPdf}>Download Pdf</button>
            </div>
          </div>
          {jobDetail && (
            <div id="job-detail-container">
              <div  className="job-detail-summary">
                <div className="job-left">
                  <img
                    src={
                      jobDetail?.company_profile
                        ? jobDetail?.company_profile?.company_logo_url
                        : dummy_logo
                    }
                    alt={jobDetail.company_name}
                    className="company-logo"
                    style={{ marginRight: "13px" }}
                  />
                  <div className="job-info">
                    <h6>{jobDetail.company_name}</h6>
                    <p>{jobDetail.title}</p>
                  </div>
                </div>
                <div className="job-detail-right">
                  <span>
                    <h6>Posted date</h6>
                    {new Date(jobDetail.created_at).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <div className="job-detail-description">
                <h5>Job Description</h5>
                <p>{jobDetail.description}</p>
                <ul>
                  {jobDetail.rensponsibility &&
                    jobDetail.rensponsibility.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </ul>
                <h6>
                  Role: <p>{jobDetail.title}</p>
                </h6>
                <h6>
                  Industry Type: <p>{jobDetail.job_category}</p>
                </h6>
                <h6>
                  Employment Type: <p>{jobDetail.type}</p>
                </h6>
                <h6>
                  Qualification:{" "}
                  <p>{jobDetail.qualification || "Not Specified"}</p>
                </h6>
                <h6>
                  Key Skills:{" "}
                  <p>
                    {jobDetail.job_skills
                      ? jobDetail.job_skills
                          .map((skill) => skill.skill_name)
                          .join(", ")
                      : "Not Specified"}
                  </p>
                </h6>
                <h6>
                  Salary:{" "}
                  <p>
                    {jobDetail.min_salary} - {jobDetail.max_salary} per annum
                  </p>
                </h6>
              </div>
              <div className="job-detail-company">
                <h5>About Company</h5>
                <p>
                  {jobDetail.company_profile
                    ? jobDetail.company_profile?.company_desc
                    : "No company description available"}
                </p>
              </div>
              <div className="job-detail-share-button">
                <button onClick={openModal}>Share Job</button>
              </div>
            </div>
          )}
        </div>

        {isModalOpen && (
          <div className="job-share-modal-overlay">
            <div className="job-share-modal-content">
              <h5>Job Sharing</h5>
              <div className="job-sharing-icon">
                <img src={jobsharingw} alt="sharing" />
                <img src={jobsharingm} alt="sharing" />
                <img src={jobsharinge} alt="sharing" />
              </div>
              <p>Share Job Opportunity</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Job URL"
              />
              <div>
                <button onClick={handleSend}>Send Email</button>
                <button onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CampusJobDetails;
