import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../styles/dashboards/hrdashboard/campuseinterview.css";

function CandidateOfSelectedCollege() {
  const { jobId, round, clgId } = useParams();
  const [selectedRound, setSelectedRound] = useState(1); // Default to Round 1

  const maxRounds = parseInt(round, 4);
  const rounds = Array.from({ length: maxRounds }, (_, i) => i + 1);

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="capuse_interview_tabs-container">
            {/* Render Tabs */}
            <div className="capuse_interview_tabs">
              {rounds.map((round) => (
                <button
                  key={round}
                  className={`capuse_interview_tab-button ${
                    selectedRound === round ? "capuse_interview_active" : ""
                  }`}
                  onClick={() => setSelectedRound(round)}
                >
                  Round {round}
                </button>
              ))}
              <button
                key="Offered"
                className={`capuse_interview_tab-button ${
                  selectedRound === "Offered" ? "capuse_interview_active" : ""
                }`}
                onClick={() => setSelectedRound("Offered")}
              >
                Offered
              </button>
            </div>

            {/* Display Content Based on Selected Round */}
            <div className="capuse_interview_tab-content">
              {selectedRound !== "Offered" ? (
                <RoundTable roundNumber={selectedRound} />
              ) : (
                <OfferedTable />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RoundTable({ roundNumber }) {
  // Sample data for each round
  const candidatesByRound = {
    1: [
      {
        id: 1,
        name: "Vinay Parihar",
        studentId: "12345688",
        jobApplied: "31 July 2024",
        status: "Interview",
      },
      {
        id: 2,
        name: "Sam Wilson",
        studentId: "12345689",
        jobApplied: "31 July 2024",
        status: "Interview",
      },
    ],
    2: [
      {
        id: 3,
        name: "Alex Johnson",
        studentId: "12345690",
        jobApplied: "1 August 2024",
        status: "Interview",
      },
      {
        id: 4,
        name: "Emma Stone",
        studentId: "12345691",
        jobApplied: "1 August 2024",
        status: "Interview",
      },
    ],
  };

  const candidates = candidatesByRound[roundNumber] || [];

  return (
    <table className="capuse_interview_candidate-table">
      <thead>
        <tr>
          <th>Full Name</th>
          <th>Student ID</th>
          <th>Job Applied</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {candidates.map((candidate) => (
          <tr key={candidate.id}>
            <td>{candidate.name}</td>
            <td>{candidate.studentId}</td>
            <td>{candidate.jobApplied}</td>
            <td>
              <button className="capuse_interview_see-application">
                See Application
              </button>
              <button className="capuse_interview_delete-button">Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function OfferedTable() {
  // Sample data for offered candidates
  const offeredCandidates = [
    {
      id: 5,
      name: "Sophia Lee",
      studentId: "12345692",
      jobApplied: "5 August 2024",
      status: "Offered",
    },
    {
      id: 6,
      name: "John Doe",
      studentId: "12345693",
      jobApplied: "6 August 2024",
      status: "Offered",
    },
  ];

  return (
    <table className="capuse_interview_candidate-table">
      <thead>
        <tr>
          <th>Full Name</th>
          <th>Student ID</th>
          <th>Job Applied</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {offeredCandidates.map((candidate) => (
          <tr key={candidate.id}>
            <td>{candidate.name}</td>
            <td>{candidate.studentId}</td>
            <td>{candidate.jobApplied}</td>
            <td>{candidate.status}</td>
            <td>
              <button className="capuse_interview_see-application">
                See Application
              </button>
              <button className="capuse_interview_delete-button">Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CandidateOfSelectedCollege;
