import React from "react";
import "../../../styles/dashboards/clgstudent/studentjobs.css"
import { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg";
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import searchIconStudent from "../../../utils/dashboards/searchIconStudent.png"

const StudentJobs = () => {

  const dummyJobs = Array.from({ length: 30 }, (_, index) => ({
    id: index + 1,
    title: [
      "Software Engineer",
      "Frontend Developer",
      "Backend Developer",
      "Full Stack Developer",
      "Data Analyst",
      "DevOps Engineer",
      "Machine Learning Engineer",
      "Product Manager",
      "Graphic Designer",
      "UI/UX Designer",
      "QA Engineer",
      "System Architect",
    ][index % 12],
    company_profile: {
      company_name: [
        "TechCorp",
        "Web Solutions",
        "InnoTech",
        "SmartSoft",
        "CloudNet",
        "GreenTech",
        "Cyberdyne",
        "FutureLabs",
        "Creative Minds",
        "Designify",
      ][index % 10],
      company_rating: (Math.random() * 2 + 3).toFixed(1),
      company_review: Math.floor(Math.random() * 200) + 1,
      company_logo_url: dummy_logo,
    },
    min_experience: Math.floor(Math.random() * 3) + 1,
    max_experience: Math.floor(Math.random() * 5) + 3,
    min_salary: (Math.random() * 5 + 3).toFixed(1),
    max_salary: (Math.random() * 10 + 5).toFixed(1),
    job_location: [
      "San Francisco, CA",
      "New York, NY",
      "Austin, TX",
      "Chicago, IL",
      "Seattle, WA",
      "Los Angeles, CA",
      "Boston, MA",
      "Denver, CO",
    ][index % 8],
    description: "This is a great opportunity to work with cutting-edge technology and a dynamic team.",
    created_at: `2024-09-${(index % 30) + 1}`,
    is_already_saved: false,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState(dummyJobs);

  const handleSave = (id) => {
    const updatedJobs = jobs.map((job) =>
      job.id === id ? { ...job, is_already_saved: true } : job
    );
    setJobs(updatedJobs);
    toast.success("Job saved successfully!");
  };

  const handleUnSave = (id) => {
    const updatedJobs = jobs.map((job) =>
      job.id === id ? { ...job, is_already_saved: false } : job
    );
    setJobs(updatedJobs);
    toast.success("Job unsaved successfully!");
  };

  const itemsPerPage = 4;
  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  const currentItems = jobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getPostedDate = (date) => {
    const postDate = new Date(date);
    const today = new Date();
    const diffTime = Math.abs(today - postDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} days ago`;
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">

        <div className="student_jobs_dropdown_container">
          <div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingBottom: "2rem", paddingTop: "1rem" }}>
              <div style={{ fontSize: "1.2rem", fontWeight: "300" }}>
                <b>All Jobs</b>
              </div>
              <div style={{ boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 2px 0px", padding: "10px 10px 10px 20px", borderRadius: "30px", background: "white" }}>
                <img src={searchIconStudent} alt="..." />
                <input style={{ border: "none", background: "none", paddingLeft: "10px" }} placeholder="Search" />
              </div>
            </div>

            <div className="job-listings">
              {currentItems.map((job, index) => (
                <div key={index} className="job-listing">
                  <div className="company-info">
                    <Link to={`/dashboard/sidebar-page/student/job-details`}>
                      <img
                        src={job.company_profile?.company_logo_url || dummy_logo}
                        alt={"logo"}
                        className="company-logo"
                      />
                    </Link>
                    <div style={{width: "100%"}}>
                      <div className="campus_jobDetails_flex">
                        <Link to={`/dashboard/sidebar-page/:roleName/job-details`}>
                          <h2>{job.title}</h2>
                        </Link>
                        <div>
                          <b>Job Details</b>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <div className="company-name">
                          {job.company_profile?.company_name || "Not Mentioned"}
                        </div>
                        <div className="rating">
                          <span className="star">★</span>{" "}
                          {job.company_profile?.company_rating || "0"} |
                          {job.company_profile?.company_review || "0"} Reviews
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job-details mb-0">
                    <span className="detail">
                      <span className="details_job_icon">
                        <img src={experienceIcon} alt="..." />
                      </span>{" "}
                      {job.min_experience} - {job.max_experience} yrs{" "}
                      <span className="stand_icon">|</span>{" "}
                    </span>
                    <span className="detail">
                      <span className="details_job_icon">
                        <img src={dollerIcon} alt="..." />
                      </span>{" "}
                      {job.min_salary} - {job.max_salary} LPA{" "}
                      <span className="stand_icon">|</span>{" "}
                    </span>
                    <span className="detail">
                      <span className="details_job_icon">
                        <img src={locationIcon} alt="..." />
                      </span>{" "}
                      {job.job_location}
                    </span>
                  </div>

                  <div className="listing-footer">
                    <span className="posted-date">
                      {getPostedDate(job.created_at)}
                    </span>
                    <button
                      className="save-button student_campus_save_button"
                      style={{
                        background: "transparent",
                        color: "#000000de",
                        fontWeight: "400",
                      }}
                    >
                      {job.is_already_saved ? (
                        <>
                          <img src={savedIcon} alt="saved" />
                          <span onClick={() => handleUnSave(job.id)}>Saved</span>
                        </>
                      ) : (
                        <>
                          <img src={saveIcon} alt="save" />
                          <span onClick={() => handleSave(job.id)}>Save</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="pagination-controls">
              <Stack spacing={2} alignItems="center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  siblingCount={1}
                  boundaryCount={1}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: () => (
                          <img src={leftArrowIcon} alt="prev" />
                        ),
                        next: () => <img src={rightIconArrow} alt="next" />,
                      }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default StudentJobs;